import { useState, useEffect } from "react";

import './Payment.css';

import { SvgDelete, SvgSave, SvgSetaDown, SvgSetaUp } from "components/SvgFile";

import { Reg_NewData_Payment, Reg_Payment_NewOrder } from "services/RegisterData";

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";

export default function Site_Payment(){
    
    const [ loading, setLoading ]   = useState(false);
    const [ dataPage, setDataPage ] = useState(GetDataPage('payment'));
    const [ categoryPayment, setCategoryPayment ] = useState(GetDataPage('category_payment'));

    const [ title, setTitle ]             = useState(InitialData('title'));
    const [ subtitle, setSubtitle ]       = useState(InitialData('subtitle'));
    const [ listPayment, setListPayment ] = useState(InitialData('payment'));

    const [ showHome, setShowHome ]           = useState(InitialData('show_home'));
    const [ showMentoring, setShowMentoring ] = useState(InitialData('show_mentoring'));
    const [ showRolo, setShowRolo ]           = useState(InitialData('show_rolo'));

    function InitialData(type){
        const newData = dataPage;
        return newData[type];
    }

    function NewCategory(id, index){
        const dataShow = [...listPayment];
        dataShow[index]['category'] = [...dataShow[index]['category'], {
            "id": 0,
            "id_given": id,
            "id_category": 0,
            "new_category": "",
            "highlight": 'Não',
            "order_": 0
        }]
        setListPayment(dataShow);
    }
    
    function handleData(index, type, value){
        const dataShow = [...listPayment];
        dataShow[index][type] = value;
        setListPayment(dataShow);
    }

    function handleDataCategory(index, index_, type, value){
        const dataShow = [...listPayment];
        dataShow[index]['category'][index_][type] = value;
        setListPayment(dataShow);
    }

    function DeleteData(type, id, value){
        SetModalData('Confirmation', { "origin": "payment", "type" : type, "id" : id, "name": value });
        SetModalState('Confirmation', true);
    }

    function RemuveDataAddCategory(id, index, index_1, value){
        if(id == 0){
            let arrayData = [...listPayment];
            arrayData[index]['category'].splice(index_1, 1);
            setListPayment(arrayData);
        }else {
            SetModalData('Confirmation', { "origin": "payment", "type" : "delete_category", "id" : id, "name": value });
            SetModalState('Confirmation', true);
        }
    }

    function SaveData(event){
        event.preventDefault();
        setLoading(true);
        Reg_NewData_Payment(dataPage.id, title, subtitle, showHome, showMentoring, showRolo, listPayment, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function ChangePosition(id, idCategory, order, type){
        Reg_Payment_NewOrder(id, idCategory, order, type, ()=>{ }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    useEffect(()=>{
        RegisterDataPage('payment', setDataPage);
        RegisterDataPage('category_payment', setCategoryPayment);

        return ()=>{
            UnRegisterDataPage('payment', setDataPage);
            UnRegisterDataPage('category_payment', setCategoryPayment);
        };
    }, []);

    useEffect(()=>{
        setDataPage(GetDataPage('payment'));
        setCategoryPayment(GetDataPage('category_payment'));

        setTitle(InitialData('title'));
        setSubtitle(InitialData('subtitle'));
        setListPayment(InitialData('payment'));
        setShowHome(InitialData('show_home'));
        setShowMentoring(InitialData('show_mentoring'));
        setShowRolo(InitialData('show_rolo'));
    }, [dataPage]);

    return(
        <div className="Site_PaymentTypes">
            <div className={ loading == true ? "return_data_save div_loading" : "return_data_save" }>
                <img alt="loading" src="./assets/loading.gif" className="loading" />
            </div>
            <form onSubmit={ SaveData } className="div_login">
                <div className="new_block">
                    <div className="title_page">Tipos de pagamento</div>
                    <div className="list_opt_alt_page">
                        <button className="div_add_new_data">
                            <div className="new_data_add">
                                <SvgSave color="#ffffff" className="icons" />
                            </div>
                            <div className="name_btn_add">Salvar</div>
                        </button>
                    </div>
                </div>

                <div className="div_high">
                    Mostrar nas páginas                           
                    <div className={ showHome == true ? "high high_active" : "high" } onClick={ ()=>{ setShowHome(!showHome) } }>
                        Home
                    </div>
                    <div className={ showMentoring == true ? "high high_active" : "high" } onClick={ ()=>{ setShowMentoring(!showMentoring) } }>
                        Mentoria
                    </div>
                    <div className={ showRolo == true ? "high high_active" : "high" } onClick={ ()=>{ setShowRolo(!showRolo) } }>
                        Rolo
                    </div>
                </div>

                <div className="show_page_data">
                    <div className="type_title">Dados fixo</div>
                    <div className="list_input_data">
                        <div className="div_input space_div">
                            <input type="text" className="input_name" onChange={ (e)=>{ setTitle(e.target.value) } } value={ title } maxLength="140" required />
                            <span className="name_input">Título</span>
                        </div>
                    </div>
                    <div className="list_input_data">
                        <div className="div_input space_div">
                            <input type="text" className="input_name" onChange={ (e)=>{ setSubtitle(e.target.value) } } value={ subtitle } maxLength="255" required />
                            <span className="name_input">Subtítulo</span>
                        </div>
                    </div>
                </div>

                {
                    listPayment.map((key, index)=>{
                        return(                            
                            <div className="show_page_data" key={ index }>
                                <div className="type_title">                                    
                                    <div className="">{ key.type }</div>
                                </div>
                                <div className="list_input_data">
                                    <div className="div_input">
                                        <select className="select_type" onChange={ (e)=>{ handleData(index, 'type', e.target.value) } } value={ key.type } required>
                                            <option value="">#</option>
                                            <option value="Free">free</option>
                                            <option value="Assinatura">Assinatura</option>
                                            <option value="Compra">Compra</option>
                                        </select>
                                        <span className="name_input">Pagamento</span>
                                    </div>
                                    {
                                        key.type == 'Free' ? null : 
                                        <>
                                            <div className="div_input">
                                                <input type="text" className="input_cash" onChange={ (e)=>{ handleData(index, 'cash', e.target.value) } } value={ key.cash } maxLength="140" required />
                                                <span className="name_input">Valor R$</span>
                                            </div>
                                            <div className="div_input">
                                                <select className="data_select" onChange={ (e)=>{ handleData(index, 'type_cash', e.target.value) } } value={ key.type_cash } required>
                                                    <option value="">#</option>
                                                    <option value="Mês">Mês</option>
                                                    <option value="Ano">Ano</option>
                                                </select>
                                                <span className="name_input">Tipo</span>
                                            </div>
                                        </>
                                    }
                                    <div className="div_input">
                                        <select className="select_cost_benefit" onChange={ (e)=>{ handleData(index, 'best_cost', e.target.value) } } value={ key.best_cost} required>
                                            <option value="">#</option>
                                            <option value="Sim">Sim</option>
                                            <option value="Não">Não</option>
                                        </select>
                                        <span className="name_input">Melhor custo benefício</span>
                                    </div>
                                    <div className="div_input">
                                        <input type="text" className="input_cash" onChange={ (e)=>{ handleData(index, 'color', e.target.value) } } value={ key.color } required />
                                        <span className="name_input">Cor</span>
                                    </div>
                                    <div className="div_input">
                                        <div className="div_color" style={ { backgroundColor: key.color } } />
                                    </div>
                                    {/* 
                                    <div className="div_input" onClick={ ()=>{ DeleteData('delete_data', key.id, key.title) } }>
                                        <SvgDelete color="#f00000" className="trash" />
                                    </div> 
                                    */}
                                </div>
                                <div className="list_input_data">
                                    <div className="div_input space_div">
                                        <input type="text" className="input_name" onChange={ (e)=>{ handleData(index, 'title', e.target.value) } } value={ key.title } required />
                                        <span className="name_input">Título</span>
                                    </div>
                                </div>
                                <div className="list_input_data">
                                    <div className="div_input space_div">
                                        <input type="text" className="input_name" onChange={ (e)=>{ handleData(index, 'subtitle', e.target.value) } } value={ key.subtitle } required />
                                        <span className="name_input">Subtítulo</span>
                                    </div>
                                </div>
                                <div className="list_input_data">
                                    <div className="div_input space_div">
                                        <div className="type_title" style={ { marginBottom: '0px' } }>
                                            <div className="">Categoria</div>
                                            <div className="cursor_pointer" onClick={ ()=>{ NewCategory(key.id, index); } }>
                                                + Adicionar nova categoria
                                            </div>
                                        </div>
                                    </div>
                                    <table width="100%" cellPadding="0" cellSpacing="0">
                                        <tbody>
                                            <tr>
                                                <th width="20" align="center">#</th>
                                                <th>Nome da categoria</th>
                                                <th width="100">Destacar</th>
                                                <th width="40">Order</th>
                                                <th width="30" align="center">#</th>
                                            </tr>
                                        </tbody>
                                        <tbody>
                                            {
                                                key.category.map((key_1, index_1)=>{
                                                    return(
                                                        <tr key={ index_1 }>
                                                            <td align="center">{ index_1 + 1 }</td>
                                                            <td>
                                                                <select className="select_category space_div" onChange={ (e)=>{ handleDataCategory(index, index_1, 'id_category', e.target.value) } } value={ key_1.id_category }>
                                                                    <option value="">#</option>
                                                                    <option value="add_new_category">Adicionar nova categoria</option>
                                                                    {
                                                                        categoryPayment.map((category, category_index)=>{
                                                                            return (
                                                                                <option value={ category.id } key={ category_index }>{ category.name }</option>
                                                                            )
                                                                        })
                                                                    }
                                                                </select>
                                                                {
                                                                    key_1.id_category != 'add_new_category' ? null :
                                                                    <div className="div_input space_div">
                                                                        <input type="text" className="data_input new_category_" onChange={ (e)=>{ handleDataCategory(index, index_1, 'new_category', e.target.value) } } value={ key_1.new_category } placeholder="Nome da nova categoria..." />
                                                                    </div> 
                                                                }
                                                            </td>
                                                            <td>
                                                                <select className="select_type_table" onChange={ (e)=>{ handleDataCategory(index, index_1, 'highlight', e.target.value) } } value={ key_1.highlight }>
                                                                    <option value="Sim">Sim</option>
                                                                    <option value="Não">Não</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <div className="div_opt_alt">
                                                                    {
                                                                        key_1.id == 0 ? null :
                                                                        <>
                                                                            {
                                                                                index_1 == 0 ?
                                                                                <div className="space_div_icon" /> :
                                                                                <div className="space_div_icon" onClick={ ()=>{ ChangePosition(key.id, key_1.id, key_1.order_, 'up') } }>
                                                                                    <SvgSetaUp color="#324d6b" className="icons"/>
                                                                                </div>
                                                                            }
                                                                            {
                                                                                (index_1 + 1) == key.category.length ?
                                                                                <div className="space_div_icon" /> :
                                                                                <div className="space_div_icon" onClick={ ()=>{ ChangePosition(key.id, key_1.id, key_1.order_, 'down') } }>
                                                                                    <SvgSetaDown color="#324d6b" className="icons"/>
                                                                                </div>
                                                                            }
                                                                        </>
                                                                    }
                                                                </div>
                                                            </td>
                                                            <td align="center" onClick={ ()=>{ RemuveDataAddCategory(key_1.id, index, index_1, key_1.name) } }>
                                                                <SvgDelete color="#F00000" className="icons" />
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        )
                    })
                }
            </form>
        </div>
    )
}