import { useState, useEffect, useRef } from "react";

import './Profile.css';

import JoditEditor from 'jodit-react';
import { config } from "dataFixed";

import { SvgDelete, SvgImg, SvgPdf, SvgVimeo, SvgYoutube } from "components/SvgFile";

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";
import { GetListPag, RegisterListPag, UnRegisterListPag } from "interface/Page";

export default function ModelSite_Profile(props){

    const height = useRef();

    const [ idPage, setIdPage ]     = useState(GetListPag('currentPageId'));
    const [ dataPage, setDataPage ] = useState(GetDataPage(props.origin));

    const [ imgOrigin, setImgOrigin ] = useState(InitialData('img_origin'));
    const [ imgName, setImgName ]     = useState(InitialData('img_name'));

    const [ bgImg, setBgImg ]               = useState(InitialData('bg_img'));
    const [ status, setStatus ]             = useState(false);
    const [ bgColor, setBgColor ]           = useState(InitialData('bg_color'));
    const [ file, setFile ]                 = useState(InitialData('file'));
    const [ statusImg, setStatusImg ]       = useState(false);
    const [ imgExample, setImgExample ]     = useState(InitialData('img_example'));
    const [ statusSample, setStatusSample ] = useState(false);

    const [ lineUp, setLineUp ]         = useState(InitialData('line_up'));
    const [ btn_1_name, setBtn_1_name ] = useState(InitialData('btn_1_name'));
    const [ btn_2_name, setBtn_2_name ] = useState(InitialData('btn_2_name'));
    const [ text_1, setText_1 ]         = useState(InitialData('text_1'));
    const [ contents, setContents ]     = useState(InitialData('contents'));

    const [ medHeight, setMedHeight ] = useState(0);

    function InitialData(type){
        if(idPage !=0){
            if(dataPage.length > 0){
                const newData = dataPage.find(item => item.id == idPage);
                return newData[type];
            }
        }
        if(type == 'line_up'){
            return 'left';
        }
        if(type == 'contents'){
            return [];
        }
        return '';
    }

    function LoadingData(){
        props.ReturnData({ "img_origin": imgOrigin, "img_name": imgName, "bg_img": bgImg, "bg_color": bgColor, "file": file, "img_example": imgExample, "line_up": lineUp, "btn_1_name": btn_1_name, "btn_2_name": btn_2_name, "text_1": text_1, "contents": contents});
    }

    function HandleImgOrigin(value){
        setImgOrigin(value);
        props.ReturnData({ "img_origin": value, "img_name": imgName, "bg_img": bgImg, "bg_color": bgColor, "file": file, "img_example": imgExample, "line_up": lineUp, "btn_1_name": btn_1_name, "btn_2_name": btn_2_name, "text_1": text_1, "contents": contents});
    }

    function HandleImgName(value){
        setImgName(value);
        props.ReturnData({ "img_origin": imgOrigin, "img_name": value, "bg_img": bgImg, "bg_color": bgColor, "file": file, "img_example": imgExample, "line_up": lineUp, "btn_1_name": btn_1_name, "btn_2_name": btn_2_name, "text_1": text_1, "contents": contents});
    }

    function HandleBgImg(value){
        setBgImg(value);
        value ? setStatus(true) : setStatus(false);
        props.ReturnData({ "img_origin": imgOrigin, "img_name": imgName, "bg_img": value, "bg_color": bgColor, "file": file, "img_example": imgExample, "line_up": lineUp, "btn_1_name": btn_1_name, "btn_2_name": btn_2_name, "text_1": text_1, "contents": contents});
    }

    function HandleBgColor(value){
        setBgColor(value);
        props.ReturnData({ "img_origin": imgOrigin, "img_name": imgName, "bg_img": bgImg, "bg_color": value, "file": file, "img_example": imgExample, "line_up": lineUp, "btn_1_name": btn_1_name, "btn_2_name": btn_2_name, "text_1": text_1, "contents": contents});
    }

    function HandleFile(value){
        setFile(value);
        value ? setStatusImg(true) : setStatusImg(false);
        props.ReturnData({ "img_origin": imgOrigin, "img_name": imgName, "bg_img": bgImg, "bg_color": bgColor, "file": value, "img_example": imgExample, "line_up": lineUp, "btn_1_name": btn_1_name, "btn_2_name": btn_2_name, "text_1": text_1, "contents": contents});
    }

    function HandleImgExample(value){
        setImgExample(value);
        value ? setStatusSample(true) : setStatusSample(false);
        props.ReturnData({ "img_origin": imgOrigin, "img_name": imgName, "bg_img": bgImg, "bg_color": bgColor, "file": file, "img_example": value, "line_up": lineUp, "btn_1_name": btn_1_name, "btn_2_name": btn_2_name, "text_1": text_1, "contents": contents});
    }

    function HandleLineUp(value){
        setLineUp(value);
        props.ReturnData({ "img_origin": imgOrigin, "img_name": imgName, "bg_img": bgImg, "bg_color": bgColor, "file": file, "img_example": imgExample, "line_up": value, "btn_1_name": btn_1_name, "btn_2_name": btn_2_name, "text_1": text_1, "contents": contents});
    }

    function HandleBtn_1_name(value){
        setBtn_1_name(value);
        props.ReturnData({ "img_origin": imgOrigin, "img_name": imgName, "bg_img": bgImg, "bg_color": bgColor, "file": file, "img_example": imgExample, "line_up": lineUp, "btn_1_name": value, "btn_2_name": btn_2_name, "text_1": text_1, "contents": contents});
    }

    function HandleBtn_2_name(value){
        setBtn_2_name(value);
        props.ReturnData({ "img_origin": imgOrigin, "img_name": imgName, "bg_img": bgImg, "bg_color": bgColor, "file": file, "img_example": imgExample, "line_up": lineUp, "btn_1_name": btn_1_name, "btn_2_name": value, "text_1": text_1, "contents": contents});
    }

    function HandleText_1(value){
        setText_1(value);
        props.ReturnData({ "img_origin": imgOrigin, "img_name": imgName, "bg_img": bgImg, "bg_color": bgColor, "file": file, "img_example": imgExample, "line_up": lineUp, "btn_1_name": btn_1_name, "btn_2_name": btn_2_name, "text_1": value, "contents": contents});
    }

    function OpenFile(type, file){
        SetModalData('ShowFile', { "type": type, "file": file });
        SetModalState('ShowFile', true);
    }

    function AddNewData(){
        setContents([...contents, { "id": 0, "id_given": idPage, "text": "", "file": "", "status": false }]);
    }
    
    function HandleContents(type, index, value){
        const newData = [...contents];
        if(type == 'file'){
            if(type == 'file'){
                newData[index][type]     = value;
                newData[index]['status'] = true;
            }else {
                newData[index][type]     = '';
                newData[index]['status'] = false;
            }
        }else {
            newData[index][type] = value;
        }
        props.ReturnData({ "img_origin": imgOrigin, "img_name": imgName, "bg_img": bgImg, "bg_color": bgColor, "file": file, "img_example": imgExample, "line_up": lineUp, "btn_1_name": btn_1_name, "btn_2_name": btn_2_name, "text_1": text_1, "contents": newData});
        setContents(newData);
    }

    function RemuveDataAdd(id, index, value){
        if(id != 0){
            SetModalData('Confirmation', { "origin": props.origin, "type" : "delete_data_contents", "id" : id, "name": value });
            SetModalState('Confirmation', true);
        }else {
            let arrayData = [...contents];
            arrayData.splice(index, 1);
            setContents(arrayData);
        }
    }

    function MeasureHeight(){
        setTimeout(() => {
            if(height?.current){
                setMedHeight(height?.current.clientHeight + 40);
            }
        }, 700);
    }

    useEffect(()=>{
        RegisterDataPage(props.origin, setDataPage);
        RegisterListPag('currentPageId', setIdPage);
        
        LoadingData();
        MeasureHeight();

        return ()=>{
            UnRegisterDataPage(props.origin, setDataPage);
            UnRegisterListPag('currentPageId', setIdPage);
        };
    }, []);

    useEffect(()=>{
        setDataPage(GetDataPage(props.origin));
        setIdPage(GetListPag('currentPageId'));
        
        setImgOrigin(InitialData('img_origin'));
        setImgName(InitialData('img_name'));
        
        setBgImg(InitialData('bg_img'));
        setStatus(false);
        setBgColor(InitialData('bg_color'));
        setFile(InitialData('file'));
        setStatusImg(false);
        setImgExample(InitialData('img_example'));
        setStatusSample(false);

        setLineUp(InitialData('line_up'));
        setBtn_1_name(InitialData('btn_1_name'));
        setBtn_2_name(InitialData('btn_2_name'));
        setText_1(InitialData('text_1'));
        setContents(InitialData('contents'));

        LoadingData();
        MeasureHeight();
    }, [dataPage, idPage]);

    useEffect(()=>{
        MeasureHeight();
    }, [text_1]);

    return(
        <div className="ModelSite_Profile">
            <div className="list_data_page">
                {
                    imgExample == "" ? null : 
                    <div className="div_input space_div title_side">
                        <div className="div_input">
                            <input type="text" className="data_input" onChange={ (e)=>{ HandleImgOrigin(e.target.value) } } value={ imgOrigin } maxLength="80" />
                            <span className="name_input">Assinatura - Origem</span>
                        </div>
                        <div className="div_input">
                            <input type="text" className="data_input" onChange={ (e)=>{ HandleImgName(e.target.value) } } value={ imgName } maxLength="80" />
                            <span className="name_input">Assinatura - Nome</span>
                        </div>
                    </div>
                } 
                
                <div className="div_input space_div title_side">
                    <div className="div_show_img">
                        <div className={ status == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                            <label>
                                { status == true ? "Imagem de fundo adicionada" : "Adicionar imagem de fundo" }
                                <input type="file" onChange={ (e)=>{ HandleBgImg(e.target.files[0]); } } accept="image/*"/>
                            </label>
                        </div>
                        {
                            bgImg !='' ?
                            <>
                                <div className="delete_img_add" onClick={ ()=>{ HandleBgImg(''); } }>
                                    <SvgDelete color="#FFFFFF" className="icons" />
                                </div>
                                {
                                    status == true ?
                                    <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(bgImg)) } }>
                                        <SvgImg color="#FFFFFF" className="icons"/>
                                    </div> :
                                    <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "profile/" + bgImg) } }>
                                        <SvgImg color="#FFFFFF" className="icons"/>
                                    </div>
                                }
                            </> : null
                        }  
                    </div>

                    <div className="div_input space_div">
                        <input type="text" className="input_color" onChange={ (e)=>{ HandleBgColor(e.target.value) } } value={ bgColor } maxLength="140" />
                        <span className="name_input">Cor de fundo</span>
                    </div>
                    <div className="div_input">
                        <div className="div_color" style={ { background: bgColor } } />
                    </div>
                </div>
                
                <div className="div_input space_div title_side">
                    <div className="div_input">
                        <select className="data_select" onChange={ (e)=>{ HandleLineUp(e.target.value) } } value={ lineUp }>
                            <option value="left">Left</option>
                            <option value="right">Right</option>
                        </select>
                        <span className="name_input">Tipo</span>
                    </div>

                    <div className="div_show_img">
                        <div className={ statusImg == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                            <label>
                                { statusImg == true ? "Foto adicionada" : "Adicionar foto" }
                                <input type="file" onChange={ (e)=>{ HandleFile(e.target.files[0]); } } accept="image/*"/>
                            </label>
                        </div>
                        {
                            file !='' ?
                            <>
                                <div className="delete_img_add" onClick={ ()=>{ HandleFile(''); } }>
                                    <SvgDelete color="#FFFFFF" className="icons" />
                                </div>
                                {
                                    statusImg == true ?
                                    <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(file)) } }>
                                        <SvgImg color="#FFFFFF" className="icons"/>
                                    </div> :
                                    <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "profile/" + file) } }>
                                        <SvgImg color="#FFFFFF" className="icons"/>
                                    </div>
                                }
                            </> : null
                        }  
                    </div>

                    <div className="div_show_img">
                        <div className={ statusSample == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                            <label>
                                { statusSample == true ? "imagem de exemplo adicionada" : "Adicionar imagem de exemplo" }
                                <input type="file" onChange={ (e)=>{ HandleImgExample(e.target.files[0]); } } accept="image/*"/>
                            </label>
                        </div>
                        {
                            imgExample !='' ?
                            <>
                                <div className="delete_img_add" onClick={ ()=>{ HandleImgExample(''); } }>
                                    <SvgDelete color="#FFFFFF" className="icons" />
                                </div>
                                {
                                    statusSample == true ?
                                    <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(imgExample)) } }>
                                        <SvgImg color="#FFFFFF" className="icons"/>
                                    </div> :
                                    <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "profile/" + imgExample) } }>
                                        <SvgImg color="#FFFFFF" className="icons"/>
                                    </div>
                                }
                            </> : null
                        }  
                    </div>
                    <div className="div_input space_div">
                        <input type="text" className="data_input" onChange={ (e)=>{ HandleBtn_1_name(e.target.value) } } value={ btn_1_name } maxLength="80" />
                        <span className="name_input">Nome 1</span>
                    </div>
                    <div className="div_input space_div">
                        <input type="text" className="data_input" onChange={ (e)=>{ HandleBtn_2_name(e.target.value) } } value={ btn_2_name } maxLength="80" />
                        <span className="name_input">Nome 2</span>
                    </div>
                </div>
                
                <div className="div_input space_div">
                    <JoditEditor className="show_textarea" config={ config } value={ text_1.replaceAll('&#34;', '"') } onBlur={ newContent => HandleText_1(newContent) } />
                    <span className="name_input">Descrição</span>
                </div>

                <div className="div_input space_div">
                    <div className="type_title" style={ { marginBottom: '0px' } }>
                        <div className="">Link</div>
                        <div className="cursor_pointer" onClick={ ()=>{ AddNewData(); } }>
                            + Adicionar novo link
                        </div>
                    </div>
                </div>
                {
                    contents.map((key, index)=>{
                        return(
                            <div className="div_input space_div title_side" key={ index }>                                
                                <div className="div_input space_div">
                                    <input type="text" className="data_input" onChange={ (e)=>{ HandleContents('text', index, e.target.value) } } value={ key.text } maxLength="80" />
                                    <span className="name_input">Link</span>
                                </div>
                                <div className="div_show_img">
                                    <div className={ key.status == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                        <label>
                                            { key.status == true ? "Ícone adicionado" : "Adicionar ícone" }
                                            <input type="file" onChange={ (e)=>{ HandleContents('file', index, e.target.files[0]) } } accept="image/*"/>
                                        </label>
                                    </div>
                                    {
                                        key.file !='' ?
                                        <>
                                            <div className="delete_img_add" onClick={ ()=>{ HandleImgExample(''); } }>
                                                <SvgDelete color="#FFFFFF" className="icons" />
                                            </div>
                                            {
                                                key.status == true ?
                                                <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(key.file)) } }>
                                                    <img alt={ "img_" + index } src={ URL.createObjectURL(key.file) } className="icons_profile"/>
                                                </div> :
                                                <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "profile/" + key.file) } }>
                                                    <img alt={ "img_" + index } src={ "./assets/profile/" + key.file } className="icons_profile"/>
                                                </div>
                                            }
                                        </> : null
                                    }   
                                    <div onClick={ ()=>{ RemuveDataAdd(key.id, index, "link") } }>
                                        <SvgDelete color="#F00000" className="icons" />
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }

                <div className="div_input div_space space_top">
                    <div className="div_show_data div_space img">
                        {
                            btn_1_name == "" || btn_2_name == "" || text_1 == "" ?
                                lineUp == "left" ? 
                                <img alt="home_example" src="./assets/bg_profile_right.jpg" className="img_example" /> :
                                <img alt="home_example" src="./assets/bg_profile_left.jpg" className="img_example" />
                                :
                            <div className="ModelPages_TeacherProfile" style={ { height: medHeight + "px" } }>
                                <div className="show_profile">
                                    {
                                        lineUp == "left" ?
                                        <>
                                            <div className="file">
                                                {
                                                    statusSample == true ?
                                                    <img alt="img" src={ URL.createObjectURL(imgExample) } className="img_bg" /> : 
                                                    <img alt="img" src={ "./assets/profile/" + imgExample } className="img_bg" />
                                                }
                                            </div>
                                            <div ref={ height } className="show_data">
                                                <div className="name_profile">
                                                    <div className="">
                                                        {
                                                            file == true ?
                                                            <img alt="img" src={ URL.createObjectURL(file) } className="img_profile" /> : 
                                                            <img alt="img" src={ "./assets/profile/" + file } className="img_profile" />
                                                        }
                                                    </div>
                                                    <div className="div_name">
                                                        <span className="type_1_name">{ btn_1_name }</span>
                                                        <span className="subname">{ btn_2_name }</span>
                                                    </div>
                                                </div>
                                                <div className="text" dangerouslySetInnerHTML={ { __html: text_1 ? text_1.replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
                                                <div className="portfolio text">
                                                    <div className="">Portfólio</div>
                                                    {
                                                        contents.map((key, index)=>{
                                                            return(
                                                                <div className="" key={ index }>
                                                                    <a href={ key.text } target="_blank">
                                                                        <img alt="" src={ "./assets/profile/" + key.file } className="icons_" />
                                                                    </a>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <>
                                            <div ref={ height } className="show_data">
                                                <div className="name_profile">
                                                    <div className="">
                                                        {
                                                            file == true ?
                                                            <img alt="img" src={ URL.createObjectURL(file) } className="img_profile" /> : 
                                                            <img alt="img" src={ "./assets/profile/" + file } className="img_profile" />
                                                        }
                                                    </div>
                                                    <div className="div_name">
                                                        <span className="type_1_name">{ btn_1_name }</span>
                                                        <span className="subname">{ btn_2_name }</span>
                                                    </div>
                                                </div>
                                                <div className="text" dangerouslySetInnerHTML={ { __html: text_1 ? text_1.replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
                                                <div className="portfolio text">
                                                    <div className="">Portfólio</div>
                                                    {
                                                        contents.map((key, index)=>{
                                                            return(
                                                                <div className="" key={ index }>
                                                                    <a href={ key.text } target="_blank">
                                                                        <img alt="" src={ "./assets/profile/" + key.file } className="icons_" />
                                                                    </a>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                            <div className="file">
                                                {
                                                    statusSample == true ?
                                                    <img alt="img" src={ URL.createObjectURL(imgExample) } className="img_bg" /> : 
                                                    <img alt="img" src={ "./assets/profile/" + imgExample } className="img_bg" />
                                                }
                                            </div>
                                        </>
                                    }
                                </div>
                                <div className="background_image">
                                    {
                                        status == true ?
                                        <img alt="img" src={ URL.createObjectURL(bgImg) } className="img_home" /> : 
                                        <img alt="img" src={ "./assets/profile/" + bgImg } className="img_home" />
                                    }
                                </div>
                            </div>
                        }  
                    </div>
                </div>
            </div>
        </div>
    );

}