import { useState, useEffect } from "react";

import './Details.css';

import Select from 'react-select';

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";
import { GetUserData, RegisterUserData, UnRegisterUserPage } from "interface/Users";
import { GetListPag, RegisterListPag, SetListPag, UnRegisterListPag } from "interface/Page";

import { Reg_UserDash } from "services/RegisterData";

import { SvgAddNewData, SvgDelete, SvgImg, SvgReturn, SvgSave, SvgSetaRight } from "components/SvgFile";

export default function Page_AccessDash_Details(props){
    const [ userId, setUserId ]     = useState(GetUserData('id'));
    const [ dataPage, setDataPage ] = useState(GetDataPage('access_dash'));
    const [ idPage, setIdPage ]     = useState(GetListPag('currentPageId'));

    const [ name, setName ]     = useState(InitialData('name'));
    const [ pass, setPass ]     = useState('');
    const [ email, setEmail ]   = useState(InitialData('email'));
    const [ file, setFile ]     = useState(InitialData('file'));
    const [ page, setPage ]     = useState(InitialData('page'));
    const [ access, setAccess ] = useState(InitialData('access'));
    const [ opt, setOpt ]       = useState([
        { "value": "0/courses", "label": "Cursos" },
        { "value": "1/students", "label": "Alunos" },
        { "value": "2/team", "label": "Turmas" },
        { "value": "3/site/home/Home", "label": "Site - Home" },
        { "value": "4/site/howWorks/Como funciona", "label": "Site - Como funciona" },
        { "value": "5/site/mentoring/Mentoria", "label": "Site - Mentoria" },
        { "value": "6/site/rolo/Rolo", "label": "Site - Rolo" },
        { "value": "7/site/payment/Tipo de pagamento", "label": "Site - Tipo de pagamento" },
        { "value": "8/site/nameMenu/Páginas do menu", "label": "Site - Páginas do menu" },
        { "value": "9/financial/entry/Entrada", "label": "Financeiro - Entrada" },
        { "value": "10/financial/exit/Saída", "label": "Financeiro - Saída" },
        { "value": "11/financial/balance/Balanço", "label": "Financeiro - Balanço" },
        { "value": "12/indicators", "label": "Indicadores" }
    ]);
    const [ addPage, setAddPage ] = useState([]);

    const [ stateFile, setStateFile ] = useState(false);
    const [ stateOpt, setStateOpt ]   = useState(false);

    useEffect(()=>{
        RegisterUserData('id', setUserId);
        RegisterDataPage('access_dash', setDataPage);
        RegisterListPag('currentPageId', setIdPage);

        return ()=>{
            UnRegisterUserPage('id', setUserId);
            UnRegisterDataPage('access_dash', setDataPage);
            UnRegisterListPag('currentPageId', setIdPage);
        };
    }, []);

    useEffect(()=>{
        setDataPage(GetDataPage('access_dash'));
        setIdPage(GetListPag('currentPageId'));

        setName(InitialData('name'));
        setEmail(InitialData('email'));
        setFile(InitialData('file'));
        setPage(InitialData('page'));
        setAccess(InitialData('access'));
        
        setAddPage([]);
        setStateFile(false);
        setStateOpt(false);
    }, [dataPage, idPage]);

    function InitialData(type){
        if(idPage !=0){
            const newData = dataPage.find(item => item.id == idPage);
            return newData[type];
        }
        if(type == 'page'){
            return [];
        }
        return '';
    }

    function ReturnPage(){
        SetListPag('currentPage', 'access_dash');
        SetListPag('currentPageId', 'remuve');
    }

    function SaveData(event){
        event.preventDefault();
        props.setLoading(true);
        Reg_UserDash(userId, idPage, name, pass, email, file, access, addPage, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        setAddPage([]);
        setStateOpt(false);
        setStateFile(false);
        
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    function AddFile(value){
        if(value){
            setFile(value);
            setStateFile(true);
        }else {
            setStateFile(false);
        }
    }

    function OpenFile(type, file){
        SetModalData('ShowFile', { "type": type, "file": file });
        SetModalState('ShowFile', true);
    }

    function DeleteData(type, id, value){
        SetModalData('Confirmation', { "origin": "access_dash", "type" : type, "id" : id, "name": value, "setLoading": props.setLoading });
        SetModalState('Confirmation', true);
    }

    return(
        <div className="Page_AccessDash_Details">
            <form onSubmit={ SaveData }>
                <div className="new_block">
                    <div className="div_return" onClick={ ()=>{ ReturnPage() }}>
                        <SvgReturn className="icons" color="#061821" />
                        Voltar
                    </div>
                    <div className="title_page">Detalhes</div>
                    <button className="div_add_new_data">
                        <div className="new_data_add">
                            <SvgSave color="#ffffff" className="icons" />
                        </div>
                        <div className="name_btn_add">Salvar</div>
                    </button>
                </div>
                <div className="show_page_data">
                    <div className="type_title">Dados do usúario</div>
                    <div className="list_input_data">
                        <div className="div_input space_div">
                            <input type="text" className="data_input" onChange={ (e)=>{ setName(e.target.value) } } value={ name } maxLength="140" required />
                            <span className="name_input">Nome</span>
                        </div>
                        <div className="div_input space_div">
                            <input type="email" className="data_input" onChange={ (e)=>{ setEmail(e.target.value) } } value={ email.replaceAll(' ', '') } maxLength="140" required />
                            <span className="name_input">E-mail</span>
                        </div>
                        <div className="div_input">
                            <input type="password" className="pass" onChange={ (e)=>{ setPass(e.target.value) } } maxLength="20" />
                            <span className="name_input">Senha</span>
                        </div>
                        {
                            idPage == userId ? null :
                            <div className="div_input">
                                <select className="data_select" onChange={ (e)=>{ setAccess(e.target.value) } } value={ access }>
                                    <option value="0">Professor</option>
                                    <option value="1">Financeiro</option>
                                    <option value="2">Administrador</option>
                                </select>
                                <span className="name_input">Usuário</span>
                            </div>
                        } 
                        <div className="div_show_img">
                            <div className={ stateFile == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                <label>
                                    { stateFile == true ? "Foto adicionada" : "Adicionar foto" }
                                    <input type="file" onChange={ (e)=>{ AddFile(e.target.files[0]); } } accept="image/*"/>
                                </label>
                            </div>
                            {
                                file !='' ?
                                <>
                                    <div className="delete_img_add" onClick={ ()=>{ setFile(''); setStateFile(false); } }>
                                        <SvgDelete color="#FFFFFF" className="icons" />
                                    </div>
                                    {
                                        stateFile == true ?
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(file)) } }>
                                            <SvgImg color="#FFFFFF" className="icons"/>
                                        </div> :
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "login/" + file) } }>
                                            <SvgImg color="#FFFFFF" className="icons"/>
                                        </div>
                                    }
                                </> : null
                            }  
                        </div> 
                    </div>
                </div>
                {
                    access == 2 ? null :
                    <div className="show_page_data">
                        <div className="type_title">
                            <div className="">Páginas liberadas</div>
                            <div className="cursor_pointer" onClick={ ()=>{ setStateOpt(!stateOpt) } }>
                                {
                                    stateOpt == true ? "Cancelar" : "Liberar nova página"
                                }
                            </div>
                        </div>
                        {
                            stateOpt == true ?
                            <div className="list_input_data">
                                <div className="div_input space_div">
                                    <div className="div_space">
                                        <Select className="div_space" defaultOptions options={ opt } onChange={ (e)=> { setAddPage(e); } } value={ addPage } placeholder="Clique aqui para visualizar as páginas..." isMulti />
                                    </div>
                                </div> 
                            </div> 
                            : null
                        }  
                        <div className="list_input_data">
                            {
                                page.length > 0 ?
                                    page.map((key, index)=>{
                                        if(key.submenu.length > 0){
                                            return(
                                                <div className="list_sub_menu" key={ index }>
                                                    {
                                                        key.submenu.map((key_1, index_1)=>{
                                                            return(
                                                                <div className="div_input" key={ index_1 }>
                                                                    <div className="page_released">
                                                                        <div className="">
                                                                            { key.name } - { key_1.page }
                                                                        </div>
                                                                        <div className="" onClick={ ()=>{ DeleteData('delete_financial', key.id + "/" + key_1.id, key.name) } }>
                                                                            <SvgDelete color="#FFFFFF" className="icons" />
                                                                        </div>
                                                                    </div>    
                                                                </div> 
                                                            )
                                                        })
                                                    }
                                                </div>
                                            )

                                        }else {
                                            return(
                                                <div className="div_input" key={ index }>
                                                    <div className="page_released">
                                                        <div className="">
                                                            { key.name }
                                                        </div>
                                                        <div className="" onClick={ ()=>{ DeleteData('delete_page', key.id, key.name) } }>
                                                            <SvgDelete color="#FFFFFF" className="icons" />
                                                        </div>
                                                    </div>    
                                                </div> 
                                            )
                                        }
                                    })
                                : 
                                stateOpt == true ? null : <div className="no_data">Nenhuma página liberada até o momento...</div>
                            }      
                        </div>
                    </div>
                }
            </form>
        </div>
    )
}