import { useState, useEffect } from "react";

import './Historic.css';

import { SvgEye, SvgEyeClose, SvgReturn } from "components/SvgFile";

import { GetDataPage } from "interface/Data";
import { GetUserData, RegisterUserData, UnRegisterUserPage } from "interface/Users";
import { GetListPag, RegisterListPag, SetListPag, UnRegisterListPag } from "interface/Page";
import { SetModalData, SetModalState } from "interface/PopUp";

export default function Page_Course_Historic(props){

    const [ userId, setUserId ]     = useState(GetUserData('id'));
    const [ dataPage, setDataPage ] = useState(GetDataPage('courses'));
    const [ idPage, setIdPage ]     = useState(GetListPag('currentPageId'));

    const [ title, setTitle ] = useState(InitialData('title'));
    const [ questions, setQuestions ] = useState(InitialData('questions'));

    function InitialData(type){
        const newData = GetDataPage('courses').find(item => item.id == idPage);
        if(newData){              
            return newData[type];
        }else {            
            SetListPag('currentPage', 'courses');
            SetListPag('currentPageId', 'remuve');
        }
    }

    function OpenInf(elem, text){
        SetModalData('Questions', { "showData": elem, "text": text });
        SetModalState('Questions', true);
    }

    function OpenText(text){
        SetModalData('QuestionsText', text);
        SetModalState('QuestionsText', true);
    }   

    useEffect(()=>{
        RegisterUserData('id', setUserId);
        RegisterListPag('currentPageId', setIdPage);

        return ()=>{
            UnRegisterUserPage('id', setUserId);
            UnRegisterListPag('currentPageId', setIdPage);
        };
    }, []);   

    return(
        <div className="Page_Historic">
            <div className="new_block">
                <div className="div_return" onClick={ ()=>{ SetListPag('currentPage', 'courses_details') }}>
                    <SvgReturn className="icons" color="#061821" />
                    Voltar
                </div>
                <div className="title_page">{ title }</div>
                <div className="title_page">Histórico do curso</div>
            </div>

            <div className="show_page_data">
                <div className="type_title">
                    <div className="">Alunos que responderam o questionário</div>
                </div>
                <div className="list_input_data line_top">
                    <table width="100%" cellPadding="0" cellSpacing="0">
                        <thead>
                            <tr>
                                <th width="20" align="center">#</th>
                                <th>Nome</th>
                                <th width="340">Respostas</th>
                                <th width="80">Data</th>
                                <th width="40">Hora</th>
                                <th width="20">#</th>
                            </tr>
                        </thead>
                        <tbody>    
                            {
                                questions.map((elem, index)=>{                                                 
                                    return(                                    
                                        <tr key={ index }>
                                            <td align="center">{ index + 1 }</td>
                                            <td>{ elem.name_user }</td>
                                            <td>                                                
                                                <div className="div_opt_alt_respose">
                                                    {
                                                        elem.list_questions.map((elem_1, inde_1)=>{
                                                            let comma = ',';
                                                            if((elem.list_questions.length - 1) == inde_1){
                                                                comma = '';
                                                            }

                                                            return(
                                                                <div className="" key={ inde_1 }>
                                                                    { elem_1.title } { elem_1.opt }{ comma }
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                    {
                                                        elem.text == '' ? null :
                                                        <div className="" onClick={ ()=>{ OpenText(elem.text) } } style={ { cursor: "pointer" } }>
                                                            - Opnião
                                                        </div>
                                                    }
                                                </div>
                                            </td>
                                            <td>{ elem.date }</td>
                                            <td>{ elem.hour }</td>
                                            <td onClick={ ()=>{ OpenInf(elem.list_questions, elem.text) } }>
                                                <SvgEye className="icons" color="#061821" />
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}
