import { useState, useEffect } from "react";

import './Details.css';

import Select from 'react-select';

import { config } from "dataFixed";
import JoditEditor from 'jodit-react';

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";
import { GetUserData, RegisterUserData, UnRegisterUserPage } from "interface/Users";
import { GetListPag, RegisterListPag, SetListPag, UnRegisterListPag } from "interface/Page";

import { SvgAddNewData, SvgArchive, SvgDelete, SvgDownload, SvgEdit, SvgHistoric, SvgImg, SvgLockClose, SvgLockOpen, SvgReturn, SvgSave, SvgSetaDown, SvgSetaRight, SvgSetaUp, SvgUpload, SvgVimeo, SvgYoutube } from "components/SvgFile";

import { Reg_AltPosition, Reg_EditOrRegisterCourses } from "services/RegisterData";

import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';

export default function Page_Courses_Details(props){

    const [ loading, setLoading ]     = useState(false);
    const [ userId, setUserId ]       = useState(GetUserData('id'));
    const [ dataPage, setDataPage ]   = useState(GetDataPage('courses'));
    const [ optSelect, setOptSelect ] = useState(GetDataPage('list_opt_select'));
    const [ idPage, setIdPage ]       = useState(GetListPag('currentPageId'));
    
    const [ typeVideo, setTypeVideo ] = useState(InitialData('type_video'));
    const [ video, setVideo ]         = useState(InitialData('video'));

    const [ file, setFile ]             = useState(InitialData('file'));
    const [ uploadFile, setUploadFile ] = useState(false);
    const [ img, setImg ]               = useState(InitialData('img'));
    const [ uploadImg, setUploadImg ]   = useState(false);

    const [ highlight, setHighlight ] = useState(InitialData('highlight'));
    const [ title, setTitle ]         = useState(InitialData('title'));
    const [ subtitle, setSubtitle ]   = useState(InitialData('subtitle'));

    const [ free, setFree ]     = useState(InitialData('free'));
    const [ bronze, setBronze ] = useState(InitialData('bronze'));
    const [ prata, setPrata ]   = useState(InitialData('prata'));
    const [ gold, setGold ]     = useState(InitialData('gold'));

    const [ category, setCategory ]     = useState(InitialData('category'));
    const [ text, setText ]             = useState(InitialData('text'));
    const [ hotPage, setHotPage ]       = useState(InitialData('hot_page'));
    const [ listModule, setListModule ] = useState(InitialData('list_module'));
    const [ listQuestions, setListQuestions ] = useState(InitialData('questions'));
    
    const [ listCategory, setListCategory ] = useState(ListCategory());

    function InitialData(type){
        if(idPage !=0){
            if(GetDataPage('courses').length > 0){
                const newData = GetDataPage('courses').find(item => item.id == idPage);
                return newData[type];
            }
        }
        if(type == 'observation'){
            return GetDataPage('courses')[0].observation;
        }

        if(type == 'type_video'){
            return "Vimeo";
        }

        if(type == 'free' || type == 'bronze' || type == 'prata' || type == 'gold' || type == 'highlight'){
            return false;
        }

        if(type == 'category' || type == 'list_classes' || type == 'questions'){
            return [];
        }
        return '';
    }

    function ReturnPage(){
        SetListPag('currentPage', 'courses');
        SetListPag('currentPageId', 'remuve');
        SetListPag('currentPageIdSegund', 'remuve');
    }

    function OpenFile(type, file){
        SetModalData('ShowFile', { "type": type, "file": file });
        SetModalState('ShowFile', true);
    }

    function AddFile(type, value){
        switch (type) {
            case 'img':
                    if(value){
                        setFile(value);
                        setUploadFile(true);
                    }else {
                        setFile('');
                        setUploadFile(false);
                    }
                break;

            case 'banner':
                    if(value){
                        setImg(value);
                        setUploadImg(true);
                    }else {
                        setImg('');
                        setUploadImg(false);
                    }
                break;
        }
    }

    function ListCategory(){
        const options = [];
        optSelect.map((key, index)=>{
            if(key.type == "category"){
                if(options.find(item => item.value == key.id)){ }else {
                    options.push({ "value": key.id, "label": key.name });  
                }
            }          
        })
        return options;
    }

    function AddOptCategory(){
        setCategory([...category, { "id": 0, "id_category": 0, "name": "" }]);
    }

    function HandleCategory(type, index, nameCategory){
        const newData        = [...category];
        newData[index][type] = nameCategory;
        setCategory(newData);
    }

    function DeleteCategory(index, id, value){
        if(id == 0){
            const newData = [...category];
            newData.splice(index, 1);
            setCategory(newData);
        }else {
            SetModalData('Confirmation', { "origin": "courses", "type" : "delete_category", "id" : id, "name": value, "setLoading": props.setLoading });
            SetModalState('Confirmation', true);
        }
    }

    function DeleteData(id, value){
        SetModalData('Confirmation', { "origin": "courses", "type" : "delete_classe", "id" : id, "name": value, "setLoading": props.setLoading });
        SetModalState('Confirmation', true);
    }

    function ChangePosition(typeOrder, id, order, type){
        props.setLoading(true);
        Reg_AltPosition(typeOrder, id, order, type, ()=>{ props.setLoading(false) }, ()=>{ CallbackError() });
    }

    function SaveData(event){
        props.setLoading(true);
        event.preventDefault();
        Reg_EditOrRegisterCourses(idPage, typeVideo, video, file, img, highlight, title, subtitle, free, bronze, prata, gold, category, text, hotPage, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    function PageClick(id){
        SetListPag('currentPage', 'courses_details_classes');
        SetListPag('currentPageIdSegund', id);
    }

    useEffect(()=>{
        RegisterUserData('id', setUserId);
        RegisterDataPage('courses', setDataPage);
        RegisterDataPage('list_opt_select', setOptSelect);
        RegisterListPag('currentPageId', setIdPage);

        return ()=>{
            UnRegisterUserPage('id', setUserId);
            UnRegisterDataPage('courses', setDataPage);
            UnRegisterDataPage('list_opt_select', setOptSelect);
            UnRegisterListPag('currentPageId', setIdPage);
        };
    }, []);

    useEffect(()=>{
        setDataPage(GetDataPage('courses'));
        setOptSelect(GetDataPage('list_opt_select'));
        setIdPage(GetListPag('currentPageId'));
        
        setTypeVideo(InitialData('type_video'));
        setVideo(InitialData('video'));
        setFile(InitialData('file'));
        setImg(InitialData('img'));
        setHighlight(InitialData('highlight'))
        setTitle(InitialData('title'));
        setSubtitle(InitialData('subtitle'));
        setFree(InitialData('free'));
        setBronze(InitialData('bronze'));
        setPrata(InitialData('prata'));
        setGold(InitialData('gold'));
        setCategory(InitialData('category'));
        setText(InitialData('text'));
        setHotPage(InitialData('hot_page'));
        setListModule(InitialData('list_module'));
        setListQuestions(InitialData('questions'));

        setUploadFile(false);
        setUploadImg(false);

        setListCategory(ListCategory());
    }, [dataPage, idPage]);

    return(
        <div className="Page_Courses_Details">
            <form onSubmit={ SaveData }>
                <div className="new_block">
                    <div className="div_return" onClick={ ()=>{ ReturnPage() }}>
                        <SvgReturn className="icons" color="#061821" />
                        Voltar
                    </div>
                    <div className="title_page">Detalhes</div>
                    <div className="list_opt_alt_page">
                        
                        {
                            listQuestions.length > 0 ?
                                <div className="" data-tooltip-id="historic_course" data-tooltip-content="Histórico do curso" data-tooltip-place="top" onClick={ ()=>{ SetListPag('currentPage', 'courses_historic') } }>
                                    <SvgHistoric className="icons" color="#061821" />
                                </div>
                            : null
                        }
                        <Tooltip id="historic_course" />

                        <button className="div_add_new_data">
                            <div className="new_data_add">
                                <SvgSave color="#ffffff" className="icons" />
                            </div>
                            <div className="name_btn_add">Salvar</div>
                        </button>
                    </div>
                </div>

                <div className="div_high">
                    <div className="">Quem pode acessar</div>
                    <div className={ free == true ? "high high_active" : "high" } onClick={ ()=>{ setFree(!free) } }>
                        Free
                    </div>
                    <div className={ bronze == true ? "high high_active" : "high" } onClick={ ()=>{ setBronze(!bronze) } }>
                        Bronze
                    </div>
                    <div className={ prata == true ? "high high_active" : "high" } onClick={ ()=>{ setPrata(!prata) } }>
                        Prata
                    </div>
                    <div className={ gold == true ? "high high_active" : "high" } onClick={ ()=>{ setGold(!gold) } }>
                        Ouro
                    </div>
                </div>

                <div className="show_page_data">
                    <div className="type_title">Dados do curso</div>
                    <div className="list_input_data">
                        <div className="div_input">
                            <select className="select_video" onChange={ (e)=>{ setTypeVideo(e.target.value) } } value={ typeVideo }>
                                <option value="Vimeo">Vimeo</option>
                                <option value="Youtube">Youtube</option>
                            </select>
                            <span className="name_input">Tipo</span>
                        </div>
                        <div className="div_input">
                            <input type="text" className="input_code" onChange={ (e)=>{ setVideo(e.target.value); } } value={ video.replaceAll(' ', '') } maxLength="18" required />
                            <span className="name_input">Código</span>
                        </div>
                        {
                            video !='' ?
                            <div className="div_input" onClick={ ()=>{ OpenFile(typeVideo + "Code", video) } }>
                                {
                                    typeVideo == 'Vimeo' ?
                                    <SvgVimeo color="#ffffff" className="icons"/> :
                                    <SvgYoutube color="#ffffff" className="icons"/>
                                }
                            </div> : ""
                        }
                        
                        <div className="div_show_img">
                            <div className={ uploadImg == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                <label>
                                    { uploadImg == true ? "Banner adicionada" : "Alterar banner" }
                                    <input type="file" onChange={ (e)=>{ AddFile('banner', e.target.files[0]); } } accept="image/*"/>
                                </label>
                            </div>
                            {
                                img !='' ?
                                <>
                                    <div className="delete_img_add" onClick={ ()=>{ AddFile('banner', ''); } }>
                                        <SvgDelete color="#FFFFFF" className="icons" />
                                    </div>
                                    {
                                        uploadImg == true ?
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(img)) } }>
                                            <img alt="img fundo" src={ URL.createObjectURL(img) } className="img_examplo" />
                                        </div> :
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "courses/img/" + img) } }>
                                            <img alt="img fundo" src={ "./assets/courses/img/" + img } className="img_examplo" />
                                        </div>
                                    }
                                </> : null
                            }  
                        </div>
                        <div className="div_switch">
                            <label>Destacar</label>
                            <label className="switch">
                                <div className="switch_wrapper">
                                    <input type="checkbox" value="true" onChange={ ()=>{ setHighlight(!highlight) } } checked={ highlight } />
                                    <span className="switch_button"></span>
                                </div>
                            </label>
                        </div>
                    </div>
                    <div className="list_input_data">
                        <div className="div_input space_div">
                            <input type="text" className="" onChange={ (e)=>{ setTitle(e.target.value) } } value={ title } maxLength="80" required />
                            <span className="name_input">Título</span>
                        </div>
                        
                        {/* 
                        <div className="div_input space_div">
                            <input type="text" className="" onChange={ (e)=>{ setTitle(e.target.value) } } value={ title } maxLength="40" required />
                            <span className="name_input">Título em destaque no slide</span>
                        </div>
                        */}
                        
                        <div className="div_show_img">
                            <div className={ uploadFile == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                <label>
                                    { uploadFile == true ? "Foto adicionada" : "Alterar foto" }
                                    <input type="file" onChange={ (e)=>{ AddFile('img', e.target.files[0]); } } accept="image/*"/>
                                </label>
                            </div>
                            {
                                file !='' ?
                                <>
                                    <div className="delete_img_add" onClick={ ()=>{ AddFile('img', ''); } }>
                                        <SvgDelete color="#FFFFFF" className="icons" />
                                    </div>
                                    {
                                        uploadFile == true ?
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(file)) } }>
                                            <img alt="img fundo" src={ URL.createObjectURL(file) } className="img_examplo" />
                                        </div> :
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "courses/img/" + file) } }>
                                            <img alt="img fundo" src={ "./assets/courses/img/" + file } className="img_examplo" />
                                        </div>
                                    }
                                </> : null
                            }  
                        </div> 
                    </div>
                    <div className="list_input_data">
                        <div className="div_input space_div">
                            <JoditEditor className="show_textarea" config={ config } value={ text != '' ? text.replaceAll('&#34;', '"') : "" } onBlur={ newContent => setText(newContent) } />
                            <span className="name_input">Descrição</span>
                        </div>
                    </div>
                    <div className="list_input_data">
                        <div className="div_show_data_specific">
                            <div className="type_title">
                                <div className="">Categoria</div>
                                <div className="cursor_pointer" onClick={ ()=>{ AddOptCategory(); } }>
                                    + categoria
                                </div>
                            </div>
                            <div className="div_list_mult_data">
                                {
                                    category.length > 0 ?
                                        category.map((key, index)=>{
                                            return(
                                                <div className="page_released" key={ index }>
                                                    <div className="div_show_select">
                                                        <select className="select_type" onChange={ (e)=> { HandleCategory('id_category', index, e.target.value); } } value={ key.id_category }>
                                                            <option value="0">Adicionar nova categoria</option>
                                                            {
                                                                listCategory.map((key_1, index_1)=>{

                                                                    return(
                                                                        <option className="option" value={ key_1.value } key={ index_1 }>{ key_1.label }</option>
                                                                    )
                                                                })
                                                            }
                                                        </select>
                                                        {
                                                            key.id_category != 0 ? null :
                                                            <div className="">
                                                                <input type="text" className="input_new_module" onChange={ (e)=>{ HandleCategory('name', index, e.target.value); } } maxLength="80" required placeholder="nome..." />
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className="" onClick={ ()=>{ DeleteCategory(index, key.id, key.name) } }>
                                                        <SvgDelete color="#FFFFFF" className="icons" />
                                                    </div>
                                                </div>   
                                            )
                                        })
                                    : <div className="no_data">Nenhuma categoria adicionada até o momento...</div>
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div className="show_page_data">
                    <div className="type_title">
                        <div className="">Descrição da hotpage</div>
                    </div>
                    <div className="list_input_data">
                        <div className="div_input space_div">
                            <JoditEditor className="show_textarea" config={ config } value={ hotPage !='' ? hotPage.replaceAll('&#34;', '"') : "" } onBlur={ newContent => setHotPage(newContent) } />
                        </div>
                    </div>
                </div>

                {
                    idPage === 0 ? null :                
                    <div className="show_page_data">
                        <div className="type_title">
                            <div className="">Aulas</div>
                            <div className="cursor_pointer" onClick={ ()=>{ PageClick(0) } }>
                                Adicionar nova aula
                            </div>
                        </div>
                        <div className="list_input_data">
                            {
                                listModule.length > 0 ?
                                <table width="100%" cellPadding="0" cellSpacing="0">
                                    <tbody>
                                        <tr>
                                            <th width="20" align="center">#</th>
                                            <th>Título</th>
                                            <th width="60" align="right"></th>
                                            <th width="60" align="right"></th>
                                        </tr>
                                    </tbody>
                                    {
                                        listModule.map((key, index)=>{                                            
                                            return(
                                                <tbody key={ index }>
                                                    <tr>
                                                        <td colSpan="4">
                                                            <div className="div_order_module">
                                                                {
                                                                    index == 0 ? <div className="space_order"></div> :
                                                                    <div className="space_order" onClick={ ()=>{ ChangePosition('modules', key.id, key.order_ + "/" + key.id_course + "/" + key.id_module + "/" + listModule[index - 1]['id_module'], 'up') } }>
                                                                        <SvgSetaUp color="#324d6b" className="icons"/>
                                                                    </div>
                                                                }
                                                                {
                                                                    (index + 1) == listModule.length ?
                                                                    <div className="space_order"></div> :
                                                                    <div className="space_order" onClick={ ()=>{ ChangePosition('modules', key.id, key.order_ + "/" + key.id_course + "/" + key.id_module + "/" + listModule[index + 1]['id_module'], 'down') } }>
                                                                        <SvgSetaDown color="#324d6b" className="icons"/>
                                                                    </div>
                                                                }
                                                                <div className="">
                                                                    <span className="title_table">Módulo:</span> 
                                                                    { key.name }
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    {
                                                        key.list_classe.map((key_1, index_1)=>{
                                                            return(
                                                                <tr key={ index_1 }>
                                                                    <td align="center">{ index_1 + 1 }</td>
                                                                    <td>{ key_1.title }</td>
                                                                    <td>
                                                                        <div className="div_opt_alt">
                                                                            {
                                                                                index_1 == 0 ?
                                                                                <div className="space_div"></div> :
                                                                                <div onClick={ ()=>{ ChangePosition('courses_details', key_1.id, key_1.order_ + "/" + key_1.id + "/" + key.list_classe[index_1 - 1]['id'], 'up') } }>
                                                                                    <SvgSetaUp color="#324d6b" className="icons"/>
                                                                                </div>
                                                                            }
                                                                            {
                                                                                (index_1 + 1) == key.list_classe.length ?
                                                                                <div className="space_div"></div> :
                                                                                <div onClick={ ()=>{ ChangePosition('courses_details', key_1.id, key_1.order_ + "/" + key_1.id + "/" + key.list_classe[index_1 + 1]['id'], 'down') } }>
                                                                                    <SvgSetaDown color="#324d6b" className="icons"/>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="div_opt_alt">
                                                                            {
                                                                                key_1.type == 'upload' ?
                                                                                <div className="">
                                                                                    <SvgUpload color="#324d6b" className="icons"/>
                                                                                </div> :
                                                                                <div onClick={ ()=>{ OpenFile(key_1.type_video + "Code", key_1.video) } }>
                                                                                    {
                                                                                        key_1.video !='' ?
                                                                                            key_1.type_video == 'Vimeo' ?
                                                                                            <SvgVimeo color="#324d6b" className="icons"/> :
                                                                                            <SvgYoutube color="#324d6b" className="icons"/> : null
                                                                                    }
                                                                                </div>
                                                                            }
                                                                            <div>
                                                                                {
                                                                                    key_1.free == true ?
                                                                                    <SvgLockOpen color="#324d6b" className="icons lock" /> :
                                                                                    <SvgLockClose color="#324d6b" className="icons lock" />
                                                                                }
                                                                            </div>
                                                                            <div onClick={ ()=>{ DeleteData(key_1.id, key_1.title) } }>
                                                                                <SvgDelete color="#F00000" className="icons"/>
                                                                            </div>
                                                                            <div onClick={ ()=>{ PageClick(key_1.id) } }>
                                                                                <SvgEdit color="#324d6b" className="icons"/>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            )
                                        })
                                    }
                                </table> : 
                                <div className="no_data">Nenhuma aula adicionada até o momento...</div>
                            }
                        </div>
                    </div>
                }
            </form>
        </div>
    )
}