import { useState, useEffect } from "react";

import './StudentWorks.css';

import JoditEditor from 'jodit-react';
import { config, typeCookiePageId } from "dataFixed";

import { SvgDelete, SvgImg, SvgVimeo, SvgYoutube } from "components/SvgFile";

import { Reg_NewData_Pages } from "services/RegisterData";

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";
import { GetListPag, RegisterListPag, UnRegisterListPag } from "interface/Page";

export default function ModelSite_StudentWorks(props){

    const [ idPage, setIdPage ]     = useState(GetListPag('currentPageId'));
    const [ dataPage, setDataPage ] = useState(GetDataPage(props.origin));

    const [ imgOrigin, setImgOrigin ] = useState(InitialData('img_origin'));
    const [ imgName, setImgName ]     = useState(InitialData('img_name'));

    const [ typeVideo, setTypeVideo ] = useState(InitialData('video_type'));
    const [ video, setVideo ]         = useState(InitialData('video_code'));
    const [ bgColor, setBgColor ]     = useState(InitialData('bg_color'));
    const [ img, setImg ]             = useState(InitialData('bg_img'));
    const [ title, setTitle ]         = useState(InitialData('title_1'));
    const [ status, setStatus ]       = useState(InitialData('status'));

    function InitialData(type){
        if(idPage !=0){
            if(dataPage.length > 0){
                const newData = dataPage.find(item => item.id == idPage);
                return newData[type];
            }
        }
        if(type == 'video_type'){
            return 'Vimeo';
        }
        return '';
    }

    function LoadingData(){
        props.ReturnData({ "img_origin": imgOrigin, "img_name": imgName, "typeVideo": typeVideo, "video": video, "bg_color": bgColor, "img": img, "title": title});
    }

    function HandleImgOrigin(value){
        setImgOrigin(value);
        props.ReturnData({ "img_origin": value, "img_name": imgName, "typeVideo": typeVideo, "video": video, "bg_color": bgColor, "img": img, "title": title});
    }

    function HandleImgName(value){
        setImgName(value);
        props.ReturnData({ "img_origin": imgOrigin, "img_name": value, "typeVideo": typeVideo, "video": video, "bg_color": bgColor, "img": img, "title": title});
    }

    function HandleTypeVideo(value){
        setTypeVideo(value);
        props.ReturnData({ "img_origin": imgOrigin, "img_name": imgName, "typeVideo": value, "video": video, "bg_color": bgColor, "img": img, "title": title});
    }

    function HandleVideo(value){
        setVideo(value);
        props.ReturnData({ "img_origin": imgOrigin, "img_name": imgName, "typeVideo": typeVideo, "video": value, "bg_color": bgColor, "img": img, "title": title});
    }

    function HandleBgColor(value){
        setBgColor(value);
        props.ReturnData({ "img_origin": imgOrigin, "img_name": imgName, "typeVideo": typeVideo, "video": video, "bg_color": value, "img": img, "title": title});
    }

    function HandleImg(value){
        setImg(value);
        value ? setStatus(true) : setStatus(false);
        props.ReturnData({ "img_origin": imgOrigin, "img_name": imgName, "typeVideo": typeVideo, "video": video, "bg_color": bgColor, "img": value, "title": title});
    }

    function HandleTitle(value){
        setTitle(value);
        props.ReturnData({ "img_origin": imgOrigin, "img_name": imgName, "typeVideo": typeVideo, "video": video, "bg_color": bgColor, "img": img, "title": value});
    }

    function OpenFile(type, file){
        SetModalData('ShowFile', { "type": type, "file": file });
        SetModalState('ShowFile', true);
    }

    useEffect(()=>{
        RegisterDataPage(props.origin, setDataPage);
        RegisterListPag('currentPageId', setIdPage);

        LoadingData();

        return ()=>{
            UnRegisterDataPage(props.origin, setDataPage);
            UnRegisterListPag('currentPageId', setIdPage);
        };
    }, []);

    useEffect(()=>{
        setDataPage(GetDataPage(props.origin));
        setIdPage(GetListPag('currentPageId'));
        
        setImgOrigin(InitialData('img_origin'));
        setImgName(InitialData('img_name'));
        
        setTypeVideo(InitialData('video_type'));
        setVideo(InitialData('video_code'));
        setBgColor(InitialData('bg_color'));
        setImg(InitialData('bg_img'));
        setTitle(InitialData('title_1'));
        setStatus(InitialData('status'));

        LoadingData();
    }, [dataPage, idPage]);

    return(
        <div className="ModelSite_StudentWorks">
            <div className="list_data_page">

                <div className="div_input space_div title_side">  
                    {
                        img == "" ? null : 
                        <>
                            <div className="div_input">
                                <input type="text" className="data_input" onChange={ (e)=>{ HandleImgOrigin(e.target.value) } } value={ imgOrigin } maxLength="80" />
                                <span className="name_input">Assinatura - Origem</span>
                            </div>
                            <div className="div_input">
                                <input type="text" className="data_input" onChange={ (e)=>{ HandleImgName(e.target.value) } } value={ imgName } maxLength="80" />
                                <span className="name_input">Assinatura - Nome</span>
                            </div>
                        </>
                    } 
                    <div className="div_input">
                        <select className="data_select" onChange={ (e)=>{ HandleTypeVideo(e.target.value) } } value={ typeVideo }>
                            <option value="Vimeo">Vimeo</option>
                            <option value="Youtube">Youtube</option>
                        </select>
                        <span className="name_input">Tipo</span>
                    </div>
                    <div className="div_input">
                        <input type="text" className="data_input" onChange={ (e)=>{ HandleVideo(e.target.value) } } value={ video } maxLength="80" />
                        <span className="name_input">Código do vídeo</span>
                    </div>
                    {
                        video !='' ?
                        <div className="div_input" onClick={ ()=>{ OpenFile(typeVideo, video) } }>
                            {
                                typeVideo == 'Vimeo' ?
                                <SvgVimeo color="#324d6b" className="icons_video"/> :
                                <SvgYoutube color="#324d6b" className="icons_video"/>
                            }
                        </div> : ""
                    }
                </div>

                <div className="div_input space_div title_side">
                    <div className="div_show_img">
                        <div className={ status == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                            <label>
                                { status == true ? "Imagem de fundo adicionada" : "Adicionar imagem de fundo" }
                                <input type="file" onChange={ (e)=>{ HandleImg(e.target.files[0]); } } accept="image/*"/>
                            </label>
                        </div>
                        {
                            img !='' ?
                            <>
                                <div className="delete_img_add" onClick={ ()=>{ setImg(''); setStatus(false); } }>
                                    <SvgDelete color="#FFFFFF" className="icons" />
                                </div>
                                {
                                    status == true ?
                                    <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(img)) } }>
                                        <SvgImg color="#FFFFFF" className="icons"/>
                                    </div> :
                                    <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "studentworks/" + img) } }>
                                        <SvgImg color="#FFFFFF" className="icons"/>
                                    </div>
                                }
                            </> : null
                        }  
                    </div>

                    <div className="div_input space_div">
                        <input type="text" className="data_input" onChange={ (e)=>{ HandleBgColor(e.target.value) } } value={ bgColor } maxLength="140" />
                        <span className="name_input">Cor de fundo</span>
                    </div>
                    <div className="div_input">
                        <div className="div_color" style={ { background: bgColor } } />
                    </div>
                </div>
                
                <div className="div_input space_div">
                    <input type="text" className="data_input" onChange={ (e)=>{ HandleTitle(e.target.value) } } value={ title } maxLength="80" />
                    <span className="name_input">Título</span>
                </div>

                <div className="div_input div_space space_top">
                    <div className="div_show_data div_space img">
                        {
                            title == "" || video == "" ? 
                            <img alt="home_example" src="./assets/bg_student.jpg" className="img_example" /> :
                            <div className="ModelPages_StudentWorks" style={ { background: bgColor } }>
                                <div className="show_data">
                                    <div className="show_video">
                                        <div className="title show_video_space_right">{ title }</div>
                                        <div className="show_video show_video_space_right">
                                            {
                                                typeVideo == "Vimeo" ?
                                                    video == "" ? null :
                                                    <iframe className="div_video" src={ "https://player.vimeo.com/video/" + video + "?color=ffffff&amp;title=0&amp;byline=0&amp;portrait=0" } frameBorder="0" webkitallowfullscreen="" mozallowfullscreen="" allowFullScreen="" />
                                                :
                                                    video == "" ? null :
                                                    <iframe className="div_video" src={ "https://www.youtube.com/embed/" + video + "?color=ffffff&amp;title=0&amp;byline=0&amp;portrait=0" } frameBorder="0" webkitallowfullscreen="" mozallowfullscreen="" allowFullScreen="" />
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="background_image">
                                    {
                                        status == true ?
                                        <img alt="img" src={ URL.createObjectURL(img) } className="img_home" /> : 
                                        <img alt="img" src={ "./assets/studentworks/" + img } className="img_home" />
                                    }
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );

}