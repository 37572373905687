import React, { useState, useEffect } from "react";

import './QuestionsText.css';

import { SvgClose } from "components/SvgFile";

import { RegisterModalData, RegisterModalObserver, SetModalData, SetModalState } from "interface/PopUp";

export default function PopUp_QuestionsText(){

    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);

    const [ text, setText ] = useState('');

    function ClosePopUp(){
        setText('');
        SetModalState('QuestionsText', false);
    }

    useEffect(()=>{
        RegisterModalData('QuestionsText', setModaldata);
        RegisterModalObserver('QuestionsText', setShowPopUp);
    }, []);  
    
    useEffect(()=>{
        if(showPopUp){
            setText(modalData);
        }
    }, [showPopUp]);

    return (
        (showPopUp ?
            <div className="PopUp">
                <div className="all Questions">
                    <div className="div_data">
                        <div className="title">
                            Observação preenchida no questionário do curso
                        </div>
                        <div className="close" onClick={ ()=>{ ClosePopUp() } }>
                            <SvgClose color="#F00000" className="icons" />
                        </div>
                    </div>
                    <div className="div_data list_inf_" style={ { paddingTop: 0 } }>
                        <div className="list_inf_questions">
                            <div className="conclusion_title">Observação</div>
                            <div className="" dangerouslySetInnerHTML={ { __html: text.replaceAll('&#34;', '"') } } />
                        </div>
                    </div>
                </div>
                <div className="close_all" onClick={ ()=>{ ClosePopUp(); } } />
            </div>
        : <React.Fragment></React.Fragment>)
    );
}