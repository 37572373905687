import React, { useState, useEffect } from "react";

import './DetailsCourse.css';

import { SvgClose, SvgDownload, SvgUpload, SvgVimeo, SvgYoutube } from "components/SvgFile";

import { SetModalState, RegisterModalData, RegisterModalObserver, SetModalData } from "interface/PopUp";

import { GetDataPage, RegisterDataPage } from "interface/Data";
import { Reg_TeamStatusClasses } from "services/RegisterData";

export default function PopUP_DetailsCourse(){

    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);
    const [ courses, setCourses ]     = useState(GetDataPage('courses'));

    const [ title, setTitle ]         = useState('');
    const [ typeVideo, setTypeVideo ] = useState('');
    const [ video, setVideo ]         = useState('');
    const [ listData, setListData ]   = useState([]);

    useEffect(()=>{
        RegisterModalData('DetailsCourse', setModaldata);
        RegisterModalObserver('DetailsCourse', setShowPopUp);

        RegisterDataPage('courses', setCourses);
    }, []);

    useEffect(()=>{
        if(showPopUp == true){
            setTitle(ShowDataPage('subtitle'));
            setTypeVideo(ShowDataPage('type_video'));
            setVideo(ShowDataPage('video'));
            setListData(ShowDataPage('list_module'));
        }
    }, [showPopUp]);

    function ShowDataPage(type){
        const newData = courses.find(item => item.id == modalData.id);
        return newData[type];
    }

    function OpenFile(type, file){
        SetModalData('ShowFile', { "type": type, "file": file });
        SetModalState('ShowFile', true);
    }

    function ClosePopUp(){
        SetModalState('DetailsCourse', false);
    }

    return (
        (showPopUp ?
            <>
                <div className="PopUp">
                    <div className="all details_course">
                        <div className="div_data">
                            <div className="title">
                                Detalhes do curso
                            </div>
                            <div className="close" onClick={ ()=>{ ClosePopUp() } }>
                                <SvgClose color="#F00000" className="icons" />
                            </div>
                        </div>
                        <div className="div_data" style={ { paddingTop: 0 } }>
                            <div className="content">
                                <div className="course_data">
                                    <div className="name_course">Curso: { title }</div>
                                    <div className="" onClick={ ()=>{ OpenFile(typeVideo + "Code", video) } }>
                                        {
                                            typeVideo == 'Vimeo' ?
                                            <SvgVimeo color="#ffffff" className="icons"/> :
                                            <SvgYoutube color="#ffffff" className="icons"/>
                                        }
                                    </div>
                                </div>
                                {
                                    listData.map((elem, index)=>{
                                        return(
                                            <div className="border_module" key={ index }>
                                                <div className="course_data">
                                                    <div className="name_course">Módulo: { elem.name  }</div>
                                                </div>
                                                <div className="border_module list_classe">
                                                    <div className="name_course">Título</div>
                                                    <div className="div_icons">#</div>
                                                </div>
                                                {
                                                    elem.list_classe.map((elem_1, index_1)=>{
                                                        return(
                                                            <div className="border_module list_classe" key={ index_1 }>
                                                                <div className="name_course">{ elem_1.title }</div>
                                                                {
                                                                    elem_1.type == 'upload' ? 
                                                                    <div className="div_icons">
                                                                        <SvgUpload color="#061821" className="icons"/>
                                                                    </div>
                                                                    :
                                                                    <div className="div_icons" onClick={ ()=>{ OpenFile(typeVideo + "Code", elem_1.video) } }>
                                                                        {
                                                                            elem_1.type_video == 'Vimeo' ?
                                                                            <SvgVimeo color="#061821" className="icons"/> :
                                                                            <SvgYoutube color="#061821" className="icons"/>
                                                                        }
                                                                    </div>
                                                                }
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    <div className="close_all" onClick={ ()=>{ ClosePopUp(); } } />
                </div>
            </>
        : <React.Fragment></React.Fragment>)
    );
}
