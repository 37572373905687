import { useState, useEffect } from "react";

import './Questionnaire.css';

import { SvgAddNewData, SvgDelete, SvgEdit, SvgSearch, SvgSetaUp, SvgSetaDown } from "components/SvgFile";

import { SetListPag } from "interface/Page";
import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";
import { GetUserData, RegisterUserData, UnRegisterUserPage } from "interface/Users";

import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';

export default function Site_Questionnaire(){

    const [ loading, setLoading ] = useState(false);
    const [ search, setSearch ] = useState('');
    const [ userId, setUserId ] = useState(GetUserData('id'));
    const [ showPageData, setShowPageData ] = useState(GetDataPage('questionnaire'));

    function SearchInput(value){
        const newList = [];
        if(value){
            GetDataPage('questionnaire').forEach(item =>{
                if(item.title.toLowerCase().indexOf(value.toLowerCase()) != -1){
                    newList.push(item);
                }
                if(item.text.toLowerCase().indexOf(value.toLowerCase()) != -1){
                    newList.push(item);
                }
            });
            const duplicate = newList.filter((item, index) => newList.indexOf(item) === index);
            setShowPageData(duplicate);
        }else {
            setShowPageData(GetDataPage('questionnaire'));
        }
        setSearch(value);
    }

    function DeleteData(id, value){
        SetModalData('Confirmation', { "origin": "questionnaire", "type" : "delete_all", "id" : id, "name": value });
        SetModalState('Confirmation', true);
    }

    function CallbackSuccess(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    function PageClick(idPage){
        SetListPag('currentPage', 'questionnaire_details')
        SetListPag('currentPageId', idPage);
    }

    useEffect(()=>{
        RegisterUserData('id', setUserId);
        RegisterDataPage('questionnaire', setShowPageData);

        return ()=>{
            UnRegisterUserPage('id', setUserId);
            UnRegisterDataPage('questionnaire', setShowPageData);
        };
    }, []);

    return(
        <div className="Site_Questionnaire">
            <div className={ loading == true ? "return_data_save div_loading" : "return_data_save" }>
                <img alt="loading" src="./assets/loading.gif" className="loading" />
            </div>
            <div className="new_block">
                <div className="input_search">
                    <SvgSearch color="#111827" className="icons_search" />
                    <input type="text" className="search" onChange={ (e)=>{ SearchInput(e.target.value) } } value={ search } required placeholder="pesquisar..." />
                </div>
                <div className="title_page">Questionário do site</div>
                <div className="list_opt_alt_page"> 
                    <div className="div_add_new_data" onClick={ ()=>{ PageClick(0) } }>
                        <div className="new_data_add">
                            <SvgAddNewData color="#ffffff" className="icons" />
                        </div>
                        <div className="name_btn_add">pergunta</div>
                    </div>
                </div>
            </div>
            <div className="list_data_page">
                <table width="100%" cellPadding="0" cellSpacing="0">
                    <tbody>
                        <tr>
                            <th width="20" align="center">#</th>
                            <th>Título</th>
                            <th>Descrição</th>
                            <th width="60" align="right"></th>
                        </tr>
                    </tbody>
                    <tbody>
                        {
                            showPageData.map((key, index)=>{
                                return(
                                    <tr key={ index }>
                                        <td align="center">{ index + 1 }</td>
                                        <td>{ key.title }</td>
                                        <td>{ key.text }</td>
                                        <td align="right">
                                            <div className="div_opt_alt">
                                                <div data-tooltip-id={ "del_" + key.id } data-tooltip-content="Apagar pergunta" data-tooltip-place="top" onClick={ ()=>{ DeleteData(key.id, key.title) } }>
                                                    <SvgDelete color="#F00000" className="icons"/>
                                                </div>
                                                <Tooltip id={ "del_" + key.id } />

                                                <div data-tooltip-id={ "edit_" + key.id } data-tooltip-content="Editar pergunta" data-tooltip-place="top" onClick={ ()=>{ PageClick(key.id) } }>
                                                    <SvgEdit color="#324d6b" className="icons"/>
                                                </div>
                                                <Tooltip id={ "edit_" + key.id } />
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}