import { useState, useEffect } from "react";

import './Classes.css';

import Select from 'react-select';

import { config } from "dataFixed";
import JoditEditor from 'jodit-react';

import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";
import { SetModalData, SetModalState } from "interface/PopUp";
import { GetListPag, RegisterListPag, SetListPag, UnRegisterListPag } from "interface/Page";

import { SvgDelete, SvgGif, SvgHistoric, SvgImg, SvgPdf, SvgReturn, SvgSave, SvgVimeo, SvgYoutube } from "components/SvgFile";

import { Reg_EditOrRegisterClasses } from "services/RegisterData";

export default function Page_Courses_Details_Classes(props){

    const [ dataPage, setDataPage ]   = useState(GetDataPage('courses'));
    const [ optSelect, setOptSelect ] = useState(GetDataPage('list_opt_select'));

    const [ idCourse, setIdCourse ]   = useState(GetListPag('currentPageId'));
    const [ idPage, setIdPage ]       = useState(GetListPag('currentPageIdSegund'));

    const [ intro, setIntro ]         = useState(InitialData('introduction'));
    const [ type, setType ]           = useState(InitialData('type'));
    const [ typeVideo, setTypeVideo ] = useState(InitialData('type_video'));
    const [ video, setVideo ]         = useState(InitialData('video'));
    const [ free, setFree ]           = useState(InitialData('free'));
    const [ module, setModule ]       = useState(InitialData('id_modules'));
    const [ moduleAlt, setModuleAlt ] = useState(InitialData('id_modules'));
    const [ newModule, setNewModule ] = useState('');
    const [ title, setTitle ]         = useState(InitialData('title'));
    const [ download, setDownload ]   = useState(InitialData('downloads'));
    const [ tips, setTips ]           = useState(InitialData('tips'));
    const [ link, setLink ]           = useState(InitialData('link'));

    const [ listModule, setListModule ] = useState(ListModules());
    
    function InitialData(type){
        if(idPage !=0){
            const showCourse = GetDataPage('courses').find(item => item.id == idCourse);
            if(showCourse){
                const showModule = showCourse.list_module.filter(item => item.id_course == idCourse);
                if(showModule){
                    const showDataClasse = [];
                    showModule.map((elem, index)=>{
                        elem.list_classe.map((elem_1, index_1)=>{
                            if(elem_1.id === parseInt(idPage)){
                                showDataClasse.push(elem_1);
                            }
                        })
                    });
                    
                    if(showDataClasse){
                        return showDataClasse[0][type];
                    }
                    if(type == 'introduction'){
                        return 'Não';
                    }
                    if(type == 'downloads'){
                        return [];
                    }        
                    if(type == 'free'){
                        return false;
                    }
                    if(type == 'type'){
                        return "classe";
                    }        
                    if(type == 'type_video'){
                        return "Vimeo";
                    }      
                    if(type == 'id_modules'){
                        return 0;
                    }
                    return '';
                }
                if(type == 'introduction'){
                    return 'Não';
                }
                if(type == 'downloads'){
                    return [];
                }        
                if(type == 'free'){
                    return false;
                }
                if(type == 'type'){
                    return "classe";
                }        
                if(type == 'type_video'){
                    return "Vimeo";
                }      
                if(type == 'id_modules'){
                    return 0;
                }
                return '';
            }
            if(type == 'introduction'){
                return 'Não';
            }
            if(type == 'downloads'){
                return [];
            }        
            if(type == 'free'){
                return false;
            }
            if(type == 'type'){
                return "classe";
            }        
            if(type == 'type_video'){
                return "Vimeo";
            }      
            if(type == 'id_modules'){
                return 0;
            }
            return '';
        }
        if(type == 'introduction'){
            return 'Não';
        }
        if(type == 'downloads'){
            return [];
        }        
        if(type == 'free'){
            return false;
        }
        if(type == 'type'){
            return "classe";
        }        
        if(type == 'type_video'){
            return "Vimeo";
        }      
        if(type == 'id_modules'){
            return 0;
        }
        return '';
    }

    function ListModules(){
        const options = [];
        optSelect.map((key, index)=>{
            if(key.type == "module"){
                if(options.find(item => item.value == key.id)){ }else {
                    options.push({ "value": key.id, "label": key.name });  
                }
            }
        })
        return options;
    }

    function ReturnPage(){
        SetListPag('currentPage', 'courses_details');
        SetListPag('currentPageIdSegund', 'remuve');
    }

    function OpenFile(type, file){
        SetModalData('ShowFile', { "type": type, "file": file });
        SetModalState('ShowFile', true);
    }

    function AddFile(type, value, index){
        const newData = [...download];
        switch(type){
            case "remuve_img":
                    newData[index].img        = '';
                    newData[index].update_img = false;
                    setDownload(newData);
                break;

            case "remuve_others":
                    newData[index].file        = '';
                    newData[index].update_file = false;
                    setDownload(newData);
                break;

            case "file":
                    newData[index].file        = value;
                    newData[index].update_file = true;
                    setDownload(newData);
                break;

            case "img":
                    newData[index].img       = value.file;
                    newData[index].positionX = value.positionX;
                    newData[index].positionY = value.positionY;
                    newData[index].width     = value.width;
                    newData[index].height    = value.height;
                    newData[index].showWidth = value.showWidth;
                    newData[index].widthReal = value.widthReal;
                    newData[index].update_img = true;
                    setDownload(newData);
                break;
        }
    }

    function AddOptDownload(){
        setDownload([...download, { "id": 0, "file": "", "update_file": false, "title": "", "img": "", "update_img": false, "positionX": "", "positionY": "", "width": "", "height": "", "showWidth": "", "widthReal": "" }])
    }    

    function HandleDataDownload(index, value){
        const newData = [...download];
        newData[index].title = value;
        setDownload(newData);
    }

    function DeleteDownload(index, id, value){
        if(id == 0){
            const newData = [...download];
            newData.splice(index, 1);
            setDownload(newData);
        }else {
            SetModalData("Confirmation", { "origin": "courses", "type" : "delete_classes_download", "id" : id, "name": value, "setLoading": props.setLoading });
            SetModalState('Confirmation', true);
        }
    }

    function PopUpCrop(index, value){
        const newData = [...download];
        SetModalData('PhotoCrop', { "index": index, "file": value, "showImg": URL.createObjectURL(value), "RegisterCropData": AddFile });
        SetModalState('PhotoCrop', true);
    }

    function SaveData(event){
        if(module !='0'){            
            props.setLoading(true);
            event.preventDefault();
            Reg_EditOrRegisterClasses(idPage, idCourse, intro, type, typeVideo, video, free, moduleAlt, module, newModule, title, download, tips, link, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });

        }else {
            SetModalData('ReturnResponse', { "page": "erro", "text": "É necessário selecionar uma módulo!" });
            SetModalState('ReturnResponse', true);
        }
    }

    function CallbackSuccess(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    useEffect(()=>{
        RegisterDataPage('courses', setDataPage);
        RegisterDataPage('list_opt_select', setOptSelect);

        RegisterListPag('currentPageId', setIdCourse);
        RegisterListPag('currentPageIdSegund', setIdPage);

        return ()=>{
            UnRegisterDataPage('courses', setDataPage);
            UnRegisterDataPage('list_opt_select', setOptSelect);
            
            UnRegisterListPag('currentPageId', setIdCourse);
            UnRegisterListPag('currentPageIdSegund', setIdPage);
        };
    }, []);

    useEffect(()=>{
        setDataPage(GetDataPage('courses'));
        setOptSelect(GetDataPage('list_opt_select'));

        setIdCourse(GetListPag('currentPageId'));
        setIdPage(GetListPag('currentPageIdSegund'));

        setIntro(InitialData('introduction'));
        setType(InitialData('type'));
        setTypeVideo(InitialData('type_video'));
        setVideo(InitialData('video'));
        setFree(InitialData('free'));
        setModule(InitialData('id_modules'));
        setModuleAlt(InitialData('id_modules'));
        setNewModule('');
        setTitle(InitialData('title'));
        setDownload(InitialData('downloads'));
        setTips(InitialData('tips'));
        setLink(InitialData('link'));

        setListModule(ListModules())
    }, [dataPage, idPage]);

    return(
        <div className="Page_Courses_Details_Classes">
            <form onSubmit={ SaveData }>
                <div className="new_block">
                    <div className="div_return" onClick={ ()=>{ ReturnPage() }}>
                        <SvgReturn className="icons" color="#061821" />
                        Voltar
                    </div>
                    <div className="title_page">Detalhes</div>
                    <div className="list_opt_alt_page">

                        <div className={ free == true ? "free free_active" : "free" } onClick={ ()=>{ setFree(!free) } }>
                            Free{ free == true ? " Ativo" : " Desativado" }
                        </div>
                        <button className="div_add_new_data">
                            <div className="new_data_add">
                                <SvgSave color="#ffffff" className="icons" />
                            </div>
                            <div className="name_btn_add">Salvar</div>
                        </button>
                    </div>
                </div>

                <div className="show_page_data">
                    <div className="type_title">Dados da Aula</div>
                    <div className="list_input_data">
                        <div className="div_input">
                            <select className="select_video" onChange={ (e)=>{ setIntro(e.target.value) } } value={ intro } required>
                                <option value="Sim">Sim</option>
                                <option value="Não">Não</option>
                            </select>
                            <span className="name_input">Introdução</span>
                        </div>
                        <div className="div_input">
                            <select className="select_video" onChange={ (e)=>{ setType(e.target.value) } } value={ type }>
                                <option value="upload">Upload</option>
                                <option value="classe">Aula</option>
                            </select>
                            <span className="name_input">Tipo</span>
                        </div>
                        {
                            type == "upload" ? null : 
                            <>                            
                                <div className="div_input">
                                    <select className="select_video" onChange={ (e)=>{ setTypeVideo(e.target.value) } } value={ typeVideo }>
                                        <option value="Vimeo">Vimeo</option>
                                        {/* <option value="Youtube">Youtube</option> */}
                                    </select>
                                    <span className="name_input">Tipo Vídeo</span>
                                </div>
                                <div className="div_input">
                                    <input type="text" className="input_code" onChange={ (e)=>{ setVideo(e.target.value); } } value={ video.replaceAll(' ', '') } maxLength="18" required />
                                    <span className="name_input">Código</span>
                                </div>
                                {
                                    video !='' ?
                                    <div className="div_input" onClick={ ()=>{ OpenFile(typeVideo + "Code", video) } }>
                                        {
                                            typeVideo == 'Vimeo' ?
                                            <SvgVimeo color="#ffffff" className="icons"/> :
                                            <SvgYoutube color="#ffffff" className="icons"/>
                                        }
                                    </div> : ""
                                }
                            </>
                        }
                    </div>

                    <div className="list_input_data">
                        <div className="div_input space_div">
                            <input type="text" className="" onChange={ (e)=>{ setTitle(e.target.value) } } value={ title } maxLength="80" required />
                            <span className="name_input">Título</span>
                        </div>
                        <div className="div_input">
                            <select className="select_module" onChange={ (e)=>{ setModule(e.target.value) } } value={ module }>
                                <option value="0">Adicionar novo módulo</option>
                                {
                                    listModule.map((elem, index)=>{
                                        return(
                                            <option className="option" value={ elem.value } key={ index }>
                                                { elem.label }
                                            </option>
                                        )
                                    })
                                }
                            </select>
                            <span className="name_input">Módulo</span>
                        </div>
                        {
                            module == "0" ?                     
                            <div className="div_input">
                                <input type="text" className="" onChange={ (e)=>{ setNewModule(e.target.value); } } maxLength="80" value={ newModule } required />
                                <span className="name_input" maxLength="14">Nome do módulo</span>
                            </div>  
                            : null
                        }
                    </div>                    
                    
                    <div className="list_input_data">
                        <div className="div_show_data_specific">
                            <div className="type_title add_new_data_array">
                                <div className="">Downloads</div>
                                <div className="cursor_pointer" onClick={ ()=>{ AddOptDownload(); } }>
                                    + Downloads
                                </div>
                            </div>
                            {
                                download.length > 0 ?
                                    download.map((key, index)=>{
                                        let iconsFile = '';
                                        if(key.update_file == false){
                                            let ext = key.file.split('.');
                                            switch (ext[1]) {
                                                case "png": case "jpg": case "jpge":
                                                        iconsFile = <SvgImg color="#ffffff" className="icons"/>;
                                                    break;
                                                
                                                case "gif":
                                                        iconsFile = <SvgGif color="#ffffff" className="icons"/>;
                                                    break;
                                            
                                                case "pdf":
                                                        iconsFile = <SvgPdf color="#ffffff" className="icons"/>;
                                                    break;
                                                    
                                                default:
                                                        iconsFile = <div className="others">?</div>;
                                                    break;
                                            }
                                        }
                                        return(
                                            <div className="div_list_mult_data div_download" key={ index }>
                                                <div className="div_input space_div">
                                                    <input type="text" className="data_input" onChange={ (e)=>{ HandleDataDownload(index, e.target.value) } } value={ key.title } maxLength="140" required />
                                                    <span className="name_input">Título</span>
                                                </div>
                                                <div className="div_show_img">
                                                    <div className={ key.update_img == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                                        <label>
                                                            { key.update_img == true ? "Imagem adicionada" : "Alterar imagem" }
                                                            <input type="file" onChange={ (e)=>{ PopUpCrop(index, e.target.files[0]); } } accept="image/*"/>
                                                        </label>
                                                    </div>
                                                    {
                                                        key.img !='' ?
                                                        <>
                                                            <div className="delete_img_add" onClick={ ()=>{ AddFile('remuve_img', '', index); } }>
                                                                <SvgDelete color="#FFFFFF" className="icons" />
                                                            </div>
                                                            {
                                                                key.update_img == true ?
                                                                <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(key.img)) } }>
                                                                    <SvgImg color="#FFFFFF" className="icons"/>
                                                                </div> :
                                                                <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "courses/img/" + key.img) } }>
                                                                    <img alt={ "img_" + index } src={ "./assets/courses/img/" + key.img } className="img_example" />
                                                                </div>
                                                            }
                                                        </> : null
                                                    }  
                                                </div> 
                                                <div className="div_show_img">
                                                    <div className={ key.update_file == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                                        <label>
                                                            { key.update_file == true ? "Arquivo adicionado" : "Alterar arquivo" }
                                                            <input type="file" onChange={ (e)=>{ AddFile('file', e.target.files[0], index); } } />
                                                        </label>
                                                    </div>
                                                    {
                                                        key.file !='' ?
                                                        <>
                                                            <div className="delete_img_add" onClick={ ()=>{ AddFile('remuve_others', '', index); } }>
                                                                <SvgDelete color="#FFFFFF" className="icons" />
                                                            </div>
                                                            {
                                                                key.update_file == true ?
                                                                <a className="show_img_" href={ URL.createObjectURL(key.file) } target="_blank">
                                                                    { iconsFile }
                                                                </a> :
                                                                <a className="show_img_" href={ "/assets/courses/others/" + key.file } target="_blank">
                                                                    { iconsFile }
                                                                </a>
                                                            }
                                                        </> : null
                                                    }  
                                                </div> 
                                                <div className="div_show_img" onClick={ ()=>{ DeleteDownload(index, key.id, key.title) } }>
                                                    <SvgDelete color="#FFFFFF" className="icons" />
                                                </div>
                                            </div>   
                                        )
                                    })
                                : <div className="no_data">Nenhuma opção de download adicionado até o momento...</div>
                            }
                        </div>
                    </div>

                    <div className="list_input_data">
                        <div className="div_input space_div">
                            <JoditEditor className="show_textarea" config={ config } value={ tips != '' ? tips.replaceAll('&#34;', '"') : "" } onBlur={ newContent => setTips(newContent) } />
                            <span className="name_input">Dicas</span>
                        </div>
                    </div>
                    
                    <div className="list_input_data">
                        <div className="div_input space_div">
                            <JoditEditor className="show_textarea" config={ config } value={ link != '' ? link.replaceAll('&#34;', '"') : "" } onBlur={ newContent => setLink(newContent) } />
                            <span className="name_input">Link</span>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}