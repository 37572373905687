import React, { useState, useEffect } from "react";

import './PageMenu.css';

import { SvgClose, SvgSetaDown, SvgSetaUp } from "components/SvgFile";

import { RegisterModalData, RegisterModalObserver, SetModalState } from "interface/PopUp";

import { GetDataPage, RegisterDataPage } from "interface/Data";
import { Reg_AltPosition, Reg_EditMenu } from "services/RegisterData";

export default function PopUp_PageMenu(){

    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);
    const [ dataPage, setDataPage ]   = useState([]);

    useEffect(()=>{
        RegisterModalData('NameMenu', setModaldata);
        RegisterModalObserver('NameMenu', setShowPopUp);
        
        RegisterDataPage('nameMenu', setDataPage);
    }, []);

    useEffect(()=>{
        if(showPopUp == true){
            setDataPage(GetDataPage('nameMenu'));
        }
    }, [showPopUp]);

    useEffect(()=>{
        setDataPage(GetDataPage('nameMenu'));
    }, [dataPage]);

    function handleData(index, value){
        const newData = [...dataPage];
        newData[index].name = value;
        setDataPage(newData);
    }

    function SaveData(event){
        event.preventDefault();
        Reg_EditMenu(dataPage, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function ChangePosition(id, order, type){
        Reg_AltPosition('nameMenu', id, order, type, ()=>{ }, ()=>{ });
    }

    function CallbackSuccess(){
        ClosePopUp();
    }

    function CallbackError(){
        ClosePopUp();
    }

    function ClosePopUp(){
        SetModalState('NameMenu', false);
    }

    return (
        (showPopUp ?
            <>
                <div className="PopUp">
                    <div className="all NameMenuSite">
                        <div className="div_data" style={ { paddingBottom: 0 } }>
                            <div className="title">
                                Nome do menu no site
                            </div>
                            <div className="close" onClick={ ()=>{ ClosePopUp() } }>
                                <SvgClose color="#F00000" className="icons" />
                            </div>
                        </div>
                        <div className="div_data" style={ { paddingTop: 0 } }>
                            <div className="content">
                                <form onSubmit={ SaveData } className="div_login">
                                    <table width="100%" cellPadding="0" cellSpacing="0">
                                        <tbody>
                                            <tr>
                                                <th width="10" align="center">#</th>
                                                <th align="left">Nome</th>
                                                <th width="60" align="right">Ordem</th>
                                            </tr>
                                        </tbody>
                                        <tbody>
                                            {
                                                dataPage.map((key, index)=>{
                                                    return(
                                                        <tr key={ index }>
                                                            <td align="center">{ index + 1 }</td>
                                                            <td>   
                                                                <div className="div_show_data">
                                                                    <input type="text" className="code" onChange={ (e)=>{ handleData(index, e.target.value) } } value={ key.name } maxLength="14" required />
                                                                </div>
                                                            </td>
                                                            <td align="right">
                                                                <div className="div_opt_alt">
                                                                    {
                                                                        index == 0 ?
                                                                        <div className="space_div"></div> :
                                                                        <div onClick={ ()=>{ ChangePosition(key.id, key.order_, 'up') } }>
                                                                            <SvgSetaUp color="#ffffff" className="icons"/>
                                                                        </div>
                                                                    }
                                                                    {
                                                                        (index + 1) == dataPage.length ?
                                                                        <div className="space_div"></div> :
                                                                        <div onClick={ ()=>{ ChangePosition(key.id, key.order_, 'down') } }>
                                                                            <SvgSetaDown color="#ffffff" className="icons"/>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                    <button className="new_block_text" style={ { width: "-webkit-fill-available" } }>Salvar</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        : <React.Fragment></React.Fragment>)
    );
}