import { useState, useEffect } from "react";

import './Indicators.css';

import ReactApexChart from "react-apexcharts";

import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";

import { SvgReturn } from "components/SvgFile";

export default function Indicators(props){
    
    const date = new Date();
    const [ showYear, setShowYear ] = useState(date.getFullYear());
    const [ currentYear, setCurrentYear ] = useState(showYear);
    
    const [ courseStated, setCourseStated ] = useState(CheckedCourse(0));

    const [ listData, setListData ] = useState([
        { "open": "", "title": "Acesso a dashboard", "qtd": InitialData('access_dash') },
        { "open": "", "title": "Cursos", "qtd": InitialData('courses') },
        { "open": "", "title": "Alunos", "qtd": InitialData('students') },
        { "open": "", "title": "Turmas", "qtd": InitialData('team') }
    ]);

    function ShowPrice(type, value){
        let showPrice = 0.00;
        const newData = GetDataPage(type).filter(item => item.status == 1 && item.show_price !=0.00);
        
        if(newData){
            newData.map((elem, index)=>{
                if(elem.payment_year == value){
                    showPrice += elem.show_price;
                }
            });
            return showPrice.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        }
        return '0,00';
    }

    function InitialData(type){
        const newData = GetDataPage(type).filter(item => item.status == 1);
        if(newData){
            return newData.length;
        }
        return 0;
    }

    function CheckedCourse(type){
        const newData = [];
        GetDataPage('students').map((elem, index)=>{
            let coursesStarted = elem.courses_started.filter(item => item.year == currentYear && item.status == type);
            if(coursesStarted.length > 0){ 
                newData.push({ "name": elem.name, "access": elem.nivel_page, "list": coursesStarted });
            }
        });        
        return newData;
    }  

    function FormatPrice(value){
        if(value > 0){
            return value.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });

        }else {
            return "0,00";
        }
    }

    /* financial */
    const [ nameFinancial, setNameFinancial ] = useState('Faturamento mensal');
    const [ financial, setFinancial ] = useState(ShowFinancial()); 
    const [ grafficFinancial, setGrafficFinancial ] = useState({
        chart: {
            type: 'line',
            height: 350,
            zoom: { enabled: false },
            toolbar: {
                show: true,
                tools: { download: true },
                export: {
                    csv: { filename: nameFinancial },
                    svg: { filename: nameFinancial },
                    png: { filename: nameFinancial }
                }
            },
        },
        plotOptions: {
            bar: { 
                columnWidth: '10%', 
                endingShape: 'rounded' 
            }
        },
        tooltip: {
            y: { 
                title: "",
                background: '#f4f4f4',
                color: '#000000'
            }
        },
        dataLabels: {
            enabled: true,
            offsetY: -10,
            style: {
                fontSize: '12px',
                colors: ["#000000"]
            },
            formatter: (value) => `${ FormatPrice(value) }`,
            background: { enabled: false }
        },
        stroke: { show: true },
        markers: {
            size: 6,
            strokeWidth: 1,
            radius: 2,
            shape: "circle"
        },
        title: {
            text: nameFinancial,
            align: '',
            style: {
                fontFamily: 'bebas neue',
                fontSize: '26px',
                fontWeight: '500',
                color: '#6c757d',
                lineHeight: 'normal'
            }
        },
        grid: {
            row: {
                colors: ['#f3f3f3', 'transparent'],
                opacity: 0.5
            },
        },
        legend: {
            show: true,
            showForSingleSeries: true,
            position: 'bottom',
            horizontalAlign: 'center',
            fontSize: '11px',
            fontFamily: 'Poppins',
            labels: {
                colors: '#333'
            }
        },
        xaxis: {
            categories: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']
        },
        yaxis: {
            labels: {
                formatter: (value) => `${ FormatPrice(value) }`
            },
            title: {
                text: 'Valor por mês',
                style: {
                    fontFamily: 'bebas neue',
                    fontSize: '12px',
                    fontWeight: 'bold',
                    color: '#6c757d'
                }
            },
        }
    }); 
    function ShowFinancial(){
        let data_entry = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        const listDataEntry = GetDataPage('balance')['entry'].find(item => item.year == currentYear); 
        if(listDataEntry){
            listDataEntry['month'].map((elem, index)=>{                
                switch (elem.month) {
                    case '01': case 1: data_entry[0] = elem.cash; break;
                    case '02': case 2: data_entry[1] = elem.cash; break;
                    case '03': case 3: data_entry[2] = elem.cash; break;
                    case '04': case 4: data_entry[3] = elem.cash; break;
                    case '05': case 5: data_entry[4] = elem.cash; break;
                    case '06': case 6: data_entry[5] = elem.cash; break;
                    case '07': case 7: data_entry[6] = elem.cash; break;
                    case '08': case 8: data_entry[7] = elem.cash; break;
                    case '09': case 9: data_entry[8] = elem.cash; break;
                    case '10': case 10: data_entry[9] = elem.cash; break;
                    case '11': case 11: data_entry[10] = elem.cash; break;
                    case '12': case 12: data_entry[11] = elem.cash; break;
                }
            });
        }
        
        let data_exit  = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        const listDataExit = GetDataPage('balance')['exit'].find(item => item.year == currentYear); 
        if(listDataExit){
            listDataExit['month'].map((elem, index)=>{                
                switch (elem.month) {
                    case '01': case 1: data_entry[0] = elem.cash; break;
                    case '02': case 2: data_entry[1] = elem.cash; break;
                    case '03': case 3: data_entry[2] = elem.cash; break;
                    case '04': case 4: data_entry[3] = elem.cash; break;
                    case '05': case 5: data_entry[4] = elem.cash; break;
                    case '06': case 6: data_entry[5] = elem.cash; break;
                    case '07': case 7: data_entry[6] = elem.cash; break;
                    case '08': case 8: data_entry[7] = elem.cash; break;
                    case '09': case 9: data_entry[8] = elem.cash; break;
                    case '10': case 10: data_entry[9] = elem.cash; break;
                    case '11': case 11: data_entry[10] = elem.cash; break;
                    case '12': case 12: data_entry[11] = elem.cash; break;
                }
            });
        }

        return [
            {
                name: "Entrada",
                data: data_entry
            },
            {
                name: "Saída",
                data: data_exit
            }
        ];
    }
    /* end */
   
    /* Most attended courses */ 
    const [ nameCoursesWatched, setNameCoursesWatched ] = useState('Cursos mais assistido');
    const [ coursesWatched, setCoursesWatched ] = useState(ShowCoursesWatched(currentYear)); 
    const [ grafficCoursesWatched, setGrafficCoursesWatched ] = useState({
        chart: {
            type: 'bar',
            height: 350,
            zoom: { enabled: false },
            toolbar: {
                show: true,
                tools: { download: true },
                export: {
                    csv: { filename: nameCoursesWatched },
                    svg: { filename: nameCoursesWatched },
                    png: { filename: nameCoursesWatched }
                }
            },
        },
        plotOptions: {
            bar: { 
                columnWidth: '10%', 
                endingShape: 'rounded' 
            }
        },
        tooltip: {
            y: { 
                title: {
                    formatter: (seriesName) => `${seriesName}: `
                },
                formatter: (value) => `${Math.floor(value)}`, // Formata como inteiro
                background: '#f4f4f4',
                color: '#333',
            }
        },
        dataLabels: {
            enabled: true,
            offsetY: -10,
            style: {
                fontSize: '12px',
                colors: ["#ffffff"]
            },
            formatter: (value) => `${ value }`,
            background: { enabled: false }
        },
        stroke: { show: false },
        markers: {
            size: 6,
            strokeWidth: 1,
            radius: 2,
            shape: "circle"
        },
        title: {
            text: nameCoursesWatched,
            align: '',
            style: {
                fontFamily: 'bebas neue',
                fontSize: '26px',
                fontWeight: '500',
                color: '#6c757d',
                lineHeight: 'normal'
            }
        },
        grid: {
            row: {
                colors: ['#f3f3f3', 'transparent'],
                opacity: 0.5
            },
        },
        legend: {
            show: true,
            showForSingleSeries: true,
            position: 'bottom',
            horizontalAlign: 'center',
            fontSize: '11px',
            fontFamily: 'Poppins',
            labels: {
                colors: '#333'
            }
        },
        xaxis: {
            categories: ['Nome dos cursos'],
            title: { text: '' }
        },
        yaxis: {
            labels: {
                formatter: (value) => Math.floor(value),
            },
            title: {
                text: 'Número de cursos',
                style: {
                    fontFamily: 'bebas neue',
                    fontSize: '12px',
                    fontWeight: 'bold',
                    color: '#6c757d'
                }
            },
        },
        series: coursesWatched
    }); 
    function ShowCoursesWatched(value){
        const newData = [];
        GetDataPage('students').map((elem, index)=>{
            let coursesStarted = elem.courses_started.filter(item => item.year == currentYear);
            if(coursesStarted.length > 0){
                coursesStarted.map((elem_1, index_1)=>{
                    let infCourse = GetDataPage('courses').find(item => item.id == elem_1.id_course);
                    if(newData.find(item => item.name == infCourse.title)){
                        let indexCourse = newData.findIndex(item => item.name == infCourse.title);
                        newData[indexCourse]['data'] = [newData[indexCourse]['data'].length + 1];
                    }else {
                        newData.push({
                            name: infCourse.title,
                            data: [1]
                        });
                    }
                });
            }
        });        
        return newData;
    }   
    /* end */
   
    /* Courses with completed questionnaire */ 
    const [ nameGrafficCourses, setNameGrafficCourses ] = useState('Cursos com questionário respondido');
    const [ coursesWithCompleted, setCoursesWithCompleted ] = useState(ShowDataCourses(currentYear));
    const [ grafficCourse, setGrafficCourse ] = useState({
        chart: {
            type: 'bar',
            height: 350,
            zoom: { enabled: false },
            toolbar: {
                show: true,
                tools: { download: true },
                export: {
                    csv: { filename: nameGrafficCourses },
                    svg: { filename: nameGrafficCourses },
                    png: { filename: nameGrafficCourses }
                }
            },
        },
        plotOptions: {
            bar: { 
                columnWidth: '10%', 
                endingShape: 'rounded' 
            }
        },
        tooltip: {
            y: { 
                title: {
                    formatter: (seriesName) => `${seriesName}: `
                },
                formatter: (value) => `${Math.floor(value)}`, // Formata como inteiro
                background: '#f4f4f4',
                color: '#333',
            }
        },
        dataLabels: {
            enabled: true,
            offsetY: -10,
            style: {
                fontSize: '12px',
                colors: ["#ffffff"]
            },
            formatter: (value) => `${ value }`,
            background: { enabled: false }
        },
        stroke: { show: false },
        markers: {
            size: 6,
            strokeWidth: 1,
            radius: 2,
            shape: "circle"
        },
        title: {
            text: nameGrafficCourses,
            align: '',
            style: {
                fontFamily: 'bebas neue',
                fontSize: '26px',
                fontWeight: '500',
                color: '#6c757d',
                lineHeight: 'normal'
            }
        },
        grid: {
            row: {
                colors: ['#f3f3f3', 'transparent'],
                opacity: 0.5
            },
        },
        legend: {
            show: true,
            showForSingleSeries: true,
            position: 'bottom',
            horizontalAlign: 'center',
            fontSize: '11px',
            fontFamily: 'Poppins',
            labels: {
                colors: '#333'
            }
        },
        xaxis: {
            categories: ['Nome dos cursos concluídos'],
            title: { text: '' }
        },
        yaxis: {
            labels: {
                formatter: (value) => Math.floor(value),
            },
            title: {
                text: 'Número de cursos concluídos',
                style: {
                    fontFamily: 'bebas neue',
                    fontSize: '12px',
                    fontWeight: 'bold',
                    color: '#6c757d'
                }
            },
        },
        series: coursesWithCompleted
    }); 
    function ShowDataCourses(value){
        const newData = [];
        GetDataPage('courses').map((elem, index)=>{            
            let infQuestions = elem.questions.filter(item => item.year == currentYear);
            if(infQuestions.length > 0){
                newData.push({
                    name: elem.title,
                    data: [elem.questions.length]
                });
            }
        });     
        return newData;
    }
    /* end */

    /* Students started the course but did not finish */
    const [ nameGrafficStarted, setNameGrafficStarted ] = useState('Curso iniciados mas não terminado');
    const [ studentStarted, setStudentsStarted ] = useState(ShowStarted(currentYear)); 
    const [ grafficStarted, setGrafficStarted ] = useState({
        chart: {
            type: 'bar',
            height: 350,
            zoom: { enabled: false },
            toolbar: {
                show: true,
                tools: { download: true },
                export: {
                    csv: { filename: nameGrafficStarted },
                    svg: { filename: nameGrafficStarted },
                    png: { filename: nameGrafficStarted }
                }
            }
        },
        plotOptions: {
            bar: { 
                columnWidth: '10%', 
                endingShape: 'rounded' 
            }
        },
        tooltip: {
            y: { 
                title: { formatter: (seriesName) => `${seriesName}: ` },
                formatter: (value) => `${Math.floor(value)}`,
                background: '#f4f4f4',
                color: '#333'
            }
        },
        dataLabels: {
            enabled: true,
            offsetY: -10,
            style: {
                fontSize: '12px',
                colors: ["#ffffff"]
            },
            formatter: (value) => `${ value }`,
            background: { enabled: false }
        },
        stroke: { show: false },
        markers: {
            size: 6,
            strokeWidth: 1,
            radius: 2,
            shape: "circle"
        },
        title: {
            text: nameGrafficStarted,
            align: '',
            style: {
                fontFamily: 'bebas neue',
                fontSize: '26px',
                fontWeight: '500',
                color: '#6c757d',
                lineHeight: 'normal'
            }
        },
        grid: {
            row: {
                colors: ['#f3f3f3', 'transparent'],
                opacity: 0.5
            },
        },
        legend: {
            show: true,
            showForSingleSeries: true,
            position: 'bottom',
            horizontalAlign: 'center',
            fontSize: '11px',
            fontFamily: 'Poppins',
            labels: {
                colors: '#333'
            }
        },
        xaxis: {
            categories: ['Nome dos cursos iniciados'],
            title: { text: '' }
        },
        yaxis: {
            labels: {
                formatter: (value) => Math.floor(value),
            },
            title: {
                text: 'Número de curso iniciados',
                style: {
                    fontFamily: 'bebas neue',
                    fontSize: '12px',
                    fontWeight: 'bold',
                    color: '#6c757d'
                }
            },
        },
        series: studentStarted
    }); 
    function ShowStarted(value){
        const newData = [];
        GetDataPage('students').map((elem, index)=>{
            let coursesStarted = elem.courses_started.filter(item => item.year == currentYear && item.status == 0);
            if(coursesStarted.length > 0){
                coursesStarted.map((elem_1, index_1)=>{
                    let infCourse = GetDataPage('courses').find(item => item.id == elem_1.id_course);
                    if(newData.find(item => item.name == infCourse.title)){
                        let indexCourse = newData.findIndex(item => item.name == infCourse.title);
                        newData[indexCourse]['data'] = [newData[indexCourse]['data'].length + 1];
                    }else {
                        newData.push({
                            name: infCourse.title,
                            data: [1]
                        });
                    }
                });
            }
        });        
        return newData;
    }
    /* end */

    useEffect(()=>{
    }, []);

    useEffect(()=>{
        setListData([
            { "open": "", "title": "Acesso a dashboard", "qtd": InitialData('access_dash') },
            { "open": "", "title": "Cursos", "qtd": InitialData('courses') },
            { "open": "", "title": "Alunos", "qtd": InitialData('students') },
            { "open": "", "title": "Turmas", "qtd": InitialData('team') }
        ]);
        setFinancial(ShowFinancial());

        setCoursesWatched(ShowCoursesWatched(currentYear));
        setCoursesWithCompleted(ShowDataCourses(currentYear));
        setStudentsStarted(ShowStarted(currentYear));
    }, [currentYear]);

    return(
        <div className="Indicators">            
            {/* 
            <div className="new_block">
                <div className="title_page">Indicadores</div>
            </div>
            <div className="list_current_data">
                {
                    listData.map((key, index)=>{
                        return(
                            <div className="show_qtd" key={ index } onClick={ ()=>{ }}>
                                <div className="number">{ key.qtd }</div>
                                <div className="title">{ key.title }</div>
                            </div>
                        )
                    })
                }
            </div> 
            */}

            <div className="new_block new_block_grafic">
                <div className="" onClick={ ()=>{ setCurrentYear((currentYear - 1)); } }>
                    <SvgReturn color="#324d6b" className="icons" />
                </div>
                <div className="title_page">
                    { currentYear }
                </div>
                <div className="" onClick={ ()=>{ setCurrentYear((currentYear + 1)); } }>
                    <SvgReturn color="#324d6b" className="icons icon_next" />
                </div>
            </div>

            <div className="list_data_page" style={ { marginBottom: '30px' } }>
                <div className="list_current_data">                    
                    <div className="show_qtd" onClick={ ()=>{ }}>
                        <div className="number">{ ShowPrice('entry', currentYear) }</div>
                        <div className="title">Faturamento - Entrada em { currentYear }</div>
                    </div>
                    
                    <div className="show_qtd" onClick={ ()=>{ }}>
                        <div className="number">{ ShowPrice('exit', currentYear) }</div>
                        <div className="title">Faturamento - Saída em { currentYear }</div>
                    </div>
                </div>

                <div className="div_grafic">
                    <ReactApexChart options={ grafficFinancial } series={ financial } type="line" height={ 350 } />
                </div>
            </div>

            <div className="list_data_page">
                <div className="div_grafic">
                    <ReactApexChart options={ grafficCoursesWatched } series={ coursesWatched } type="bar" height={ 350 } />
                </div>
            </div>

            <div className="list_graffic">
                <div className="list_data_page">
                    <div className="div_grafic">
                        <ReactApexChart options={ grafficCourse } series={ coursesWithCompleted } type="bar" height={ 350 } />
                    </div>
                </div>

                <div className="list_data_page">
                    <div className="div_grafic">
                        <ReactApexChart options={ grafficStarted } series={ studentStarted } type="bar" height={ 350 } />
                    </div>
                </div>
            </div>

            {
                courseStated.length > 0 ?
                    <div className="div_show_table">
                        <div className="div_title">Alunos que iniciaram o curso mas não concluíram</div>
                        <div className="list_table">                    
                            <table width="100%" cellPadding="0" cellSpacing="0">
                                <thead>
                                    <tr>
                                        <th width="20" align="center">#</th>
                                        <th>Nome do aluno</th>
                                        <th width="50">Acesso</th>
                                        <th>Nome do curso</th>
                                        <th width="60">Iniciado</th>
                                    </tr>
                                </thead>
                                    {                            
                                        courseStated.map((elem, index)=>{
                                            return(
                                                <tbody key={ index }>
                                                    <tr>
                                                        <td rowSpan={ (elem.list.length + 1) }>{ index + 1 }</td>
                                                        <td rowSpan={ (elem.list.length + 1) }>{ elem.name }</td>
                                                        <td align="center" rowSpan={ (elem.list.length + 1) } style={ { borderRight: "1px solid #E2E8F0" } }>
                                                            { elem.access }
                                                        </td>
                                                    </tr>
                                                    {
                                                        elem.list.map((elem_1, index_1)=>{
                                                            let infCourse = GetDataPage('courses').find(item => item.id == elem_1.id_course);
                                                            return(
                                                                <tr key={ index_1 }>
                                                                    <td>{ infCourse.title }</td>
                                                                    <td>{ elem_1.date }</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            )
                                        })
                                    }
                            </table>
                        </div>
                    </div>
                : null
            }
        </div>
    )
}