import { useState, useEffect } from "react";

import './ModulesWorkload.css';

import JoditEditor from 'jodit-react';
import { config } from "dataFixed";

import { SvgDelete, SvgImg, SvgVimeo, SvgYoutube } from "components/SvgFile";

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";
import { GetListPag, RegisterListPag, UnRegisterListPag } from "interface/Page";

export default function ModelSite_ModulesWorkload(props){

    const [ idPage, setIdPage ]     = useState(GetListPag('currentPageId'));
    const [ dataPage, setDataPage ] = useState(GetDataPage(props.origin));

    const [ imgOrigin, setImgOrigin ] = useState(InitialData('img_origin'));
    const [ imgName, setImgName ]     = useState(InitialData('img_name'));

    const [ img, setImg ]             = useState(InitialData('img_example'));
    const [ status, setStatus ]       = useState(false);
    const [ bgPage, setBgPage ]       = useState(InitialData('bg_color'));
    const [ title_1, setTitle_1 ]     = useState(InitialData('title_1'));
    const [ text_1, setText_1 ]       = useState(InitialData('text_1'));
    const [ dataTable, setDataTable ] = useState(InitialData('contents'));

    function InitialData(type){
        if(idPage !=0){
            if(dataPage.length > 0){
                const newData = dataPage.find(item => item.id == idPage);
                return newData[type];
            }
        }
        if(type == 'contents'){
            return [];
        }
        return '';
    }

    function LoadingData(){
        props.ReturnData({ "img_origin": imgOrigin, "img_name": imgName, "img": img, "bg_page": bgPage, "title_1": title_1, "text_1": text_1, "dataTable": dataTable});
    }

    function HandleImgOrigin(value){
        setImgOrigin(value);
        props.ReturnData({ "img_origin": value, "img_name": imgName, "img": img, "bg_page": bgPage, "title_1": title_1, "text_1": text_1, "dataTable": dataTable});
    }

    function HandleImgName(value){
        setImgName(value);
        props.ReturnData({ "img_origin": imgOrigin, "img_name": value, "img": img, "bg_page": bgPage, "title_1": title_1, "text_1": text_1, "dataTable": dataTable});
    }

    function HandleBgPage(value){
        setBgPage(value)
        props.ReturnData({ "img_origin": imgOrigin, "img_name": imgName, "img": img, "bg_page": value, "title_1": title_1, "text_1": text_1, "dataTable": dataTable});
    }

    function HandleImg(value){
        setImg(value);
        value ? setStatus(true) : setStatus(false);
        props.ReturnData({ "img_origin": imgOrigin, "img_name": imgName, "img": value, "bg_page": bgPage, "title_1": title_1, "text_1": text_1, "dataTable": dataTable});
    }

    function HandleTitle(value){
        setTitle_1(value);
        props.ReturnData({ "img_origin": imgOrigin, "img_name": imgName, "img": img, "bg_page": bgPage, "title_1": value, "text_1": text_1, "dataTable": dataTable});
    }

    function HandleText(value){
        setText_1(value);
        props.ReturnData({ "img_origin": imgOrigin, "img_name": imgName, "img": img, "bg_page": bgPage, "title_1": title_1, "text_1": value, "dataTable": dataTable});
    }

    function handleTable(type, value, index){
        let arrayData = [...dataTable];
        arrayData[index][type] = value;
        setDataTable(arrayData);
        props.ReturnData({ "img_origin": imgOrigin, "img_name": imgName, "img": img, "bg_page": bgPage, "title_1": title_1, "text_1": text_1, "dataTable": arrayData});
    }

    function AddNewData(){
        setDataTable([...dataTable, { "id": 0, "id_given": idPage, "name": "", "text": "", "date": "" }]);
    }

    function OpenFile(type, file){
        SetModalData('ShowFile', { "type": type, "file": file });
        SetModalState('ShowFile', true);
    }

    function RemuveDataAdd(id, index, value){
        if(id != 0){
            SetModalData('Confirmation', { "origin": props.origin, "type" : "delete_data_contents", "id" : id, "name": value });
            SetModalState('Confirmation', true);
        }else {
            let arrayData = [...dataTable];
            arrayData.splice(index, 1);
            setDataTable(arrayData);
        }
    }

    useEffect(()=>{
        RegisterDataPage(props.origin, setDataPage);
        RegisterListPag('currentPageId', setIdPage);
        
        LoadingData();

        return ()=>{
            UnRegisterDataPage(props.origin, setDataPage);
            UnRegisterListPag('currentPageId', setIdPage);
        };
    }, []);

    useEffect(()=>{
        setDataPage(GetDataPage(props.origin));
        setIdPage(GetListPag('currentPageId'));
        
        setImgOrigin(InitialData('img_origin'));
        setImgName(InitialData('img_name'));
        
        setImg(InitialData('img_example'));
        setStatus(false);
        setBgPage(InitialData('bg_color'));
        setTitle_1(InitialData('title_1'));
        setText_1(InitialData('text_1'));
        setDataTable(InitialData('contents'));

        LoadingData();
    }, [dataPage, idPage]);

    return(
        <div className="ModelSite_ModulesWorkload">
            <div className="list_data_page">
                {
                    img == "" ? null : 
                    <div className="div_input space_div title_side">
                        <div className="div_input">
                            <input type="text" className="data_input" onChange={ (e)=>{ HandleImgOrigin(e.target.value) } } value={ imgOrigin } maxLength="80" />
                            <span className="name_input">Assinatura - Origem</span>
                        </div>
                        <div className="div_input">
                            <input type="text" className="data_input" onChange={ (e)=>{ HandleImgName(e.target.value) } } value={ imgName } maxLength="80" />
                            <span className="name_input">Assinatura - Nome</span>
                        </div>
                    </div>
                } 
                <div className="div_input space_div title_side">
                    <div className="div_show_img">
                        <div className={ status == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                            <label>
                                { status == true ? "Imagem adicionada" : "Adicionar imagem" }
                                <input type="file" onChange={ (e)=>{ HandleImg(e.target.files[0]); } } accept="image/*"/>
                            </label>
                        </div>
                        {
                            img !='' ?
                            <>
                                <div className="delete_img_add" onClick={ ()=>{ setImg(''); setStatus(false); } }>
                                    <SvgDelete color="#FFFFFF" className="icons" />
                                </div>
                                {
                                    status == true ?
                                    <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(img)) } }>
                                        <SvgImg color="#FFFFFF" className="icons"/>
                                    </div> :
                                    <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "modules_workload/" + img) } }>
                                        <SvgImg color="#FFFFFF" className="icons"/>
                                    </div>
                                }
                            </> : null
                        }  
                    </div>

                    <div className="div_input space_div">
                        <input type="text" className="data_input" onChange={ (e)=>{ HandleBgPage(e.target.value) } } value={ bgPage } maxLength="140" />
                        <span className="name_input">Cor de fundo</span>
                    </div>
                    <div className="div_input">
                        <div className="div_color" style={ { background: bgPage } } />
                    </div>
                </div>

                <div className="div_input space_div">
                    <input type="text" className="data_input" onChange={ (e)=>{ HandleTitle(e.target.value) } } value={ title_1 } maxLength="140" />
                    <span className="name_input">Título</span>
                </div>

                <div className="div_input space_div">
                    <JoditEditor className="show_textarea" config={ config } value={ text_1.replaceAll('&#34;', '"') } onBlur={ newContent => HandleText(newContent) } />
                    <span className="name_input">Texto</span>
                </div>
                
                <div className="div_input space_div">
                    <div className="type_title" style={ { marginBottom: '0px' } }>
                        <div className="">Tabela</div>
                        <div className="cursor_pointer" onClick={ ()=>{ AddNewData(); } }>
                            + Adicionar nova linha na tabela
                        </div>
                    </div>
                </div>
                <div className="div_input space_div">
                    <table width="100%" cellPadding="0" cellSpacing="0">
                        <tbody>
                            <tr>
                                <th width="10" align="center">#</th>
                                <th>Nome</th>
                                <th width="80">Cor</th>
                                <th width="40">Hora</th>
                                <th width="20" align="center">#</th>
                            </tr>
                        </tbody>
                        <tbody>
                            {
                                dataTable.map((key, index)=>{
                                    return(
                                        <tr key={ index }>
                                            <td className="td_" align="center">{ index + 1 }</td>
                                            <td className="td_">
                                                <input type="text" className="data_input input_new_bg" onChange={ (e)=>{ handleTable('name', e.target.value, index) } } value={ key.name } maxLength="80" />
                                            </td>
                                            <td className="td_">
                                                <div className="show_div_color">
                                                    <input type="text" className="input_color input_new_bg" onChange={ (e)=>{ handleTable('text', e.target.value, index) } } value={ key.text } maxLength="80" />
                                                    <div className="div_color" style={ { background: key.text } } />
                                                </div>
                                            </td>
                                            <td className="td_">
                                                <input type="text" className="input_hour input_new_bg" onChange={ (e)=>{ handleTable('date', e.target.value, index) } } value={ key.date } maxLength="80" />
                                            </td>
                                            <td className="td_">
                                                <div onClick={ ()=>{ RemuveDataAdd(key.id, index, key.name) } }>
                                                    <SvgDelete color="#F00000" className="icons" />
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
                
                <div className="div_input div_space space_top">
                    <div className="div_show_data div_space img">
                        {
                            img == "" || dataTable.length == 0 ? 
                            <img alt="home_example" src="./assets/bg_modules_workload.jpg" className="img_example" /> :
                            <div className="ModelPages_ModulesWorkload" style={ { background: bgPage } }>
                                <div className="show_data">
                                    <div className="bg_img">                                    
                                        {
                                            status == true ?
                                            <img alt="img" src={ URL.createObjectURL(img) } className="img" /> : 
                                            <img alt="img" src={ "./assets/modules_workload/" + img } className="img" />
                                        }
                                    </div>
                                    <div className="div_text">
                                        <div className="title">{ title_1 }</div>
                                        <div className="table">
                                            <table width="100%" cellPadding="2" cellSpacing="6">
                                                <tbody>
                                                    {
                                                        dataTable.map((key, index)=>{
                                                            return(
                                                                <tr key={ index }>
                                                                    <td className="table_numb" style={ { backgroundColor: key.text } }>{ index + 1 }</td>
                                                                    <td className="table_text" style={ { color: key.text } }>{ key.name }</td>
                                                                    <td className="table_time" style={ { backgroundColor: key.text } }>{ key.date }</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                        {
                                            text_1 == "" ? null : 
                                            <div className="time">
                                                <div className="" dangerouslySetInnerHTML={ { __html: text_1 ? text_1.replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}