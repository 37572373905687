import React, { useState, useEffect } from "react";

import './StatusTeam.css';

import { SvgClose, SvgDownload, SvgUpload, SvgVimeo, SvgYoutube } from "components/SvgFile";

import { GetDataPage, RegisterDataPage } from "interface/Data";
import { SetModalState, RegisterModalData, RegisterModalObserver, SetModalData } from "interface/PopUp";

import { Reg_FileUser } from "services/RegisterData";

export default function PopUP_StatusTeam(){

    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);
    const [ courses, setCourses ]     = useState(GetDataPage('courses'));

    const [ title, setTitle ]         = useState('');
    const [ typeVideo, setTypeVideo ] = useState('');
    const [ video, setVideo ]         = useState('');
    const [ listData, setListData ]   = useState([]);

    const [ listFile, setListFile ] = useState([]);

    const [ toast, setToast ] = useState(false);
    const [ textToast, setTextToast ] = useState('Informação salva com sucesso!');

    function ShowDataPage(type){
        const newData = courses.find(item => item.id == modalData.id_course);
        return newData[type];
    }

    function ClosePopUp(){
        SetModalState('StatusTeam', false);
    }

    function OpenFile(type, file){
        SetModalData('ShowFile', { "type": type, "file": file });
        SetModalState('ShowFile', true);
    }

    function HandleData(id_classe, idFile, value){   
        const newData = [...listFile];
        let indexFile = newData.findIndex(item => item.id_classe == id_classe);
        newData[indexFile]['status'] = value;
        setListFile(newData);

        Reg_FileUser(idFile, value, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }
    
    function CallbackSuccess(){
        setToast(true);
        setTextToast('Informação salva com sucesso!');
        setTimeout(() => {
            setToast(false);
            setTextToast('');
        }, 1500);
    }

    function CallbackError(){
        setToast(true);
        setTextToast('Erro! tente novamente');
        setTimeout(() => {
            setToast(false);
            setTextToast('');
        }, 1500);
    }

    useEffect(()=>{
        RegisterModalData('StatusTeam', setModaldata);
        RegisterModalObserver('StatusTeam', setShowPopUp);

        RegisterDataPage('courses', setCourses);
    }, []);

    useEffect(()=>{
        if(showPopUp == true){
            setTitle(ShowDataPage('subtitle'));
            setTypeVideo(ShowDataPage('type_video'));
            setVideo(ShowDataPage('video'));
            setListData(ShowDataPage('list_module'));

            setListFile(modalData.file_classe)
        }
    }, [showPopUp]);

    return (
        (showPopUp ?
            <>
                <div className="PopUp">
                    <div className="all status_team">
                        <div className="div_data">
                            <div className="title">
                                Arquivos adicionados dos cursos
                            </div>
                            <div className="close" onClick={ ()=>{ ClosePopUp() } }>
                                <SvgClose color="#F00000" className="icons" />
                            </div>
                        </div>
                        <div className="div_data" style={ { paddingTop: 0 } }>
                            <div className="content">
                                
                                <div className="course_data">
                                    <div className="name_course">Curso: { title }</div>
                                    <div className="" onClick={ ()=>{ OpenFile(typeVideo + "Code", video) } }>
                                        {
                                            typeVideo == 'Vimeo' ?
                                            <SvgVimeo color="#ffffff" className="icons"/> :
                                            <SvgYoutube color="#ffffff" className="icons"/>
                                        }
                                    </div>
                                </div>
                                {
                                    listData.map((elem, index)=>{
                                        if(elem.list_classe.find(item => item.type == 'upload')){
                                            return(
                                                <div className="border_module" key={ index }>
                                                    <div className="course_data">
                                                        <div className="name_course">Módulo: { elem.name  }</div>
                                                    </div>
                                                    {
                                                        elem.list_classe.map((elem_1, index_1)=>{
                                                            if(elem_1.type == 'upload'){
                                                                let showFile = [];
                                                                if(listFile.filter(item => item.id_classe == elem_1.id)){
                                                                    showFile = listFile.filter(item => item.id_classe == elem_1.id);
                                                                }

                                                                return(
                                                                    <div className="border_module list_classe" key={ index_1 }>
                                                                        <div className="name_course">Título: { elem_1.title }</div>
                                                                        {
                                                                            showFile.length > 0 ?
                                                                            <table width="100%" cellPadding="0" cellSpacing="0">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <td className="td_height" align="left">Nome</td>
                                                                                        <td className="td_height" align="left" width="80">Data</td>
                                                                                        <td className="td_height" align="left" width="80">Status</td>
                                                                                        <td className="td_height" align="center" width="20">#</td>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {
                                                                                        showFile.map((key, index_2)=>{
                                                                                            return(
                                                                                                <tr key={ index_2 }>
                                                                                                    <td className="td_height" align="left">
                                                                                                        { key.title }
                                                                                                        {
                                                                                                            toast ? 
                                                                                                            <div className="td_toast">
                                                                                                                { textToast }
                                                                                                            </div> : null
                                                                                                        }
                                                                                                    </td>
                                                                                                    <td className="td_height" align="left" width="80">{ key.date }</td>
                                                                                                    <td className="td_height" align="left" width="80">
                                                                                                        <select onChange={ (e)=>{ HandleData(elem_1.id, key.id, e.target.value) } } value={ key.status }>
                                                                                                            <option value="0">#</option>
                                                                                                            <option value="1">Ótimo</option>
                                                                                                            <option value="2">Regular</option>
                                                                                                            <option value="3">Ruim</option>
                                                                                                        </select>
                                                                                                    </td>
                                                                                                    <td className="td_height" align="center" width="20">
                                                                                                        <a href={ key.file } download={ key.file } target="_blank">
                                                                                                            <SvgDownload className="icons" color="#061821"/>
                                                                                                        </a>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </tbody>
                                                                            </table>

                                                                            : <div className="">Nenhum arquivo adicionado</div>
                                                                        }
                                                                    </div>
                                                                )
                                                            }
                                                        })
                                                    }
                                                </div>
                                            )
                                        }
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    <div className="close_all" onClick={ ()=>{ ClosePopUp(); } } />
                </div>
            </>
        : <React.Fragment></React.Fragment>)
    );
}
