import React, { useState, useEffect } from "react";

import './Confirmation.css';

import { Reg_DeleteData, Reg_Reactivate, Reg_UpdateJson } from "services/RegisterData";

import { GetUserData, RegisterUserData } from "interface/Users";
import { SetModalState, RegisterModalData, RegisterModalObserver, SetModalData } from "interface/PopUp";

export default function PopUP_Confirmation(props){

    const [ userId, setUserId ]       = useState(GetUserData('id'));
    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);

    function TypeAlt(){
        props.setLoading(true);
        switch(modalData.type){
            case "update_json":
                    Reg_UpdateJson(()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
                break;
            
            case "reactivate_data":
                    Reg_Reactivate(userId, modalData.type_register, modalData.origin, modalData.id, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
                break;

            default:
                    Reg_DeleteData(userId, modalData.origin, modalData.type, modalData.id, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
                break;

        }
    }

    function CallbackSuccess(){
        setTimeout(() => {
            props.setLoading(false);
            SetModalData('ReturnResponse', { "page": "ok" });
            SetModalState('ReturnResponse', true);
        }, 1000);
        SetModalState('Confirmation', false);
    }

    function CallbackError(){
        setTimeout(() => {
            props.setLoading(false);
            SetModalData('ReturnResponse', { "page": "erro" });
            SetModalState('ReturnResponse', true);
        }, 1000);
        SetModalState('Confirmation', false);
    }

    function ClosePopUp(){
        SetModalState('Confirmation', false);
    }

    function ShowTextRemuve(){
        if(modalData.type == "delete_options"){
            return(
                <div className="alert">
                    <div className="attention">*Atenção*</div> 
                    <div className="">
                        Ao clicar na opção "Sim" abaixo, será deletado junto com todas as informações referente a ele!
                    </div>
                </div>
            )
        }else {
            return(
                <div className="alert">
                    <div className="attention">*Atenção*</div> 
                    <div className="">
                        Ao clicar na opção "Sim" abaixo, o { modalData.type == "delete_course" ? "curso" : "aluno" } será deletado junto com todas as informações referente a ele!
                    </div>
                </div>
            )
        }
    }

    function TypeRemoval(){
        switch(modalData.type){
            case "update_json":
                return "Atualizar plataforma";

            case "alt_highlight":
                return (
                    <div>
                        {
                            modalData.highlight == 0 ? "Destacar o curso" : "Remover o destaque do curso"
                        }
                        <div>{ modalData.name }</div>
                    </div>
                );

            case "reactivate_data":
                return (
                    <div>
                        Reativar dados
                        <div>{ modalData.name }</div>
                    </div>
                );

            case "disabled_access_dash":
                return (
                    <div>
                        Desabilitar usuário:
                        <div>{ modalData.name }</div>
                    </div>
                );

            case "reactivate_access_dash":
                return (
                    <div>
                        Reabilitar usuário:
                        <div>{ modalData.name }</div>
                    </div>
                );

            case "show_courses":
                return (
                    <div>
                        Liberar curso:
                        <div>{ modalData.name }</div>
                    </div>
                );

            case "hide_courses": case "hide_students":
                return (
                    <div>
                        Desativar:
                        <div>{ modalData.name }</div>
                    </div>
                );

            case "delete_course":
                return (
                    <div>
                        Deletar curso:
                        <div>{ modalData.name }</div>
                    </div>
                );

            default:
                return (
                    <div>
                        Deletar:
                        <div>{ modalData.name }</div>
                    </div>
                );
        }
    }

    useEffect(()=>{
        RegisterModalData('Confirmation', setModaldata);
        RegisterModalObserver('Confirmation', setShowPopUp);

        RegisterUserData('id', setUserId);
    }, []);

    return (
        (showPopUp ?
            <>
                <div className="PopUp">
                    <div className="all confirmation">
                        <div className="div_data">
                            <div className="title title_confirmation">
                                { TypeRemoval() }
                            </div>
                        </div>
                        <div className="div_data" style={ { paddingTop: 0 } }>
                            {
                                modalData.type == "delete_options" ? 
                                modalData.used == 1 ? ShowTextRemuve() : "" :

                                modalData.type != "delete_course" && modalData.type != "delete_students" ? null :
                                ShowTextRemuve()
                            }
                            <div className="content div_confirmation">
                                <div onClick={ ()=>{ TypeAlt(); } }>
                                    <div className="opt_select yes_update">Sim</div>
                                </div>
                                <div onClick={ ()=>{ ClosePopUp(); } }>
                                    <div className="opt_select not_update">Não</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        : <React.Fragment></React.Fragment>)
    );
}
