import { cookiesRegister, typeCookiePage, typeCookiePageId, typeCookieSegundId } from 'dataFixed';

import Cookies from 'universal-cookie';

const cookies = new Cookies();

let ListPag = {
    "page"                : 'login',
    "currentPage"         : 'index',
    "currentPageId"       : 0,
    "currentPageIdSegund" : 0
};

let NotifyListPag = {
    "page"                : [],
    "currentPage"         : [],
    "currentPageId"       : [],
    "currentPageIdSegund" : []
}

export function SetListPag(key, value){
    ListPag[key] = value;
    NotifyListPag[key].forEach(element => {
        element(value);
    });

    if(key == 'currentPage'){
        cookies.set(typeCookiePage, value, '/', cookiesRegister);
    }

    if(key == 'currentPageId'){
        if(value == 'remuve'){
            cookies.remove(typeCookiePageId, '', '/', cookiesRegister);
            cookies.remove(typeCookieSegundId, '', '/', cookiesRegister);
        }else {
            cookies.set(typeCookiePageId, value, '/', cookiesRegister);
        }
    }

    if(key == 'currentPageIdSegund'){
        if(value == 'remuve'){
            cookies.remove(typeCookieSegundId, '', '/', cookiesRegister);
        }else {
            cookies.set(typeCookieSegundId, value, '/', cookiesRegister);
        }
    }
}

export function GetListPag(key){
    return ListPag[key];
}

export function RegisterListPag(key, value){
    if(!NotifyListPag[key]){
        NotifyListPag[key] = [];
    }
    NotifyListPag[key].push(value);
}

export function UnRegisterListPag(key, callback){
    if(NotifyListPag[key].length > 0){
        NotifyListPag[key] = NotifyListPag[key].filter((item) => {
            return item !== callback;
        });
    }
}
