import Axios from 'axios';

import Cookies from "universal-cookie";

import { cookiesRegister, typeCookieEmail, typeCookiePassw } from 'dataFixed';

import { SetListPag } from 'interface/Page';
import { SetUserData } from 'interface/Users';
import { SetListDataSingle } from 'interface/Data';

// Delete data
export function Reg_DeleteData(userId, currentPage, type, id, CallbackSuccess, CallbackError){
    const data = new FormData();

    data.append('id', id);
    data.append('edit_', type);
    data.append('userId', userId);
    data.append('current_page', currentPage);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        console.log(response.data);        
        SetListDataSingle('upload_site', response.data['upload_site']);

        switch (currentPage) {
            case "courses":
                    SetListDataSingle(currentPage, response.data[currentPage]);
                    SetListDataSingle('list_opt_select', response.data['list_opt_select']);
                break;

            case "students":
                    SetListDataSingle(currentPage, response.data[currentPage]);
                    SetListDataSingle('entry', response.data['entry']);
                    SetListDataSingle('team', response.data['team']);
                break;

            case "settings":
                    SetListDataSingle('list_opt_select', response.data['list_opt_select']);
                break;

            default:
                    SetListDataSingle(currentPage, response.data[currentPage]);
                break;
        }

        CallbackSuccess();

    }).catch((error)=>{        
        CallbackError();
    })
}
// Reactivate data
export function Reg_Reactivate(userId, type, origin, idData, CallbackSuccess, CallbackError){
    const data    = new FormData();

    data.append('userId', userId);
    data.append('edit_', type);
    data.append('current_page', origin);
    data.append('id', idData);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        SetListDataSingle('upload_site', response.data['upload_site']);
        SetListDataSingle(origin, response.data[origin]);
        CallbackSuccess();

    }).catch((error)=>{

        CallbackError();
    })
}


// Edit data user
export function Reg_UserDash(userId, idPage, name, pass, email, file, access, addPage, CallbackSuccess, CallbackError){
    const cookies = new Cookies();

    const data = new FormData();
    data.append('edit_', 'edit_profile');
    data.append('current_page', 'access_dash');

    data.append('userId', userId);
    data.append('idPage', idPage);
    data.append('name', name);
    data.append('pass', pass);
    data.append('email', email);
    data.append('file', file);
    data.append('access', access);
    addPage.forEach((element, index) => {
        data.append('page_value[]', element.value);
        data.append('page_name[]', element.label);
    });

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        SetListDataSingle('upload_site', response.data['upload_site']);
        SetListDataSingle('access_dash', response.data.access_dash);
        if(response.data.idPage !=0){
            SetListPag('currentPageId', response.data.idPage);
        }
        if(userId == idPage){
            const newData = response.data.access_dash.find(item => item.id == userId);
            cookies.set(typeCookieEmail, email, '/', cookiesRegister);
            if(pass !=''){
                cookies.set(typeCookiePassw, newData['pass'], '/', cookiesRegister);
            }
            SetUserData('id', newData['id']);
            SetUserData('file', newData['file']);
            SetUserData('access', newData['access']);
            SetUserData('page', newData['page']);
        }

        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}
// Edit user logado
export function Reg_EditUser(id, name, email, pass, file, CallbackSuccess, CallbackError){
    const cookies = new Cookies();

    const data = new FormData();

    data.append('edit_', 'edit_profile_logado');
    data.append('current_page', 'access_dash');

    data.append('id', id);
    data.append('name', name);
    data.append('email', email);
    data.append('pass', pass);
    data.append('file', file);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        SetListDataSingle('upload_site', response.data['upload_site']);
        SetListDataSingle('access_dash', response.data.access_dash);
        const newData = response.data.access_dash.find(item => item.id == id);
        cookies.set(typeCookieEmail, email, '/', cookiesRegister);
        if(pass !=''){
            cookies.set(typeCookiePassw, response.data.pass, '/', cookiesRegister);
        }
        
        SetUserData('file', newData['file']);
        SetUserData('name', newData['name']);
        SetUserData('email', newData['email']);

        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}


// Change position
export function Reg_AltPosition(current_page, id, order, type, CallbackSuccess, CallbackError){
    const data = new FormData();
    data.append('edit_', 'alt_position');
    data.append('current_page', current_page);

    data.append('id', id);
    data.append('order', order);
    data.append('type', type);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        console.log(response.data);
        SetListDataSingle('upload_site', response.data['upload_site']);
        if(current_page == 'modules' || current_page == 'courses' || current_page == 'courses_details'){
            SetListDataSingle('courses', response.data.courses);
            SetListDataSingle('list_opt_select', response.data.list_opt_select);

        }else if(current_page == 'nameMenu'){
            SetListDataSingle('nameMenu', response.data.nameMenu);

        }else if(current_page == 'home'){
            SetListDataSingle('home', response.data.home);

        }else {
            SetListDataSingle(current_page, response.data);
        }
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}


// Register data courses
export function Reg_EditOrRegisterCourses(idPage, typeVideo, video, file, img, highlight, title, subtitle, free, bronze, prata, gold, category, text, hotPage, CallbackSuccess, CallbackError){
    const data = new FormData();
    data.append('edit_', 'edit_or_register_courses');
    data.append('current_page', 'courses');

    data.append('id_page', idPage);
    data.append('type_video', typeVideo);
    data.append('video', video);
    data.append('file', file);
    data.append('img', img);
    data.append('highlight', highlight);
    data.append('title', title);
    data.append('subtitle', subtitle);
    data.append('free', free);
    data.append('bronze', bronze);
    data.append('prata', prata);
    data.append('gold', gold);

    category.forEach((element, index) => {
        data.append('category_id[]', element.id);
        data.append('category_id_category[]', element.id_category);
        data.append('category_name[]', element.name);
    });
    
    data.append('text', text);
    data.append('hot_page', hotPage);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        
        console.log(response.data);
        SetListDataSingle('upload_site', response.data['upload_site']);
        SetListDataSingle('courses', response.data.courses);
        SetListDataSingle('list_opt_select', response.data.list_opt_select);
        if(response.data.idPage !=0){
            SetListPag('currentPageId', response.data.idPage);
        }
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}
// Register data classes
export function Reg_EditOrRegisterClasses(idPage, idCourses, intro, type, typeVideo, video, free, moduleAlt, module, newModule, title, download, tips, link, CallbackSuccess, CallbackError){
    const data = new FormData();
    data.append('edit_', 'edit_or_register_classes');
    data.append('current_page', 'courses');

    data.append('id_page', idPage);
    data.append('id_courses', idCourses);
    data.append('introduction', intro);
    data.append('type', type);
    data.append('type_video', typeVideo);
    data.append('video', video);
    data.append('free', free);
    data.append('module', module);
    data.append('moduleAlt', moduleAlt);
    data.append('newModule', newModule);
    data.append('title', title);
    data.append('tips', tips);
    data.append('link', link);
    
    download.forEach((element, index) => {
        data.append('download_id[]', element.id);
        data.append('download_title[]', element.title);
        data.append('download_file_' + index, element.file);
    
        data.append('download_img_' + index, element.img);
        data.append("download_positionX[]", element.positionX);
        data.append("download_positionY[]", element.positionY);
        data.append("download_width[]", element.width);
        data.append("download_height[]", element.height);
        data.append("download_showWidth[]", element.showWidth);
        data.append("download_widthReal[]", element.widthReal);
    });

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        console.log(response.data);
        
        SetListDataSingle('upload_site', response.data['upload_site']);
        SetListDataSingle('courses', response.data.courses);
        SetListDataSingle('list_opt_select', response.data.list_opt_select);
        if(response.data.id_page !=0){
            SetListPag('currentPageIdSegund', response.data.idPage);
        }
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}


// Register students
export function Reg_Students(id_page, user_id, name, login, email, phone, file, country, address, cep, city, uf, cpf, note, pass, nivel, recording, listTeam, id_courses, typeAdd, classes, CallbackSuccess, CallbackError){
    const data = new FormData();

    data.append('edit_', 'edit_or_register_students');
    data.append('current_page', 'students');

    data.append('id_page', id_page);
    data.append('user_id', user_id);
    data.append('name', name);
    data.append('login', login);
    data.append('email', email);
    data.append('phone', phone);
    data.append('file', file);
    data.append('country', country);
    data.append('address', address);
    data.append('cep', cep);
    data.append('city', city);
    data.append('uf', uf);
    data.append('cpf', cpf);
    data.append('note', note);
    data.append('pass', pass);
    data.append('nivel', nivel);
    data.append('id_courses', id_courses);
    data.append('type', typeAdd);

    recording.forEach(element => {
        data.append('recording_id[]', element.id);
        data.append('recording_type[]', element.type);
        data.append('recording_title[]', element.title);
        data.append('recording_video[]', element.video);
    });

    listTeam.forEach(element => {
        if(element.id == 0){
            data.append('team[]', element.idNew);
        }
    });

    classes.forEach(element => {
        data.append('id_classes[]', element.value);
    });

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        SetListDataSingle('upload_site', response.data['upload_site']);
        SetListDataSingle('students', response.data.students);
        SetListDataSingle('entry', response.data.entry);
        SetListDataSingle('team', response.data.team);
        if(response.data.id_page !=0){
            SetListPag('currentPageId', response.data.id_page);
        }
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}


// Register team
export function Reg_Team(idPage, name, note, listStudy, studentsAdd, CallbackSuccess, CallbackError){
    const data = new FormData();

    data.append('edit_', 'edit_or_register_team');
    data.append('current_page', 'team');

    data.append('id_page', idPage);
    data.append('name', name);
    data.append('note', note);

    listStudy.forEach(element => {
        element.state_classes.forEach(element_1 => {
            data.append('list_students_id[]', element_1.id);
            data.append('list_students_status[]', element_1.status);
            data.append('list_students_id_login[]', element_1.id_login);
            data.append('list_students_id_courses[]', element_1.id_courses);
        });
    });

    studentsAdd.forEach(element => {
        data.append('id_students[]', element.value);
    });

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        SetListDataSingle('upload_site', response.data['upload_site']);
        SetListDataSingle('team', response.data.team);
        if(response.data.id_page !=0){
            SetListPag('currentPageId', response.data.id_page);
        }
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}
// Register data team - status
export function Reg_TeamStatusClasses(id_page, id_user, id_classe, statusClasse, CallbackSuccess, CallbackError){
    const data = new FormData();

    data.append('edit_', 'edit_status_classes');
    data.append('current_page', 'team');

    data.append('id_page', id_page);
    data.append('id_team_id', id_user);
    data.append('id_classe', id_classe);
    data.append('statusClasse', statusClasse);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        SetListDataSingle('upload_site', response.data['upload_site']);
        SetListDataSingle('team', response.data.team);
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}
// Edit File user
export function Reg_FileUser(idFile, value, CallbackSuccess, CallbackError){
    const data = new FormData();

    data.append('edit_', 'edit_status_file');
    data.append('current_page', 'team');

    data.append('id_file', idFile);
    data.append('status', value);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        console.log(response.data);
        SetListDataSingle('students', response.data.students);
        SetListDataSingle('team', response.data.team);

        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}


// Register financial entry
export function Reg_Entry(idPage, title, status, bank, newBank, agency, bill, dueDate, paymentData, cash, file, typeOrigin, origin, newOrigin, idLogin, typePayment, newPayment, codePag, fee, note, CallbackSuccess, CallbackError){
    const data = new FormData();

    data.append('edit_', 'edit_or_register_entry');
    data.append('current_page', 'entry');

    data.append('idPage', idPage);
    data.append('title', title);
    data.append('status', status);
    data.append('bank', bank);
    data.append('newBank', newBank);
    data.append('agency', agency);
    data.append('bill', bill);
    data.append('dueDate', dueDate);
    data.append('paymentData', paymentData);
    data.append('cash', cash);
    data.append('file', file);
    data.append('typeOrigin', typeOrigin);
    data.append('origin', origin);
    data.append('newOrigin', newOrigin);
    data.append('idLogin', idLogin);
    data.append('typePayment', typePayment);
    data.append('newPayment', newPayment);
    data.append('codePag', codePag);
    data.append('fee', fee);
    data.append('note', note);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        SetListDataSingle('upload_site', response.data['upload_site']);
        SetListDataSingle('entry', response.data.entry);
        if(response.data.id_page !=0){
            SetListPag('currentPageId', response.data.id_page);
        }
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}
// Register financial exit
export function Reg_Exit(idPage, title, status, bank, newBank, agency, bill, dueDate, paymentData, cash, file, typeOrigin, origin, newOrigin, idLogin, typePayment, newPayment, codePag, fee, note, CallbackSuccess, CallbackError){
    const data = new FormData();

    data.append('edit_', 'edit_or_register_exit');
    data.append('current_page', 'exit');

    data.append('idPage', idPage);
    data.append('title', title);
    data.append('status', status);
    data.append('bank', bank);
    data.append('newBank', newBank);
    data.append('agency', agency);
    data.append('bill', bill);
    data.append('dueDate', dueDate);
    data.append('paymentData', paymentData);
    data.append('cash', cash);
    data.append('file', file);
    data.append('typeOrigin', typeOrigin);
    data.append('origin', origin);
    data.append('newOrigin', newOrigin);
    data.append('idLogin', idLogin);
    data.append('typePayment', typePayment);
    data.append('newPayment', newPayment);
    data.append('codePag', codePag);
    data.append('fee', fee);
    data.append('note', note);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        SetListDataSingle('upload_site', response.data['upload_site']);
        SetListDataSingle('exit', response.data.exit);
        if(response.data.id_page !=0){
            SetListPag('currentPageId', response.data.id_page);
        }
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}


// Site - Edit data menu
export function Reg_EditMenu(listMenus, CallbackSuccess, CallbackError){
    const data = new FormData();
    data.append('current_page', 'nameMenu');
    data.append('edit_', 'edit_name_menu');

    listMenus.forEach((element, index) => {
        data.append('id[]', element.id);
        data.append('order_[]', element.order_);
        data.append('name[]', element.name);
    });

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        SetListDataSingle('upload_site', response.data['upload_site']);
        SetListDataSingle('nameMenu', response.data.nameMenu);
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    });
}


// Site - home, mentoria e rolo
export function Reg_DataSite(idPage, page, origin, type, contents, CallbackSuccess, CallbackError){
    const data = new FormData();
    data.append('edit_', 'edit_or_register');
    data.append('current_page', page);

    data.append('idPage', idPage);
    data.append('origin', origin);
    data.append('type', type);
    
    switch(type){
        case "Home":
                data.append('bg_img', contents.bg_img);
                data.append('bg_color', contents.bg_color);
                data.append('title_1', contents.title_1);
                data.append('title_2', contents.title_2);
                data.append('title_3', contents.title_3);
                data.append('title_4', contents.title_4);
                data.append('title_5', contents.title_5);
                data.append('title_6', contents.title_6);

                data.append('btn_1_type', contents.btn_1_type);
                data.append('btn_1_destiny', contents.btn_1_destiny);
                data.append('btn_1_name', contents.btn_1_name);

                data.append('btn_2_type', contents.btn_2_type);
                data.append('btn_2_destiny', contents.btn_2_destiny);
                data.append('btn_2_name', contents.btn_2_name);
            break;

        case "Apresentação com vídeo":
                data.append('bg_page', contents.bg_page);
                data.append('typeVideo', contents.typeVideo);
                data.append('video', contents.video);
                data.append('type_destiny_1', contents.typeDestiny_1);
                data.append('destiny_1', contents.destiny_1);
                data.append('button', contents.button);
                data.append('title_1', contents.title_1);
                data.append('title_2', contents.title_2);
                data.append('title_3', contents.title_3);
                data.append('text_1', contents.text_1);
                data.append('text_2', contents.text_2);
            break;

        case "Trabalhos de alunos":
                data.append('typeVideo', contents.typeVideo);
                data.append('video', contents.video);
                data.append('title', contents.title);
                data.append('bg_color', contents.bg_color);
                data.append('img', contents.img);
            break;

        case "Explicação dos conteúdos":
                data.append('bg_page', contents.bg_page);
                data.append('title_1', contents.title_1);
                data.append('title_2', contents.title_2);
                data.append('title_3', contents.title_3);
                data.append('text_1', contents.text_1);
                data.append('text_2', contents.text_2);
                data.append('text_3', contents.text_3);
                data.append('name_1', contents.name_1);
                data.append('name_2', contents.name_2);
                data.append('file', contents.file);
                data.append('img', contents.img);
            break;

        case "Módulos e carga horária":
                data.append('img', contents.img);
                data.append('bg_page', contents.bg_page);
                data.append('title_1', contents.title_1);
                data.append('text_1', contents.text_1);
                contents.dataTable.forEach(element => {
                    data.append('dataTable_id[]', element.id);
                    data.append('dataTable_id_given[]', element.id_given);
                    data.append('dataTable_title[]', element.name);
                    data.append('dataTable_hour[]', element.date);
                    data.append('dataTable_color[]', element.text);
                });
            break;

        case "Depoimentos":
                data.append('title_1', contents.title_1);
                data.append('bg_page', contents.bg_page);
                contents.dataProfile.forEach((element, index) => {
                    data.append('id_depositions[]', element.id);
                    data.append('img_' + index, element.file);
                    data.append('title[]', element.name);
                    data.append('text[]', element.text);
                    data.append('area[]', element.area);
                    data.append('data[]', element.date);
                });
            break;

        case "Atendimento personalizado":
                data.append('file', contents.file);
                data.append('bg_color', contents.bg_color);
                data.append('title_1', contents.title_1);
                data.append('title_2', contents.title_2);
                data.append('title_3', contents.title_3);
                data.append('title_4', contents.title_4);
                data.append('text_1', contents.text_1);
                data.append('text_2', contents.text_2);
                data.append('text_3', contents.text_3);
            break;

        case "Perfil do professor":
                data.append('bg_img', contents.bg_img);
                data.append('bg_color', contents.bg_color);
                data.append('file', contents.file);
                data.append('img_example', contents.img_example);
                data.append('line_up', contents.line_up);
                data.append('btn_1_name', contents.btn_1_name);
                data.append('btn_2_name', contents.btn_2_name);
                data.append('text_1', contents.text_1);

                contents.contents.forEach((element, index) => {
                    data.append('contents_id[]', element.id);
                    data.append('contents_img_' + index, element.file);
                    data.append('contents_id_given[]', element.id_given);
                    data.append('contents_text[]', element.text);
                });
            break;

    }

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        SetListDataSingle('upload_site', response.data['upload_site']);
        if(page == 'home'){
            SetListDataSingle('home', response.data.home);

        }else if(page == 'mentoring'){
            SetListDataSingle('mentoring', response.data.mentoring);

        }else if(page == 'rolo'){
            SetListDataSingle('rolo', response.data.rolo);
        }

        if(response.data.idPage !=0){
            SetListPag('currentPageId', response.data.idPage);
        }
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    });
}
// Site - New profile
export function Reg_NewProfile(idPage, page, file, name, area, date, text, CallbackSuccess, CallbackError){
    const data = new FormData();
    data.append('edit_', 'new_profile');
    data.append('current_page', page);

    data.append('idPage', idPage);
    data.append('file', file);
    data.append('name', name);
    data.append('text', text);
    data.append('area', area);
    data.append('date', date);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        SetListDataSingle('upload_site', response.data['upload_site']);
        if(page == 'home'){
            SetListDataSingle('home', response.data.home);
            
        }else if(page == 'mentoring'){
            SetListDataSingle('mentoring', response.data.mentoring);

        }else if(page == 'rolo'){
            SetListDataSingle('rolo', response.data.rolo);
        }
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}


// HowWorks
export function Reg_HowWorks(title_1, title_2, text, doubts, CallbackSuccess, CallbackError){
    const data = new FormData();
    data.append('edit_', 'edit_or_register');
    data.append('current_page', 'how_works');

    data.append("title_1", title_1);
    data.append("title_2", title_2);
    data.append("text", text);

    doubts.forEach(element_2=>{
        data.append("doubts_id[]", element_2.id);
        data.append("doubts_question[]", element_2.question);
        data.append("doubts_response[]", element_2.response);
    });

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        SetListDataSingle('upload_site', response.data['upload_site']);
        SetListDataSingle('how_works', response.data.how_works);
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}
// HowWorks - Doubts
export function Reg_NewData_Doubts(question, response, CallbackSuccess, CallbackError){
    const data = new FormData();
    data.append('edit_', 'register_doubts');
    data.append('current_page', 'howWorks');

    data.append('question', question);
    data.append('response', response);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        SetListDataSingle('upload_site', response.data['upload_site']);
        SetListDataSingle('howWorks', response.data.howWorks);
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}


// Payment
export function Reg_NewData_Payment(id_page, title, subtitle, showHome, showMentoring, showRolo, listPayment, CallbackSuccess, CallbackError){
    const data = new FormData();
    data.append('edit_', 'edit_payment');
    data.append('current_page', 'payment');

    data.append('id_page', id_page);
    data.append('title', title);
    data.append('subtitle', subtitle);
    data.append('showHome', showHome);
    data.append('showMentoring', showMentoring);
    data.append('showRolo', showRolo);

    listPayment.forEach((element, index) => {
        data.append('payment_index[]', index);
        data.append('payment_id[]', element.id);
        data.append('payment_type[]', element.type);
        data.append('payment_best_cost_benefit[]', element.best_cost);
        data.append('payment_data[]', element.data);
        data.append('payment_cash[]', element.cash);
        data.append('payment_title[]', element.title);
        data.append('payment_subtitle[]', element.subtitle);
        data.append('payment_color[]', element.color);
        data.append('payment_type_cash[]', element.type_cash);

        element.category.forEach((element_1, index_1) => {
            data.append('category_index[]', index);
            data.append('category_id[]', element_1.id);
            data.append('category_id_given[]', element_1.id_given);
            data.append('category_id_category[]', element_1.id_category);
            data.append('category_new_category[]', element_1.new_category);
            data.append('category_highlight[]', element_1.highlight);
            data.append('category_order_[]', element_1.order_);
        });
    });

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        SetListDataSingle('upload_site', response.data['upload_site']);
        SetListDataSingle('payment', response.data.payment);
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}
// payment - order
export function Reg_Payment_NewOrder(id, idCategory, order, type, CallbackSuccess, CallbackError){
    const data = new FormData();
    data.append('edit_', 'payment_alt_order');
    data.append('current_page', 'payment');

    data.append('id', id);
    data.append('idCategory', idCategory);
    data.append('order', order);
    data.append('type', type);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        SetListDataSingle('upload_site', response.data['upload_site']);
        SetListDataSingle('payment', response.data.payment);
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}


// Settings
export function Reg_Settings(purchase, signature, textCourse, address, instagram, youtube, facebook, twitter, privacy_terms, cookie_policy, whatsapp, listModule, listCategory, CallbackSuccess, CallbackError){
    const data = new FormData();
    data.append('current_page', 'settings');
    data.append('edit_', 'edit_or_register');

    data.append('purchase', purchase);
    data.append('signature', signature);
    data.append('textCourse', textCourse);
    data.append('address', address);
    data.append('instagram', instagram);
    data.append('youtube', youtube);
    data.append('facebook', facebook);
    data.append('twitter', twitter);
    data.append('privacy_terms', privacy_terms);
    data.append('cookie_policy', cookie_policy);
    data.append('whatsapp', whatsapp);

    if(listModule.length > 0){
        listModule.forEach((elem, index) => {
            data.append('data_id[]', elem.id);
            data.append('data_name[]', elem.name);
        });
    }

    if(listCategory.length > 0){
        listCategory.forEach((elem, index) => {
            data.append('data_id[]', elem.id);
            data.append('data_name[]', elem.name);
        });
    }

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        SetListDataSingle('upload_site', response.data['upload_site']);
        SetListDataSingle('settings', response.data.settings);
        SetListDataSingle('upload_site', response.data.upload_site);
        SetListDataSingle('list_opt_select', response.data.list_opt_select);
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}


// Update json
export function Reg_UpdateJson(CallbackSuccess, CallbackError){
    const data = new FormData();
    data.append('current_page', "update_json");

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        console.log(response.data);
        SetListDataSingle("upload_site", response.data.upload_site);
        CallbackSuccess();

    }).catch((error)=>{

        CallbackError();
    })
}


// Register questionnaire
export function Reg_Questionnaire(idPage, title, text, list, CallbackSuccess, CallbackError){
    const data = new FormData();

    data.append('edit_', 'edit_or_register');
    data.append('current_page', 'questionnaire');

    data.append('idPage', idPage);
    data.append('title', title);
    data.append('text', text);

    list.forEach((elem, index) => {
        data.append('list_id[]', elem.id);
        data.append('list_title[]', elem.title);
        data.append('list_subtitle[]', elem.subtitle);
    });

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        console.log(response.data);
        
        SetListDataSingle('upload_site', response.data['upload_site']);
        SetListDataSingle('questionnaire', response.data.questionnaire);
        if(response.data.idPage !=0){
            SetListPag('currentPageId', response.data.idPage);
        }
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}