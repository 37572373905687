import { useState, useEffect } from 'react';

import './Contents.css';

import Cookies from "universal-cookie";

import { GetDataPage } from 'interface/Data';
import { SetModalData, SetModalState } from 'interface/PopUp';
import { GetUserData, RegisterUserData } from 'interface/Users';
import { GetListPag, SetListPag, RegisterListPag } from 'interface/Page';

import { cookiesRegister, typeCookie, typeCookieEmail, typeCookiePassw, typeCookiePage } from 'dataFixed';

import { SvgDashboard, SvgDashboardExit, SvgLogotipo, SvgMenuCourse, SvgMenuFinance, SvgMenuGraphic, SvgMenuHamburguer, SvgMenuPoint, SvgMenuSite, SvgMenuStudents, SvgMenuTeam, SvgMenuUpdateJson, SvgSetaRight, SvgSettings, SvgSite, SvgUser } from 'components/SvgFile';

import Page_AccessDash from 'components/Page/AccessDash';
import Page_AccessDash_Details from 'components/Page/AccessDash/Details';

import Page_Courses from 'components/Page/Courses';
import Page_Courses_Details from 'components/Page/Courses/Details';
import Page_Courses_Details_Classes from 'components/Page/Courses/Details/Classes';
import Page_Course_Historic from 'components/Page/Courses/Details/Historic';

import Page_Students from 'components/Page/Students';
import Page_Students_Details from 'components/Page/Students/Details';
import Page_Students_Historic from 'components/Page/Students/Historic';

import Page_Team from 'components/Page/Team';
import Page_Team_Details from 'components/Page/Team/Details';

import Site_Home from 'components/Page/Site/Home';
import Site_Home_details from 'components/Page/Site/Home/Details';

import Site_Payment from 'components/Page/Site/Payment';

import Finacial_Exit from 'components/Page/Financial/Exit';
import Finacial_Exit_details from 'components/Page/Financial/Exit/Details';

import Finacial_Entry from 'components/Page/Financial/Entry';
import Finacial_Entry_details from 'components/Page/Financial/Entry/Details';

import Finacial_Balance from 'components/Page/Financial/Balance';

import Site_Rolo from 'components/Page/Site/Rolo';
import Site_Rolo_details from 'components/Page/Site/Rolo/Details';

import Site_Mentoring from 'components/Page/Site/Mentoring';
import Site_Mentoring_details from 'components/Page/Site/Mentoring/Details';

import Indicators from 'components/Page/Indicators';

import Site_HowWorks from 'components/Page/Site/HowWorks';

import Page_Settings from 'components/Page/Settings';

import PopUp_Questions from 'components/PopUp/Questions';
import PopUP_Historic from 'components/PopUp/Historic';
import PopUp_PageMenu from 'components/PopUp/PageMenu';
import PopUp_ShowFile from 'components/PopUp/ShowFile';
import PopUp_PhotoCrop from 'components/PopUp/PhotoCrop';
import PopUP_EditProfile from 'components/PopUp/EditProfile';
import PopUP_Confirmation from 'components/PopUp/Confirmation';
import PopUP_ReturnResponse from 'components/PopUp/ReturnResponse';
import Site_Questionnaire from 'components/Page/Site/Questionnaire';
import Site_Questionnaire_details from 'components/Page/Site/Questionnaire/Details';
import PopUp_QuestionsText from 'components/PopUp/QuestionsText';

export default function Contents(){

    const cookies                 = new Cookies();
    const [ status, setStatus ]   = useState(false);
    const [ loading, setLoading ] = useState(false);

    const [ currentPage, setCurrentPage ] = useState(GetListPag('currentPage'));

    const [ userFile, setUserFile ]     = useState(GetUserData('file'));
    const [ userName, setUserName ]     = useState(GetUserData('name'));
    const [ userAccess, setUserAccess ] = useState(GetUserData('access'));
    const [ userPage, setUserPage ]     = useState(GetUserData('page'));

    const [ menuSite, setMenuSite ]        = useState(false);
    const [ listMenuSite, setListSubMenu ] = useState([
        "home", "home_details", 
        "mentoring", "mentoring_details", 
        "rolo", "rolo_details", 
        "how_works", 
        "payment", 
        "questionnaire", "questionnaire_details", 
        "nameMenu"
    ]);

    const [ menuFinancial, setMenuFinancial ]        = useState(false);
    const [ listMenuFinancial, setListSubFinancial ] = useState([
        "entry", "entry_details", 
        "exit", "exit_details", 
        "balance"
    ]);

    function CkickPage(value){
        setTimeout(() => {
            SetListPag('currentPage', value);
        }, 100);
    }

    function EditProfile(){
        SetModalState('EditProfile', true);
    }

    function OpenDetailsNameMenu(){
        SetModalState('NameMenu', true);
    }
    
    function CurrentPage(){
        switch(currentPage) {
            case "settings":
                return <Page_Settings setLoading={ setLoading } />;

            case "access_dash":
                return <Page_AccessDash setLoading={ setLoading } />;
            case "access_dash_details":
                return <Page_AccessDash_Details setLoading={ setLoading } />;

            case "courses":
                return <Page_Courses setLoading={ setLoading } />;
            case "courses_details":
                return <Page_Courses_Details setLoading={ setLoading } />;
            case "courses_details_classes":
                return <Page_Courses_Details_Classes setLoading={ setLoading } />;
            case "courses_historic":
                return <Page_Course_Historic setLoading={ setLoading } />;

            case "students":
                return <Page_Students setLoading={ setLoading } />;
            case "students_details":
                return <Page_Students_Details setLoading={ setLoading } />;
            case "students_historic":
                return <Page_Students_Historic setLoading={ setLoading } />;

            case "team":
                return <Page_Team setLoading={ setLoading } />;
            case "team_details":
                return <Page_Team_Details setLoading={ setLoading } />;

            case "home":
                return <Site_Home setLoading={ setLoading } />;
            case "home_details":
                return <Site_Home_details setLoading={ setLoading } />;

            case "how_works":
                return <Site_HowWorks setLoading={ setLoading } />;
                
            case "payment":
                return <Site_Payment setLoading={ setLoading } />;

            case "questionnaire":
                return <Site_Questionnaire setLoading={ setLoading } />;
            case "questionnaire_details":
                return <Site_Questionnaire_details setLoading={ setLoading } />;

            case "entry":
                return <Finacial_Entry setLoading={ setLoading } />;
            case "entry_details":
                return <Finacial_Entry_details setLoading={ setLoading } />;

            case "exit":
                return <Finacial_Exit setLoading={ setLoading } />;
            case "exit_details":
                return <Finacial_Exit_details setLoading={ setLoading } />;
            case "balance":
                return <Finacial_Balance setLoading={ setLoading } />;

            case "mentoring":
                return <Site_Mentoring setLoading={ setLoading } />;
            case "mentoring_details":
                return <Site_Mentoring_details setLoading={ setLoading } />;

            case "rolo":
                return <Site_Rolo setLoading={ setLoading } />;
            case "rolo_details":
                return <Site_Rolo_details setLoading={ setLoading } />;

            case "indicators":
                return <Indicators setLoading={ setLoading } />;

            default:
                return(
                    <div className="no_data_content">
                        Selecione uma opção do menu na lateral para continuar...
                    </div>
                )
        }
    }

    function Logout(){
        SetListPag('page', 'index');
        cookies.set(typeCookie, 'index', '/', cookiesRegister);

        cookies.remove(typeCookieEmail, '', '/', cookiesRegister);
        cookies.remove(typeCookiePassw, '', '/', cookiesRegister);
        cookies.remove(typeCookiePage, '', '/', cookiesRegister);
    }

    function UpdateJson(){
        SetModalData('Confirmation', { "type": "update_json", "setLoading": setLoading })
        SetModalState('Confirmation', true);
    }

    useEffect(()=>{
        RegisterListPag('currentPage', setCurrentPage);
        
        RegisterUserData('file', setUserFile);
        RegisterUserData('name', setUserName);
        RegisterUserData('access', setUserAccess);
        RegisterUserData('page', setUserPage);
    }, []);

    return(
        <div className="Contents">
            <div className={ loading == true ? "return_data_save div_loading" : "return_data_save" }>
                <img alt="img" src="./assets/loading.gif" className="img_loading" />
            </div>

            <a href="https://previa.crazypixel.com.br/" target="_blank">
                <div className="show_site">
                    <SvgSite color="#ffffff" className="icons_site" />
                </div>
            </a>
            <div className={ status == true ? "list_menu active_menu" : "list_menu" }>
                <div className="show_opt_menu">
                    <div className="show_data_top">

                        <div className="div_logo" onClick={ ()=>{ setStatus(!status); setMenuFinancial(false); setMenuSite(false); } }>
                            <div className={ status == true ? "div_logo_opt div_logo_opt_" : "div_logo_opt" }>
                                <div className="">
                                    {
                                        status == true ?
                                        <SvgLogotipo widthLogo="logotipo" className="logotipo2" /> :
                                        <SvgLogotipo widthLogo="logotipo2" className="logotipo" />
                                    }
                                </div>
                                {
                                    status == true ? null :
                                    <div className="">
                                        <SvgMenuHamburguer color="#ffffff" className="icons" />
                                    </div>
                                }
                            </div>
                        </div>
                        
                        <div className="opt_menu_dash">
                            {
                                userAccess == 2 ?
                                <>
                                    <div className={ currentPage == "settings" ? "show_menu_dash active_dash" : status == true ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ CkickPage("settings"); setMenuFinancial(false); setMenuSite(false); } }>
                                        <div className={ status == true ? "name_menu name_menu_" : "name_menu" }>
                                            <div className="div_icons">
                                                <SvgSettings color="#FFFFFF" className="icons_dash" />
                                            </div>
                                            <div className={ status == true ? "menu_close" : "" }>Configuração</div>
                                        </div>
                                    </div>

                                    <div className={ currentPage == "access_dash" || currentPage == "access_dash_details" ? "show_menu_dash active_dash" : status == true ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ CkickPage("access_dash"); setMenuFinancial(false); setMenuSite(false); } }>
                                        <div className={ status == true ? "name_menu name_menu_" : "name_menu" }>
                                            <div className="div_icons">
                                                <SvgDashboard color="#FFFFFF" className="icons_dash" />
                                            </div>
                                            <div className={ status == true ? "menu_close" : "" }>Acessos a Dashboard</div>
                                        </div>
                                    </div>

                                    <div className={ currentPage == "courses" || currentPage == "courses_details" || currentPage == "courses_details_classes" || currentPage == "courses_historic" ? "show_menu_dash active_dash" : status == true ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ CkickPage("courses"); setMenuFinancial(false); setMenuSite(false); } }>
                                        <div className={ status == true ? "name_menu name_menu_" : "name_menu" }>
                                            <div className="div_icons">
                                                <SvgMenuCourse color="#FFFFFF" className="icons" />
                                            </div>
                                            <div className={ status == true ? "menu_close" : "" }>Cursos</div>
                                        </div>
                                    </div>

                                    <div className={ currentPage == "students" || currentPage == "students_details" || currentPage == "students_historic" ? "show_menu_dash active_dash" : status == true ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ CkickPage("students"); setMenuFinancial(false); setMenuSite(false); } }>
                                        <div className={ status == true ? "name_menu name_menu_" : "name_menu" }>
                                            <div className="div_icons">
                                                <SvgMenuStudents color="#FFFFFF" className="icons" />
                                            </div>
                                            <div className={ status == true ? "menu_close" : "" }>Alunos</div>
                                        </div>
                                    </div>

                                    <div className={ currentPage == "team" || currentPage == "team_details" ? "show_menu_dash active_dash" : status == true ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ CkickPage("team"); setMenuFinancial(false); setMenuSite(false); } }>
                                        <div className={ status == true ? "name_menu name_menu_" : "name_menu" }>
                                            <div className="div_icons">
                                                <SvgMenuTeam color="#FFFFFF" className="icons" />
                                            </div>
                                            <div className={ status == true ? "menu_close" : "" }>Turmas</div>
                                        </div>
                                    </div>

                                    <div className={ status == true ? listMenuSite.find(item => item == currentPage) ? "show_menu_dash active_dash" : "show_menu_dash show_menu_dash_" : "show_menu_dash" }>
                                        <div className={ status == true ? "name_menu name_menu_" : "name_menu" } onClick={ ()=>{ setMenuSite(!menuSite); setMenuFinancial(false); setStatus(false); } }>
                                            <div className="div_icons">
                                                <SvgMenuSite color="#FFFFFF" className="icons" />
                                            </div>
                                            <div className={ status == true ? "menu_close" : "" }>Site</div>
                                            <div className={ status == true ? "menu_close" : "icons_submenu" }>
                                                {
                                                    menuSite == true || listMenuSite.find(item => item == currentPage) ?
                                                    <SvgSetaRight color="#ffffff" className="icons icons_active" /> :
                                                    <SvgSetaRight color="#ffffff" className="icons" />
                                                }
                                            </div>
                                        </div>
                                        {
                                            status == true ? null :                                        
                                            <div className={ menuSite == true || listMenuSite.find(item => item == currentPage) ? "name_submenu submenu_active" : "name_submenu" }>
                                                <div className={ currentPage == "home" || currentPage == "home_details" ? "sub_menu show_menu_dash space_submenu active_dash" : "sub_menu show_menu_dash space_submenu" } onClick={ ()=>{ CkickPage("home") } }>
                                                    <div>Home</div>
                                                </div>
                                                <div className={ currentPage == "how_works" ? "sub_menu show_menu_dash space_submenu active_dash" : "sub_menu show_menu_dash space_submenu" } onClick={ ()=>{ CkickPage("how_works") } }>
                                                    <div>Como funciona</div>
                                                </div>
                                                <div className={ currentPage == "mentoring" || currentPage == "mentoring_details" ? "sub_menu show_menu_dash space_submenu active_dash" : "sub_menu show_menu_dash space_submenu" } onClick={ ()=>{ CkickPage("mentoring") } }>
                                                    <div>Mentoria</div>
                                                </div>
                                                <div className={ currentPage == "rolo" || currentPage == "rolo_details" ? "sub_menu show_menu_dash space_submenu active_dash" : "sub_menu show_menu_dash space_submenu" } onClick={ ()=>{ CkickPage("rolo") } }>
                                                    <div>Rolo</div>
                                                </div>
                                                <div className={ currentPage == "payment" ? "sub_menu show_menu_dash space_submenu active_dash" : "sub_menu show_menu_dash space_submenu" } onClick={ ()=>{ CkickPage("payment") } }>
                                                    <div>Pagamento</div>
                                                </div>
                                                <div className={ currentPage == "questionnaire" || currentPage == "questionnaire_details" ? "sub_menu show_menu_dash space_submenu active_dash" : "sub_menu show_menu_dash space_submenu" } onClick={ ()=>{ CkickPage("questionnaire") } }>
                                                    <div>Questionário</div>
                                                </div>
                                                <div className="sub_menu show_menu_dash space_submenu" onClick={ ()=>{ OpenDetailsNameMenu() } }>
                                                    <div>Nome do menu</div>
                                                </div>
                                            </div>
                                        }
                                    </div>

                                    <div className={ status == true ? listMenuFinancial.find(item => item == currentPage) ? "show_menu_dash active_dash" : "show_menu_dash show_menu_dash_" : "show_menu_dash" }>
                                        <div className={ status == true ? "name_menu name_menu_" : "name_menu" } onClick={ ()=>{ setMenuSite(false); setMenuFinancial(!menuFinancial); setStatus(false); } }>
                                            <div className="div_icons">
                                                <SvgMenuFinance color="#FFFFFF" className="icons" />
                                            </div>
                                            <div className={ status == true ? "menu_close" : "" }>Financeiro</div>
                                            <div className={ status == true ? "menu_close" : "icons_submenu" }>
                                                {
                                                    menuFinancial == true || listMenuFinancial.find(item => item == currentPage) ?
                                                    <SvgSetaRight color="#ffffff" className="icons icons_active" /> :
                                                    <SvgSetaRight color="#ffffff" className="icons" />
                                                }
                                            </div>
                                        </div>
                                        {
                                            status == true ? null :                                          
                                            <div className={ menuFinancial == true || listMenuFinancial.find(item => item == currentPage) ? "name_submenu submenu_active" : "name_submenu" }>
                                                <div className={ currentPage == "entry" || currentPage == "entry_details" ? "sub_menu show_menu_dash space_submenu active_dash" : "sub_menu show_menu_dash space_submenu" } onClick={ ()=>{ CkickPage("entry") } }>
                                                    <div>Entrada</div>
                                                </div>
                                                <div className={ currentPage == "exit" || currentPage == "exit_details" ? "sub_menu show_menu_dash space_submenu active_dash" : "sub_menu show_menu_dash space_submenu" } onClick={ ()=>{ CkickPage("exit") } }>
                                                    <div>Saída</div>
                                                </div>
                                                <div className={ currentPage == "balance" ? "sub_menu show_menu_dash space_submenu active_dash" : "sub_menu show_menu_dash space_submenu" } onClick={ ()=>{ CkickPage("balance") } }>
                                                    <div>Balanço</div>
                                                </div>
                                            </div>
                                        }
                                    </div>

                                    <div className={ currentPage == "indicators" ? "show_menu_dash active_dash" : status == true ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ CkickPage("indicators"); setMenuFinancial(false); setMenuSite(false); } }>
                                        <div className={ status == true ? "name_menu name_menu_" : "name_menu" }>
                                            <div className="div_icons">
                                                <SvgMenuGraphic color="#FFFFFF" className="icons" />
                                            </div>
                                            <div className={ status == true ? "menu_close" : "" }>Indicadores</div>
                                        </div>
                                    </div>

                                    <div className={ status == true ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ setMenuFinancial(false); setMenuSite(false); UpdateJson(); } }>
                                        <div className={ status == true ? "name_menu name_menu_" : "name_menu" }>
                                            <div className="div_icons">
                                                <SvgMenuUpdateJson color="#FFFFFF" className="icons" />
                                            </div>
                                            <div className={ status == true ? "menu_close inf_icon_attention" : "inf_icon_attention" }>
                                                Atualizar site
                                                {
                                                    GetDataPage('upload_site') ? <span className="attention" /> : ""
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </> 
                                :
                                <>
                                    {
                                        userPage.map((key, index)=>{
                                            let iconMenu   = '';
                                            let activeMenu = 'show_menu_dash';
                                            switch (key.page) {
                                                case 'courses':
                                                        iconMenu = <SvgMenuCourse color="#FFFFFF" className="icons" />;
                                                        if(currentPage == 'courses_details' || currentPage == "courses_details_classes"){
                                                            status == true ? activeMenu = "show_menu_dash active_dash show_menu_dash_": activeMenu = "show_menu_dash active_dash";
                                                        }
                                                    break;

                                                case 'students':
                                                        iconMenu = <SvgMenuStudents color="#FFFFFF" className="icons" />;
                                                        if(currentPage == 'students_details'){
                                                            status == true ? activeMenu = "show_menu_dash active_dash show_menu_dash_": activeMenu = "show_menu_dash active_dash";
                                                        }
                                                    break;

                                                case 'team':
                                                        iconMenu = <SvgMenuTeam color="#FFFFFF" className="icons" />;
                                                        if(currentPage == 'team_details'){
                                                            status == true ? activeMenu = "show_menu_dash active_dash show_menu_dash_": activeMenu = "show_menu_dash active_dash";
                                                        }
                                                    break;

                                                case 'site':
                                                        iconMenu = <SvgMenuSite color="#FFFFFF" className="icons" />;
                                                    break;

                                                case 'financial':
                                                        iconMenu = <SvgMenuFinance color="#FFFFFF" className="icons" />;
                                                    break;

                                                case 'indicators':
                                                        iconMenu = <SvgMenuGraphic color="#FFFFFF" className="icons" />;
                                                    break;
                                            }

                                            if(currentPage == key.page){
                                                status == true ? activeMenu = "show_menu_dash active_dash show_menu_dash_": activeMenu = "show_menu_dash active_dash";
                                            }

                                            switch (key.page) {
                                                case 'site':
                                                    if(key.submenu.length > 0){
                                                        return(
                                                            <div className={ status == true ? listMenuSite.find(item => item == currentPage) ? "show_menu_dash active_dash" : "show_menu_dash show_menu_dash_" : "show_menu_dash" } key={ index }>
                                                                <div className={ status == true ? "name_menu name_menu_" : "name_menu" } onClick={ ()=>{ setMenuSite(!menuSite); setMenuFinancial(false); setStatus(false); } }>
                                                                    <div className="div_icons">
                                                                        <SvgMenuSite color="#FFFFFF" className="icons" />
                                                                    </div>
                                                                    <div className={ status == true ? "menu_close" : "" }>Site</div>
                                                                    <div className={ status == true ? "menu_close" : "icons_submenu" }>
                                                                        {
                                                                            menuSite == true || listMenuSite.find(item => item == currentPage) ?
                                                                            <SvgSetaRight color="#ffffff" className="icons icons_active" /> :
                                                                            <SvgSetaRight color="#ffffff" className="icons" />
                                                                        }
                                                                    </div>
                                                                </div>
                                                                {
                                                                    status == true ? null :
                                                                    <div className={ menuSite == true || listMenuSite.find(item => item == currentPage) ? "name_submenu submenu_active" : "name_submenu" }>
                                                                        {                     
                                                                            key.submenu.map((key_1, index_1)=>{
                                                                                return(
                                                                                    <div className={ currentPage == key_1.name || currentPage == key_1.name + "_details" ? "sub_menu show_menu_dash space_submenu active_dash" : "sub_menu show_menu_dash space_submenu" } onClick={ ()=>{ CkickPage(key_1.name) } } key={ index_1 }>
                                                                                        <div>{ key_1.page }</div>
                                                                                    </div>
                                                                                )
                                                                            })  
                                                                        }
                                                                    </div>
                                                                }
                                                            </div>
                                                        )
                                                    }

                                                case 'financial':
                                                    if(key.submenu.length > 0){
                                                        return(
                                                            <div className={ status == true ? listMenuFinancial.find(item => item == currentPage) ? "show_menu_dash active_dash" : "show_menu_dash show_menu_dash_" : "show_menu_dash" } key={ index }>
                                                                <div className={ status == true ? "name_menu name_menu_" : "name_menu" } onClick={ ()=>{ setMenuSite(false); setMenuFinancial(!menuFinancial); setStatus(false); } }>
                                                                    <div className="div_icons">
                                                                        <SvgMenuFinance color="#FFFFFF" className="icons" />
                                                                    </div>
                                                                    <div className={ status == true ? "menu_close" : "" }>Financeiro</div>
                                                                    <div className={ status == true ? "menu_close" : "icons_submenu" }>
                                                                        {
                                                                            menuFinancial == true || listMenuFinancial.find(item => item == currentPage) ?
                                                                            <SvgSetaRight color="#ffffff" className="icons icons_active" /> :
                                                                            <SvgSetaRight color="#ffffff" className="icons" />
                                                                        }
                                                                    </div>
                                                                </div>
                                                                {
                                                                    status == true ? null :
                                                                    <div className={ menuFinancial == true || listMenuFinancial.find(item => item == currentPage) ? "name_submenu submenu_active" : "name_submenu" }>
                                                                        {                     
                                                                            key.submenu.map((key_1, index_1)=>{
                                                                                return(
                                                                                    <div className={ currentPage == key_1.name || currentPage == key_1.name + "_details" ? "sub_menu show_menu_dash space_submenu active_dash" : "sub_menu show_menu_dash space_submenu" } onClick={ ()=>{ CkickPage(key_1.name) } } key={ index_1 }>
                                                                                        <div>{ key_1.page }</div>
                                                                                    </div>
                                                                                )
                                                                            })  
                                                                        }
                                                                    </div>
                                                                }
                                                            </div>
                                                        )
                                                    }

                                                default:
                                                    return(
                                                        <div className={ activeMenu } onClick={ ()=>{ CkickPage(key.page); setMenuSite(false); setMenuFinancial(false); } } key={ index }>
                                                            <div className={ status == true ? "name_menu name_menu_" : "name_menu" }>
                                                                <div className="div_icons">
                                                                    { iconMenu }
                                                                </div>
                                                                <div className={ status == true ? "menu_close" : "" }>
                                                                    { key.name }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )

                                            }
                                            
                                        })
                                    }
                                </>
                            }

                            <div className={ status == true ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ Logout() } }>
                                <div className={ status == true ? "name_menu name_menu_" : "name_menu" }>
                                    <div className="div_icons">
                                        <SvgDashboardExit color="#FFFFFF" className="icons_dash" />
                                    </div>
                                    <div className={ status == true ? "menu_close" : "" }>Sair</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="div_profile">
                        <div className={ status == true ? "div_profile_data div_profile_data_" : "div_profile_data" } onClick={ ()=>{ EditProfile() } }>
                            <div className="div_img">
                                {
                                    userFile !='' ?
                                    <img alt="profile user" src={ "./assets/login/" + userFile } className="show_img_profile" /> :
                                    <SvgUser color="#FFFFFF" className="show_img_profile" />
                                }
                            </div>
                            <div className={ status == true ? "name_user menu_close" : "name_user" }>
                                { userName }
                            </div>
                            <div className={ status == true ? "open_profile menu_close" : "open_profile" }>
                                <SvgMenuPoint color="#ffffff" className="icons" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="list_data">
                <div className="show_data">
                    { CurrentPage() }
                </div>
            </div>
            <PopUP_Historic />
            <PopUP_EditProfile />
            <PopUP_Confirmation setLoading={ setLoading } />
            <PopUP_ReturnResponse />
            <PopUp_ShowFile />
            <PopUp_PageMenu />
            <PopUp_PhotoCrop />
            <PopUp_Questions />
            <PopUp_QuestionsText />
        </div>
    )
}
