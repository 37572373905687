import { useState, useEffect } from "react";

import './Depositions.css';

import JoditEditor from 'jodit-react';
import { config } from "dataFixed";

import { SvgDelete, SvgImg, SvgPdf, SvgVimeo, SvgYoutube } from "components/SvgFile";

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";
import { GetListPag, RegisterListPag, UnRegisterListPag } from "interface/Page";
import PopUp_DataProfile from "components/PopUp/DataProfile";

export default function ModelSite_Depositions(props){

    const [ idPage, setIdPage ]     = useState(GetListPag('currentPageId'));
    const [ dataPage, setDataPage ] = useState(GetDataPage(props.origin));

    const [ title_1, setTitle_1 ]         = useState(InitialData('title_1'));
    const [ bgPage, setBgPage ]           = useState(InitialData('bg_color'));
    const [ dataProfile, setDataProfile ] = useState(InitialData('contents'));

    function InitialData(type){
        if(idPage !=0){
            if(dataPage.length > 0){
                const newData = dataPage.find(item => item.id == idPage);
                return newData[type];
            }
        }
        if(type == 'contents'){
            return [];
        }
        return '';
    }

    function LoadingData(){
        props.ReturnData({"title_1": title_1, "bg_page": bgPage, "dataProfile": dataProfile});
    }

    function AddNewData(){
        SetModalData('DataProfile', { "id": idPage, "page": props.origin });
        SetModalState('DataProfile', true);
    }

    function OpenFile(type, file){
        SetModalData('ShowFile', { "type": type, "file": file });
        SetModalState('ShowFile', true);
    }

    function handleProfile(type, value, index){
        let arrayData = [...dataProfile];
        if(type == 'file'){
            if(value){
                arrayData[index]['upload'] = true;
            }else {
                arrayData[index]['upload'] = false;
            }
        }
        arrayData[index][type] = value;
        setDataProfile(arrayData);
        props.ReturnData({"title_1": title_1, "bg_page": bgPage, "dataProfile": arrayData});
    }

    function RemuveDataAdd(id, value){
        SetModalData('Confirmation', { "origin": props.origin, "type" : "delete_data_contents", "id" : id, "name": value });
        SetModalState('Confirmation', true);
    }

    function HandleTitle(value){
        setTitle_1(value);
        props.ReturnData({"title_1": value, "bg_page": bgPage, "dataProfile": dataProfile});
    }

    function HandleBgPage(value){
        setBgPage(value);
        props.ReturnData({"title_1": title_1, "bg_page": value, "dataProfile": dataProfile});
    }

    useEffect(()=>{
        RegisterDataPage(props.origin, setDataPage);
        RegisterListPag('currentPageId', setIdPage);
        
        LoadingData();

        return ()=>{
            UnRegisterDataPage(props.origin, setDataPage);
            UnRegisterListPag('currentPageId', setIdPage);
        };
    }, []);

    useEffect(()=>{
        setDataPage(GetDataPage(props.origin));
        setIdPage(GetListPag('currentPageId'));
        
        setTitle_1(InitialData('title_1'));
        setBgPage(InitialData('bg_color'));
        setDataProfile(InitialData('contents'));

        LoadingData();
    }, [dataPage, idPage]);

    return(
        <div className="ModelSite_Depositions">
            <div className="list_data_page">

                <div className="div_input space_div title_side">
                    <div className="div_input space_div">
                        <input type="text" className="data_input" onChange={ (e)=>{ HandleBgPage(e.target.value) } } value={ bgPage } maxLength="140" />
                        <span className="name_input">Cor de fundo</span>
                    </div>
                    <div className="div_input">
                        <div className="div_color" style={ { background: bgPage } } />
                    </div>
                </div>

                <div className="div_input space_div">
                    <input type="text" className="data_input" onChange={ (e)=>{ HandleTitle(e.target.value) } } value={ title_1 } maxLength="80" />
                    <span className="name_input">Título</span>
                </div>
                
                {
                    idPage == 0 ? null : 
                    <>                    
                        <div className="div_input space_div">
                            <div className="type_title" style={ { marginBottom: '0px' } }>
                                <div className="">Perfil</div>
                                <div className="cursor_pointer" onClick={ ()=>{ AddNewData(); } }>
                                    + Adicionar novo perfil
                                </div>
                            </div>
                        </div>
                        {
                             dataProfile.map((key, index)=>{
                                return(
                                    <div className="list_data_page list_profiles" key={ index }>
                                        <div className="div_input space_div title_side">
                                            <div className="div_show_img">
                                                <div className={ key.upload == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                                    <label>
                                                        { key.upload == true ? "Imagem adicionada" : "Adicionar imagem" }
                                                        <input type="file" onChange={ (e)=>{ handleProfile('file', e.target.files[0], index); } } accept="image/*"/>
                                                    </label>
                                                </div>
                                                {
                                                    key.file !='' ?
                                                    <>
                                                        <div className="delete_img_add" onClick={ ()=>{ handleProfile('img', '', index); } }>
                                                            <SvgDelete color="#FFFFFF" className="icons" />
                                                        </div>
                                                        {
                                                            key.upload == true ?
                                                            <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(key.file)) } }>
                                                                <img alt={ "img_" + index } src={ URL.createObjectURL(key.file) } className="icons_profile"/>
                                                            </div> :
                                                            <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "depositions/" + key.file) } }>
                                                                <img alt={ "img_" + index } src={ "./assets/depositions/" + key.file } className="icons_profile"/>
                                                            </div>
                                                        }
                                                    </> : null
                                                }  
                                            </div>
                                        </div>
                                        <div className="div_input space_div title_side">
                                            <div className="div_input">
                                                <input type="text" className="input_name" onChange={ (e)=>{ handleProfile('name', e.target.value, index); } } value={ key.name } maxLength="80" />
                                                <span className="name_input">Nome</span>
                                            </div>
                                            <div className="div_input space_div">
                                                <input type="text" className="data_input" onChange={ (e)=>{ handleProfile('area', e.target.value, index) } } value={ key.area } maxLength="80" />
                                                <span className="name_input">Área</span>
                                            </div>
                                            <div className="div_input">
                                                <input type="text" className="data_" onChange={ (e)=>{ handleProfile('date', e.target.value, index) } } value={ key.date } maxLength="80" />
                                                <span className="name_input">Data</span>
                                            </div>
                                            <div className="div_input" style={ { top: "6px" } } onClick={ ()=>{ RemuveDataAdd(key.id, key.name) } }>
                                                <SvgDelete color="#ffffff" className="icons" />
                                            </div>
                                        </div>
                                        <div className="div_input space_div">
                                            <JoditEditor className="show_textarea" config={ config } value={ key.text.replaceAll('&#34;', '"') } onBlur={ newContent => handleProfile('text', newContent, index) } />
                                            <span className="name_input">Descrição</span>
                                        </div>
                                    </div>
                                )
                             })
                        }
                    </>
                }
            </div>
            <PopUp_DataProfile />
        </div>
    );

}