// Connection type
// const pageServidor = 'http://localhost/React_JS/3_crazy_pixel/dashboard_v4/public';
const pageServidor = '.';
export const connectionPage = pageServidor;

// Cookies type
// const cookiesServidor = 'localhost';
const cookiesServidor = 'dashboard.crazypixel.com.br';
export const cookiesRegister  = cookiesServidor;

// Cookies name
export const typeCookie         = 'dashCrazy_dash';
export const typeCookiePassw    = 'dashCrazy_passw';
export const typeCookieEmail    = 'dashCrazy_email';
export const typeCookiePage     = 'dashCrazy_page';
export const typeCookiePageId   = 'dashCrazy_id';
export const typeCookieSegundId = 'dashCrazy_id_segund';

// List month
export const monthList = [
    {
        "name": "Janeiro",
        "month": "01"
    },
    {
        "name": "Fevereiro",
        "month": "02"
    },
    {
        "name": "Março",
        "month": "03"
    },
    {
        "name": "Abril",
        "month": "04"
    },
    {
        "name": "Maio",
        "month": "05"
    },
    {
        "name": "Junho",
        "month": "06"
    },
    {
        "name": "Julho",
        "month": "07"
    },
    {
        "name": "Agosto",
        "month": "08"
    },
    {
        "name": "Setembro",
        "month": "09"
    },
    {
        "name": "Outubro",
        "month": "10"
    },
    {
        "name": "Novembro",
        "month": "11"
    },
    {
        "name": "Dezembro",
        "month": "12"
    }
]

// Opt box editor
export const config = {
    zIndex: 0,
    readonly: false,
    width: 'auto',
    height: 'auto',
    minHeight: 100,
    direction: '',
    language: 'auto',
    tabIndex: -1,
    buttons: ['bold', 'italic', 'underline', 'image', 'link', 'align', 'source'],
    link: {
        modeClassName: false,
        noFollowCheckbox: false,
        openInNewTabCheckbox: false,
        processPastedLink: false,
        processVideoLink: false,
        selectMultipleClassName: false,
        selectOptionsClassName: false,
    },
    imageDefaultWidth: 24,
    image: {
        dialogWidth: true,
        editAlign: false,
        editAlt: false,
        editBorderRadius: true,
        editClass: false,
        editId: false,
        editLink: true,
        editMargins: false,
        editSize: true,
        editSrc: true,
        editStyle: false,
        editTitle: false,
        openOnDblClick: true,
        selectImageAfterClose: true,
        showPreview: true,
        useImageEditor: true,
    },
    placeholder: "Digite o texto aqui...",
    toolbarAdaptive: false
}

export const listTypePages = [
    { "value": "Home", "label": "Home" },
    { "value": "Apresentação com vídeo", "label": "Apresentação com vídeo" },
    { "value": "Trabalhos de alunos", "label": "Trabalhos de alunos" },
    { "value": "Explicação dos conteúdos", "label": "Explicação dos conteúdos" },
    { "value": "Módulos e carga horária", "label": "Módulos e carga horária" },
    { "value": "Depoimentos", "label": "Depoimentos" },
    { "value": "Atendimento personalizado", "label": "Atendimento personalizado" },
    { "value": "Perfil do professor", "label": "Perfil do professor" }
];
