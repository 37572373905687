import { useState, useEffect } from "react";

import './Settings.css';

import { config } from "dataFixed";
import JoditEditor from 'jodit-react';

import { SvgDelete, SvgLink, SvgPdf, SvgSave } from "components/SvgFile";

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";

import { Reg_Settings } from "services/RegisterData";

export default function Page_Settings(props){  
    
    const [ dataPage, setDataPage ]   = useState(GetDataPage('settings'));
    const [ optSelect, setOptSelect ] = useState(GetDataPage('list_opt_select'));

    const [ purchase, setPurchase ]     = useState(dataPage.purchase);
    const [ signature, setSignature ]   = useState(dataPage.text);
    const [ textCourse, setTextCourse ] = useState(dataPage.text_course);

    const [ address, setAddress ]     = useState(dataPage.address);
    const [ whatsapp, setWhatsapp ]   = useState(dataPage.whatsapp);
    const [ instagram, setInstagram ] = useState(dataPage.instagram);
    const [ youtube, setYoutube ]     = useState(dataPage.youtube);
    const [ facebook, setFacebook ]   = useState(dataPage.facebook);
    const [ twitter, setTwitter ]     = useState(dataPage.twitter);

    const [ privacyTerms, setPrivacyTerms ]   = useState(dataPage.privacy_terms);
    const [ statusPrivacy, setStatusPrivacy ] = useState(false);

    const [ cookiePolicy, setCookiePolicy ] = useState(dataPage.cookie_policy);
    const [ statusPolicy, setStatusPolicy ] = useState(false);

    const [ module, setModule ]     = useState(ListOptions('module'));
    const [ category, setCategory ] = useState(ListOptions('category'));

    function SaveData(event){
        props.setLoading(true);
        event.preventDefault();
        Reg_Settings(purchase, signature, textCourse, address, instagram, youtube, facebook, twitter, privacyTerms, cookiePolicy, whatsapp, module, category, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    function AddFile(type, value){
        if(type == 'privacy_terms'){
            if(value){
                setPrivacyTerms(value);
                setStatusPrivacy(true);
            }else {
                setPrivacyTerms('');
                setStatusPrivacy(false);
            }

        }else if(type == 'cookie_policy'){
            if(value){
                setCookiePolicy(value);
                setStatusPolicy(true);
            }else {
                setCookiePolicy('');
                setStatusPolicy(false);
            }
        }
    }

    function OpenFile(type, file){
        SetModalData('ShowFile', { "type": type, "file": file });
        SetModalState('ShowFile', true);
    }

    function ListOptions(type){
        const options = [];
        optSelect.map((elem, index)=>{
            if(elem.type == type){
                if(options.find(item => item.id == elem.id)){ }else {
                    options.push(elem);  
                }
            }          
        })
        return options;
    }

    function HandleData(type, index, value){
        if(type == 'category'){
            const newData = [...category];
            newData[index].name = value;
            setCategory(newData);
        }

        if(type == 'module'){
            const newData = [...module];
            newData[index].name = value;
            setModule(newData);
        }
    }

    function DeleteCategory(id, value, used){
        SetModalData('Confirmation', { "origin": "settings", "type" : "delete_options", "id" : id, "name": value, "setLoading": props.setLoading, "used": used });
        SetModalState('Confirmation', true);
    }

    useEffect(()=>{
        RegisterDataPage('settings', setDataPage);
        RegisterDataPage('list_opt_select', setOptSelect);

        return ()=>{
            UnRegisterDataPage('settings', setDataPage);
            UnRegisterDataPage('list_opt_select', setOptSelect);
        };
    }, []);

    useEffect(()=>{
        setDataPage(GetDataPage('settings'));
        setOptSelect(GetDataPage('list_opt_select'));

        setPurchase(dataPage.purchase);
        setSignature(dataPage.text);
        setTextCourse(dataPage.text_course);

        setAddress(dataPage.address);
        setWhatsapp(dataPage.whatsapp);
        setInstagram(dataPage.instagram);
        setYoutube(dataPage.youtube);
        setFacebook(dataPage.facebook);
        setTwitter(dataPage.twitter);
        
        setPrivacyTerms(dataPage.privacy_terms);
        setStatusPrivacy(false);
        setCookiePolicy(dataPage.cookie_policy);
        setStatusPolicy(false);

        setModule(ListOptions('module'));
        setCategory(ListOptions('category'));
    }, [dataPage]);

    useEffect(()=>{
        setOptSelect(GetDataPage('list_opt_select'));

        setModule(ListOptions('module'));
        setCategory(ListOptions('category'));
    }, [optSelect]);

    return(
        <div className="Page_Settings">
            <form onSubmit={ SaveData }>
                <div className="new_block">
                    <div className="title_page">Configurações</div>
                    <div className="list_opt_alt_page">
                        <button className="div_add_new_data">
                            <div className="new_data_add">
                                <SvgSave color="#ffffff" className="icons" />
                            </div>
                            <div className="name_btn_add">Salvar</div>
                        </button>
                    </div>
                </div>

                <div className="show_page_data">
                    <div className="type_title">Texto compra</div>
                    <div className="list_input_data">
                        <div className="space_div">
                            <JoditEditor className="show_textarea" config={ config } value={ purchase != '' ? purchase.replaceAll('&#34;', '"') : "" } onBlur={ newContent => setPurchase(newContent) } />
                        </div>
                    </div>
                </div>

                <div className="show_page_data">
                    <div className="type_title">Texto assinatura</div>
                    <div className="list_input_data">
                        <div className="space_div">
                            <JoditEditor className="show_textarea" config={ config } value={ signature != '' ? signature.replaceAll('&#34;', '"') : "" } onBlur={ newContent => setSignature(newContent) } />
                        </div>
                    </div>
                </div>

                <div className="show_page_data">
                    <div className="type_title">Texto do PopUp do curso</div>
                    <div className="list_input_data">
                        <div className="space_div">
                            <JoditEditor className="show_textarea" config={ config } value={ textCourse != '' ? textCourse.replaceAll('&#34;', '"') : "" } onBlur={ newContent => setTextCourse(newContent) } />
                        </div>
                    </div>
                </div>

                <div className="show_page_data">
                    <div className="type_title">Dados gerais</div>
                    <div className="list_input_data">
                        <div className="div_show_img">
                            <div className={ statusPrivacy == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                <label>
                                    { statusPrivacy == true ? "Termo de privacidade adicionado" : "Termo de privacidade" }
                                    <input type="file" onChange={ (e)=>{ AddFile('privacy_terms', e.target.files[0]); } } accept="application/pdf"/>
                                </label>
                            </div>
                            {
                                privacyTerms !='' ?
                                <>
                                    <div className="delete_img_add" onClick={ ()=>{ AddFile('privacy_terms', ''); } }>
                                        <SvgDelete color="#FFFFFF" className="icons" />
                                    </div>
                                    {
                                        statusPrivacy == true ?
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showPdf_local', URL.createObjectURL(privacyTerms)) } }>
                                            <SvgPdf className="icons" color="#ffffff" />
                                        </div> :
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showPdf', "settings/" + privacyTerms) } }>
                                            <SvgPdf className="icons" color="#ffffff" />
                                        </div>
                                    }
                                </> : null
                            }  
                        </div> 
                        <div className="div_show_img">
                            <div className={ statusPolicy == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                <label>
                                    { statusPolicy == true ? "Política dos cookies adicionado" : "Política dos cookies" }
                                    <input type="file" onChange={ (e)=>{ AddFile('cookie_policy', e.target.files[0]); } } accept="application/pdf"/>
                                </label>
                            </div>
                            {
                                cookiePolicy !='' ?
                                <>
                                    <div className="delete_img_add" onClick={ ()=>{ AddFile('cookie_policy', ''); } }>
                                        <SvgDelete color="#FFFFFF" className="icons" />
                                    </div>
                                    {
                                        statusPolicy == true ?
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showPdf_local', URL.createObjectURL(cookiePolicy)) } }>
                                            <SvgPdf className="icons" color="#ffffff" />
                                        </div> :
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showPdf', "settings/" + cookiePolicy) } }>
                                            <SvgPdf className="icons" color="#ffffff" />
                                        </div>
                                    }
                                </> : null
                            }  
                        </div> 
                        <div className="div_input">
                            <input type="text" className="" onChange={ (e)=>{ setWhatsapp(e.target.value) } } value={ whatsapp } maxLength="140" />
                            <span className="name_input">Whatsapp</span>
                        </div>
                    </div>

                    <div className="list_input_data">
                        <div className="div_input space_div">
                            <input type="text" className="" onChange={ (e)=>{ setAddress(e.target.value) } } value={ address } maxLength="140" />
                            <span className="name_input">Endereço</span>
                        </div>
                    </div>

                    <div className="list_input_data">
                        <div className="div_input space_div">
                            <input type="text" className="" onChange={ (e)=>{ setInstagram(e.target.value) } } value={ instagram } maxLength="140" />
                            <span className="name_input">Instagram</span>
                        </div>
                        {
                            instagram == "" ? null :
                            <div className="div_input">
                                <a href={ instagram } target="_blank">
                                    <SvgLink className="icons" color="#ffffff" />
                                </a>
                            </div>
                        }
                    </div>

                    <div className="list_input_data">
                        <div className="div_input space_div">
                            <input type="text" className="" onChange={ (e)=>{ setFacebook(e.target.value) } } value={ facebook } maxLength="140" />
                            <span className="name_input">Facebook</span>
                        </div>
                        {
                            facebook == "" ? null :
                            <div className="div_input">
                                <a href={ facebook } target="_blank">
                                    <SvgLink className="icons" color="#ffffff" />
                                </a>
                            </div>
                        }
                    </div>

                    <div className="list_input_data">
                        <div className="div_input space_div">
                            <input type="text" className="" onChange={ (e)=>{ setYoutube(e.target.value) } } value={ youtube } maxLength="140" />
                            <span className="name_input">Youtube</span>
                        </div>
                        {
                            youtube == "" ? null :
                            <div className="div_input">
                                <a href={ youtube } target="_blank">
                                    <SvgLink className="icons" color="#ffffff" />
                                </a>
                            </div>
                        }
                    </div>

                    <div className="list_input_data">
                        <div className="div_input space_div">
                            <input type="text" className="" onChange={ (e)=>{ setTwitter(e.target.value) } } value={ twitter } maxLength="140" />
                            <span className="name_input">Twitter</span>
                        </div>
                        {
                            twitter == "" ? null :
                            <div className="div_input">
                                <a href={ twitter } target="_blank">
                                    <SvgLink className="icons" color="#ffffff" />
                                </a>
                            </div>
                        }
                    </div>
                </div>

                <div className="show_page_data">
                    <div className="type_title">Categorias dos cursos</div>
                    <div className="list_input_data">
                        {
                            category.map((elem, index)=>{
                                return(
                                    <div className="page_released" key={ index }>
                                        <div className="">
                                            <input type="text" className="" onChange={ (e)=>{ HandleData('category', index, e.target.value) } } value={ elem.name } maxLength="140" />
                                        </div>
                                        <div className="" onClick={ ()=>{ DeleteCategory(elem.id, elem.name, elem.used) } }>
                                            <SvgDelete color="#FFFFFF" className="icons" />
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>

                <div className="show_page_data">
                    <div className="type_title">Módulos dos cursos</div>
                    <div className="list_input_data">
                        {
                            module.map((elem, index)=>{
                                return(
                                    <div className="page_released" key={ index }>
                                        <div className="">
                                            <input type="text" className="" onChange={ (e)=>{ HandleData('module', index, e.target.value) } } value={ elem.name } maxLength="140" />
                                        </div>
                                        <div className="" onClick={ ()=>{ DeleteCategory(elem.id, elem.name, elem.used) } }>
                                            <SvgDelete color="#FFFFFF" className="icons" />
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </form>
        </div>
    )
}