import { useState, useEffect } from "react";

import './Details.css';

import Select from 'react-select';

import { config } from "dataFixed";
import JoditEditor from 'jodit-react';

import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";
import { GetUserData, RegisterUserData, UnRegisterUserPage } from "interface/Users";
import { GetListPag, RegisterListPag, SetListPag, UnRegisterListPag } from "interface/Page";

import { Reg_Team, Reg_TeamStatus } from "services/RegisterData";

import { SvgAddNewData, SvgCancel, SvgDelete, SvgEdit, SvgImg, SvgReturn, SvgSave, SvgSetaRight, SvgStatus } from "components/SvgFile";

import PopUP_StatusTeam from "components/PopUp/StatusTeam";
import PopUp_DetailsStudy from "components/PopUp/DetailsStudy";
import PopUP_DetailsCourse from "components/PopUp/DetailsCourse";

export default function Page_Team_Details(props){
    const [ userId, setUserId ]     = useState(GetUserData('id'));
    const [ dataPage, setDataPage ] = useState(GetDataPage('team'));
    const [ courses, setCourses ]   = useState(GetDataPage('courses'));
    const [ idPage, setIdPage ]     = useState(GetListPag('currentPageId'));

    const [ name, setName ]               = useState(InitialData('name'));
    const [ note, setNote ]               = useState(InitialData('note'));
    const [ listStudy, setListStudy ]     = useState(InitialData('list_study'));
    const [ status, setStatus ]           = useState(false);
    const [ studentsAdd, setStudentsAdd ] = useState([]);

    const [ listCourse, setListCourse ] = useState(ListCourse());

    function InitialData(type){
        if(idPage !=0){
            const newData = dataPage.find(item => item.id == idPage);
            return newData[type];
        }
        if(type == 'list_study'){
            return [];
        }
        return '';
    }

    function ListCourse(){
        const options = []; 
        courses.map((key, index)=>{
            if(key.status == 1){
                options.push(key);
            }
        })
        return options;
    }

    function ListStudents(){
        const options = []; 
        GetDataPage('students').map((key, index)=>{
            if(key.status == 1){
                if(listStudy.find(item => item.id_user == key.id)){ }else {
                    options.push({
                        "value": key.id, "label": key.name
                    });
                }
            }
        })
        return options;
    }

    function OpenDetailsStudy(id, name){
        SetModalData('StatusClasses', { "id": id, "name": name });
        SetModalState('StatusClasses', true);
    }

    function DeleteData(id, value){
        SetModalData('Confirmation', { "origin": "team", "type" : "delete_user", "id" : id, "name": value, "setLoading": props.setLoading });
        SetModalState('Confirmation', true);
    }

    function OpenDetailsClasse(id){
        SetModalData('DetailsCourse', { "id": id, "type": "" });
        SetModalState('DetailsCourse', true);
    }

    function OpenFileCourse(id_user, id_course, listFile){
        SetModalData('StatusTeam', { "id_user": id_user, "id_course": id_course, "file_classe": listFile, "setLoading": props.setLoading });
        SetModalState('StatusTeam', true);
    }

    function HandleClasse(index, id_login, idCourse, statusCourse){
        const newData = [...listStudy];
        if(newData[index].state_classes.find(item => item.id_courses == idCourse) == undefined){
            newData[index].state_classes.push({ "id": 0, "status": statusCourse, "id_login": id_login, "id_team": idPage, "id_courses": idCourse, "list_files": [] });
        }else {
            newData[index].state_classes.find(item => item.id_courses == idCourse).status = statusCourse;
        }     
        setListStudy(newData); 
    }

    function SaveData(event){
        event.preventDefault();
        props.setLoading(true);
        Reg_Team(idPage, name, note, listStudy, studentsAdd, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    function ReturnPage(){
        SetListPag('currentPage', 'team');
        SetListPag('currentPageId', 'remuve');
    }

    useEffect(()=>{
        RegisterUserData('id', setUserId);
        RegisterDataPage('team', setDataPage);
        RegisterDataPage('courses', setCourses);
        RegisterListPag('currentPageId', setIdPage);

        return ()=>{
            UnRegisterUserPage('id', setUserId);
            UnRegisterDataPage('team', setDataPage);
            UnRegisterDataPage('courses', setCourses);
            UnRegisterListPag('currentPageId', setIdPage);
        };
    }, []);

    useEffect(()=>{
        setDataPage(GetDataPage('team'));
        setCourses(GetDataPage('courses'));
        setIdPage(GetListPag('currentPageId'));

        setName(InitialData('name'));
        setNote(InitialData('note'));
        setListStudy(InitialData('list_study'));

        setStatus(false);
        setStudentsAdd([]);

        setListCourse(ListCourse());
    }, [dataPage, idPage]);
    
    return(
        <div className="Page_Team_Details">
            <form onSubmit={ SaveData }>
                <div className="new_block">
                    <div className="div_return" onClick={ ()=>{ ReturnPage() }}>
                        <SvgReturn className="icons" color="#061821" />
                        Voltar
                    </div>
                    <div className="title_page">Detalhes</div>
                    <div className="list_opt_alt_page">
                        <button className="div_add_new_data">
                            <div className="new_data_add">
                                <SvgSave color="#ffffff" className="icons" />
                            </div>
                            <div className="name_btn_add">Salvar</div>
                        </button>
                    </div>
                </div>

                <div className="show_page_data">
                    <div className="type_title">Dados da turma</div>
                    <div className="list_input_data">
                        <div className="div_input space_div">
                            <input type="text" className="input_name" onChange={ (e)=>{ setName(e.target.value) } } value={ name } maxLength="140" required />
                            <span className="name_input">Nome</span>
                        </div>
                    </div>
                    <div className="list_input_data">
                        <div className="div_input space_div">
                            <JoditEditor className="show_textarea" config={ config } value={ note ? note.replaceAll('&#34;', '"') : "" } onBlur={ newContent => setNote(newContent) } />
                            <span className="name_input">Observação</span>
                        </div>
                    </div>
                </div>

                <div className="show_page_data">
                    <div className="type_title">
                        <div className="">Alunos</div>
                        <div className="cursor_pointer" onClick={ ()=>{ setStatus(!status) } }>
                            { status != true ? "+ aluno" : "- cancelar" }
                        </div>
                    </div>
                    {
                        status != true ? null : 
                        <div className="div_input add_new_course">
                            <div className="div_input space_div">                            
                                <Select className="div_space" defaultOptions options={ ListStudents() } onChange={ (e)=> { setStudentsAdd(e); } } value={ studentsAdd } isMulti /> 
                            </div>
                        </div>
                    }
                    <div className="list_input_data">
                        {
                            listStudy.length > 0 ?
                            <div className="show_data_team">
                                <div className="div_name_study">
                                    <table width="100%" cellPadding="0" cellSpacing="0">
                                        <tbody>
                                            <tr>
                                                <th className="table_height table_td" align="center" width="10">#</th>
                                                <th className="table_height" width="140">Aluno</th>
                                            </tr>
                                            {
                                                listStudy.map((key, index)=>{
                                                    return(
                                                        <tr className="table_end_height" key={ index }>
                                                            <td className="table_height table_td" align="center">
                                                                { index + 1 }
                                                            </td>
                                                            <td className="table_height" style={ { cursor: 'pointer' } } onClick={ ()=>{ OpenDetailsStudy(key.id_user, key.name) } }>
                                                                <div data-tooltip-id={ "user_" } data-tooltip-content="Clique aqui para visualizar dados do aluno" data-tooltip-place="top" className="show_name">
                                                                    { key.name }
                                                                </div>
                                                                {
                                                                    key.status == 1 ? null : 
                                                                    <div data-tooltip-id={ "type_user_" } data-tooltip-content="Aluno desativado" data-tooltip-place="top" className="show_icon_cancel" />
                                                                }
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <div className="div_list_classes">
                                    <table width="100%" cellPadding="0" cellSpacing="0">
                                        <tbody>
                                            <tr>
                                                {
                                                    listCourse.map((key, index)=>{
                                                        return(
                                                            <td className="table_height table_td table_name_course" align="center" key={ index } onClick={ ()=>{ OpenDetailsClasse(key.id) } }>
                                                                { key.title }
                                                            </td>
                                                        )
                                                    })
                                                }
                                            </tr>
                                        </tbody>
                                        <tbody>
                                            {
                                                listStudy.map((key, index)=>{
                                                    return(
                                                        <tr key={ index }>
                                                            {
                                                                listCourse.map((key_1, index_1)=>{
                                                                    let idStatus = 0;
                                                                    let fileCourse = [];
                                                                    let statusCourse = false;
                                                                    if(key.state_classes.find(item => item.id_courses == key_1.id)){
                                                                        let dataStatus = key.state_classes.find(item => item.id_courses == key_1.id);

                                                                        idStatus     = dataStatus.id;
                                                                        fileCourse   = dataStatus.list_files;
                                                                        statusCourse = dataStatus.status;
                                                                    }

                                                                    return(
                                                                        <td className="table_height table_td" align="center" key={ index_1 }>
                                                                            <div className="div_opt_alt">
                                                                                <div className="">
                                                                                    <input type="checkbox" className="data_classes" value="true" onChange={ (e)=>{ HandleClasse(index, key.id_user, key_1.id, !statusCourse) } } checked={ statusCourse } />
                                                                                </div>
                                                                                {
                                                                                    fileCourse.length > 0 ?
                                                                                    <div className="" onClick={ ()=>{ OpenFileCourse(key.id, key_1.id, fileCourse) } }>
                                                                                        <SvgEdit className="icons" color="#061821" />
                                                                                    </div> : null
                                                                                }
                                                                            </div>
                                                                        </td>
                                                                    )
                                                                })
                                                            }
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <div className="div_delete">
                                    <table width="100%" cellPadding="0" cellSpacing="0">
                                        <tbody>
                                            <tr>
                                                <th className="table_height table_td table_left" align="center" width="20">#</th>
                                            </tr>
                                            {
                                                listStudy.map((key, index)=>{
                                                    return(
                                                        <tr className="table_end_height" key={ index }>
                                                            <td className="table_height table_td">
                                                                <div className="div_opt_alt">
                                                                    <div onClick={ ()=>{ DeleteData(key.id, key.name) } }>
                                                                        <SvgDelete color="#F00000" className="icons"/>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <Tooltip id="user_" />
                                <Tooltip id="type_user_" />
                                <Tooltip id="classe_" />
                            </div>
                            :
                            status != true ? <div className="no_data">Nenhum curso liberado até o momento...</div> : null
                        }
                    </div>
                </div>
            </form>
            <PopUP_DetailsCourse />
            <PopUp_DetailsStudy />
            <PopUP_StatusTeam />
        </div>
    )
}