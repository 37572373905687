import React, { useState, useEffect, useRef } from 'react';

import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import './PhotoCrop.css';

import { SetModalData, SetModalState, RegisterModalData, RegisterModalObserver } from 'interface/PopUp.js';
import { SvgClose } from 'components/SvgFile';

export default function PopUp_PhotoCrop(){

    const positionImg = useRef();
    
    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);

    const [ proportionX, setProportionX ] = useState(16);
    const [ proportionY, setProportionY ] = useState(16);

    const [ crop, setCrop ] = useState({
        unit: '%', // Can be 'px' or '%'
        aspect: proportionX / proportionY,
        x: 0,
        y: 0,
        width: 0,
        height: 0
    });

    useEffect(()=>{
        RegisterModalData('PhotoCrop', setModaldata);
        RegisterModalObserver('PhotoCrop', setShowPopUp);
    }, []);

    useEffect(()=>{
        if(showPopUp == true){
        }
    }, [showPopUp]);

    async function cropImageNow (){
        modalData.RegisterCropData('img', 
            { 
                "type"      : modalData.origin,
                "index"     : modalData.index, 
                "index_1"   : modalData.index_1,
                "upload"    : true,
                "file"      : modalData.file, 
                "showImg"   : modalData.showImg, 
                "positionX" : crop.x, 
                "positionY" : crop.y, 
                "width"     : crop.width, 
                "height"    : crop.height,
                "showWidth" : positionImg.current.clientWidth,
                "widthReal" : positionImg.current.naturalWidth
            },
            modalData.index
        );        
        SetModalState('PhotoCrop', false);
    };
 
    // Starts cropping at 9/16 ratio centered on image
    function onImageLoad(e) {
        const { naturalWidth: width, naturalHeight: height } = e.currentTarget;
        let crop = "";
        modalData.origin == "portfolio" ?
        crop = centerCrop(
            makeAspectCrop(
                {
                    unit: '%',
                    width: 90,
                }
            ),
            width,
            height
        ) :
        crop = centerCrop(
            makeAspectCrop(
                {
                    unit: '%',
                    width: 90,
                }
            ),
            width,
            height
        )  
        setCrop(crop);
    }

    function ClosePopUp(){
        SetModalState('PhotoCrop', false);
    }

    return (
        (showPopUp ? 
            <div className="PopUp">
                <div className="all photo_crop">
                    <div className="div_data type_div">
                        <div className="title">Recorte de foto</div>
                        <div className="close" onClick={ ()=>{ ClosePopUp(); } }>
                            <SvgClose color="#F00000" className="icons" />
                        </div>
                    </div>
                    <div className="div_data" style={ { paddingTop: 0 } }>
                        <div className="content">
                            {
                                crop.width > 0 ?
                                <div className="new_block_text" onClick={ ()=>{ cropImageNow() } }>Salvar</div> :
                                <div className="new_block_text">É necessário recortar a imagem para salvar</div>
                            }
                            <div className="">Clique na imagem e arrasta para realizar o recorte</div>
                            <div className="image_crop">
                                <ReactCrop crop={ crop } onChange={ setCrop } aspect={ proportionX / proportionY }>
                                    <img ref={ positionImg } src={ modalData.showImg } onLoad={ onImageLoad } className="image" />
                                </ReactCrop>
                            </div>

                        </div>
                    </div>
                </div>
            </div> 
        : <React.Fragment></React.Fragment>)
    );
}