import { useState, useEffect } from "react";

import './Video.css';

import JoditEditor from 'jodit-react';
import { config } from "dataFixed";

import { SvgVimeo, SvgYoutube } from "components/SvgFile";

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";
import { GetListPag, RegisterListPag, UnRegisterListPag } from "interface/Page";

export default function ModelSite_Video(props){

    const [ idPage, setIdPage ]     = useState(GetListPag('currentPageId'));
    const [ dataPage, setDataPage ] = useState(GetDataPage(props.origin));

    const [ bgPage, setBgPage ]       = useState(InitialData('bg_color'));
    const [ typeVideo, setTypeVideo ] = useState(InitialData('video_type'));
    const [ video, setVideo ]         = useState(InitialData('video_code'));

    const [ typeDestiny, setTypeDestiny ] = useState(InitialData('btn_1_type'));
    const [ destiny, setDestiny ]         = useState(InitialData('btn_1_destiny'));
    const [ button, setButton]            = useState(InitialData('btn_1_name'));

    const [ title_1, setTitle_1 ] = useState(InitialData('title_1'));
    const [ title_2, setTitle_2 ] = useState(InitialData('title_2'));
    const [ title_3, setTitle_3 ] = useState(InitialData('title_3'));
    const [ text_1, setText_1 ]   = useState(InitialData('text_1'));
    const [ text_2, setText_2 ]   = useState(InitialData('text_2'));

    function InitialData(type){
        if(idPage !=0){
            if(dataPage.length > 0){
                const newData = dataPage.find(item => item.id == idPage);
                return newData[type];
            }
        }
        if(type == 'video_type'){
            return 'Vimeo';
        }
        if(type == 'btn_1_type'){
            return 'Interno';
        }
        return '';
    }

    function LoadingData(){
        props.ReturnData({ "bg_page": bgPage, "typeVideo": typeVideo, "video": video, "typeDestiny_1": typeDestiny, "destiny_1": destiny, "button": button, "title_1": title_1, "title_2": title_2, "title_3": title_3, "text_1": text_1, "text_2": text_2 });
    }

    function HandleBgPage(value){
        setBgPage(value);
        props.ReturnData({ "bg_page": value, "typeVideo": typeVideo, "video": video, "typeDestiny_1": typeDestiny, "destiny_1": destiny, "button": button, "title_1": title_1, "title_2": title_2, "title_3": title_3, "text_1": text_1, "text_2": text_2 });
    }

    function HandleTypeVideo(value){
        setTypeVideo(value);
        props.ReturnData({ "bg_page": bgPage, "typeVideo": value, "video": video, "typeDestiny_1": typeDestiny, "destiny_1": destiny, "button": button, "title_1": title_1, "title_2": title_2, "title_3": title_3, "text_1": text_1, "text_2": text_2 });
    }

    function HandleVideo(value){
        setVideo(value);
        props.ReturnData({ "bg_page": bgPage, "typeVideo": typeVideo, "video": value, "typeDestiny_1": typeDestiny, "destiny_1": destiny, "button": button, "title_1": title_1, "title_2": title_2, "title_3": title_3, "text_1": text_1, "text_2": text_2 });
    }

    function HandleTypeDestiny(value){
        setTypeDestiny(value);
        props.ReturnData({ "bg_page": bgPage, "typeVideo": typeVideo, "video": video, "typeDestiny_1": value, "destiny_1": destiny, "button": button, "title_1": title_1, "title_2": title_2, "title_3": title_3, "text_1": text_1, "text_2": text_2 });
    }

    function HandleDestiny(value){
        setDestiny(value);
        props.ReturnData({ "bg_page": bgPage, "typeVideo": typeVideo, "video": video, "typeDestiny_1": typeDestiny, "destiny_1": value, "button": button, "title_1": title_1, "title_2": title_2, "title_3": title_3, "text_1": text_1, "text_2": text_2 });
    }

    function HandleButton(value){
        setButton(value);
        props.ReturnData({ "bg_page": bgPage, "typeVideo": typeVideo, "video": video, "typeDestiny_1": typeDestiny, "destiny_1": destiny, "button": value, "title_1": title_1, "title_2": title_2, "title_3": title_3, "text_1": text_1, "text_2": text_2 });
    }

    function HandleTitle_1(value){
        setTitle_1(value);
        props.ReturnData({ "bg_page": bgPage, "typeVideo": typeVideo, "video": video, "typeDestiny_1": typeDestiny, "destiny_1": destiny, "button": button, "title_1": value, "title_2": title_2, "title_3": title_3, "text_1": text_1, "text_2": text_2 });
    }

    function HandleTitle_2(value){
        setTitle_2(value);
        props.ReturnData({ "bg_page": bgPage, "typeVideo": typeVideo, "video": video, "typeDestiny_1": typeDestiny, "destiny_1": destiny, "button": button, "title_1": title_1, "title_2": value, "title_3": title_3, "text_1": text_1, "text_2": text_2 });
    }

    function HandleTitle_3(value){
        setTitle_3(value);
        props.ReturnData({ "bg_page": bgPage, "typeVideo": typeVideo, "video": video, "typeDestiny_1": typeDestiny, "destiny_1": destiny, "button": button, "title_1": title_1, "title_2": title_2, "title_3": value, "text_1": text_1, "text_2": text_2 });
    }

    function HandleText_1(value){
        setText_1(value);
        props.ReturnData({ "bg_page": bgPage, "typeVideo": typeVideo, "video": video, "typeDestiny_1": typeDestiny, "destiny_1": destiny, "button": button, "title_1": title_1, "title_2": title_2, "title_3": title_3, "text_1": value, "text_2": text_2 });
    }

    function HandleText_2(value){
        setText_2(value);
        props.ReturnData({ "bg_page": bgPage, "typeVideo": typeVideo, "video": video, "typeDestiny_1": typeDestiny, "destiny_1": destiny, "button": button, "title_1": title_1, "title_2": title_2, "title_3": title_3, "text_1": text_1, "text_2": value });
    }

    function OpenFile(type, file){
        SetModalData('ShowFile', { "type": type, "file": file });
        SetModalState('ShowFile', true);
    }

    useEffect(()=>{
        RegisterDataPage(props.origin, setDataPage);
        RegisterListPag('currentPageId', setIdPage);
        
        LoadingData();

        return ()=>{
            UnRegisterDataPage(props.origin, setDataPage);
            UnRegisterListPag('currentPageId', setIdPage);
        };
    }, []);

    useEffect(()=>{
        setDataPage(GetDataPage(props.origin));
        setIdPage(GetListPag('currentPageId'));

        setBgPage(InitialData('bg_color'));
        setTypeVideo(InitialData('video_type'));
        setVideo(InitialData('video_code'));

        setTypeDestiny(InitialData('btn_1_type'));
        setDestiny(InitialData('btn_1_destiny'));
        setButton(InitialData('btn_1_name'));

        setTitle_1(InitialData('title_1'));
        setTitle_2(InitialData('title_2'));
        setTitle_3(InitialData('title_3'));
        setText_1(InitialData('text_1'));
        setText_2(InitialData('text_2'));
        
        LoadingData();
    }, [dataPage, idPage]);

    return(
        <div className="ModelSite_Video">
            <div className="list_data_page">
            
                <div className="div_input space_div title_side">
                    <div className="div_input space_div">
                        <input type="text" className="data_input" onChange={ (e)=>{ HandleBgPage(e.target.value) } } value={ bgPage } maxLength="80" />
                        <span className="name_input">Cor de fundo</span>
                    </div>
                    <div className="div_input">
                        <div className="div_color" style={ { background: bgPage } } />
                    </div>
                </div>
                
                <div className="div_input space_div title_side">
                    <div className="div_input">
                        <select className="data_select" onChange={ (e)=>{ HandleTypeVideo(e.target.value) } } value={ typeVideo }>
                            <option value="Vimeo">Vimeo</option>
                            <option value="Youtube">Youtube</option>
                        </select>
                        <span className="name_input">Tipo</span>
                    </div>
                    <div className="div_input">
                        <input type="text" className="data_input" onChange={ (e)=>{ HandleVideo(e.target.value) } } value={ video } maxLength="80" />
                        <span className="name_input">Código do vídeo</span>
                    </div>
                    {
                        video !='' ?
                        <div className="div_input" onClick={ ()=>{ OpenFile(typeVideo, video) } }>
                            {
                                typeVideo == 'Vimeo' ?
                                <SvgVimeo color="#324d6b" className="icons_video"/> :
                                <SvgYoutube color="#324d6b" className="icons_video"/>
                            }
                        </div> : ""
                    }
                </div>
            
                <div className="div_input space_div title_side">
                    <div className="div_input space_div">
                        <input type="text" className="data_input" onChange={ (e)=>{ HandleTitle_1(e.target.value) } } value={ title_1 } maxLength="80" />
                        <span className="name_input">Título 1</span>
                    </div>
                    <div className="div_input space_div">
                        <input type="text" className="data_input" onChange={ (e)=>{ HandleTitle_2(e.target.value) } } value={ title_2 } maxLength="80" />
                        <span className="name_input">Título 2</span>
                    </div>
                </div>

                <div className="div_input space_div">
                    <JoditEditor className="show_textarea" config={ config } value={ text_1.replaceAll('&#34;', '"') } onBlur={ newContent => HandleText_1(newContent) } />
                    <span className="name_input">Texto 1</span>
                </div>            
                
                <div className="div_input space_div">
                    <input type="text" className="data_input" onChange={ (e)=>{ HandleTitle_3(e.target.value) } } value={ title_3 } maxLength="80" />
                    <span className="name_input">Título 3</span>
                </div>

                <div className="div_input space_div">
                    <JoditEditor className="show_textarea" config={ config } value={ text_2.replaceAll('&#34;', '"') } onBlur={ newContent => HandleText_2(newContent) } />
                    <span className="name_input">Texto 2</span>
                </div>
                
                <div className="div_input space_div title_side">
                    <div className="div_input">
                        <select className="select_button" onChange={ (e)=>{ HandleTypeDestiny(e.target.value) } } value={ typeDestiny }>
                            <option value="Interno">Interno</option>
                            <option value="Externo">Externo</option>
                        </select>
                        <span className="name_input">Direcionamento</span>
                    </div>
                    <div className="div_input">
                        {
                            typeDestiny == 'Interno' ?
                            <select onChange={ (e)=>{ HandleDestiny(e.target.value) } } value={ destiny } required>
                                <option value="">#</option>
                                <option value="home">Home</option>
                                <option value="howworks">Como funciona</option>
                                <option value="courses">Cursos</option>
                                <option value="mentoring">Mentoria</option>
                                <option value="rolo">Rolo</option>
                                <option value="typepayment">Tipo de pagamento</option>
                                <option value="enrollment">Inscrição</option>
                            </select> :
                            <input type="text" className="data_input" onChange={ (e)=>{ HandleDestiny(e.target.value) } } value={ destiny !='' ? typeDestiny == 'Externo' ? destiny.replaceAll(' ', '') : "" : "" } placeholder="cole o link..." />
                        }
                        <span className="name_input">Para</span>
                    </div>
                    <div className="div_input space_div">
                        <input type="text" className="data_input" onChange={ (e)=>{ HandleButton(e.target.value) } } value={ button } maxLength="80" />
                        <span className="name_input">Botão</span>
                    </div>
                </div>

                <div className="div_input div_space space_top">
                    <div className={ video == "" || title_1 == "" || text_1 == "" ? "div_show_data div_space img" : "div_show_data div_space div_img" } style={ { background: bgPage } }>
                        {
                            video == "" || title_1 == "" || text_1 == "" ? 
                            <img alt="home_example" src="./assets/bg_video.jpg" className="img_example" />
                            :
                            <>
                                <div className="show_video">
                                    {
                                        typeVideo == "Vimeo" ?
                                            video == "" ? null :
                                            <iframe className="div_video" src={ "https://player.vimeo.com/video/" + video + "?color=ffffff&amp;title=0&amp;byline=0&amp;portrait=0" } frameBorder="0" webkitallowfullscreen="" mozallowfullscreen="" allowFullScreen="" />
                                        :
                                            video == "" ? null :
                                            <iframe className="div_video" src={ "https://www.youtube.com/embed/" + video + "?color=ffffff&amp;title=0&amp;byline=0&amp;portrait=0" } frameBorder="0" webkitallowfullscreen="" mozallowfullscreen="" allowFullScreen="" />
                                    }
                                </div>
                                <div className="div_text">
                                    <div className="div_title">
                                        {
                                            title_1 ? <div className="subtitle">{ title_1 }</div> : null
                                        }
                                        {
                                            title_2 ? <div className="title">{ title_2 }</div> : null
                                        }
                                    </div>
                                    {
                                        text_1 ? <div className="text" dangerouslySetInnerHTML={ { __html: text_1.replaceAll('&#34;', '"') } } /> : null
                                    }                    
                                    {
                                        title_3 ? <div className="subtitle">{ title_3 }</div> : null
                                    }
                                    {
                                        text_2 ? <div className="text" dangerouslySetInnerHTML={ { __html: text_2.replaceAll('&#34;', '"') } } /> : null
                                    } 
                                    {
                                        button ? <div className="button_text">{ button }</div> : null
                                    }
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};