import React, { useState, useEffect } from "react";

import './Questions.css';

import { SvgClose, SvgImg } from "components/SvgFile";

import { RegisterModalData, RegisterModalObserver, SetModalData, SetModalState } from "interface/PopUp";

export default function PopUp_Questions(){

    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);

    const [ text, setText ] = useState('');
    const [ listInf, setListInf ] = useState([]);

    function ClosePopUp(){
        setText('');
        setListInf([]);
        SetModalState('Questions', false);
    }

    useEffect(()=>{
        RegisterModalData('Questions', setModaldata);
        RegisterModalObserver('Questions', setShowPopUp);
    }, []);  
    
    useEffect(()=>{
        if(showPopUp){
            setText(modalData.text);
            setListInf(modalData.showData);
        }
    }, [showPopUp]);

    return (
        (showPopUp ?
            <div className="PopUp">
                <div className="all Questions">
                    <div className="div_data">
                        <div className="title">
                            Questionário respondido do curso
                        </div>
                        <div className="close" onClick={ ()=>{ ClosePopUp() } }>
                            <SvgClose color="#F00000" className="icons" />
                        </div>
                    </div>
                    <div className="div_data list_inf_" style={ { paddingTop: 0 } }>
                        {
                            listInf.map((elem, index)=>{
                                return(
                                    <div className="list_inf_questions" key={ index }>
                                        <div className="conclusion_title">{ elem.title }</div>
                                        <div className="" dangerouslySetInnerHTML={ { __html: elem.text ? elem.text.replaceAll('&#34;', '"') : "" } } />
                                        <div className="list_opt_click">
                                            {
                                                elem.list_response.map((elem_1, index_1)=>{
                                                    return(
                                                        <div className={ elem_1.opt == elem.opt ? "div_opt opt_selected" : "div_opt" } key={ index_1 }>
                                                            <div className="div_opt_number">{ index_1 + 1 }</div>
                                                            <div className="div_opt_title">{ elem_1.title }</div>
                                                            <div className="div_opt_subtitle">{ elem_1.subtitle }</div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            })
                        }
                        <div className="list_inf_questions">
                            <div className="conclusion_title">Observação</div>
                            <div className="" dangerouslySetInnerHTML={ { __html: text.replaceAll('&#34;', '"') } } />
                        </div>
                    </div>
                </div>
                <div className="close_all" onClick={ ()=>{ ClosePopUp(); } } />
            </div>
        : <React.Fragment></React.Fragment>)
    );
}