import { useState, useEffect } from "react";

import './Details.css';

import Select from 'react-select';

import JoditEditor from 'jodit-react';
import { config } from "dataFixed";

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";
import { GetUserData, RegisterUserData, UnRegisterUserPage } from "interface/Users";
import { GetListPag, SetListPag, RegisterListPag, UnRegisterListPag } from "interface/Page";

import { SvgDelete, SvgImg, SvgPdf, SvgReturn, SvgSave } from "components/SvgFile";

import PopUP_ReturnResponse from "components/PopUp/ReturnResponse";
import { Reg_Questionnaire } from "services/RegisterData";

export default function Site_Questionnaire_details(){

    const [ loading, setLoading ] = useState(false);
    const [ userId, setUserId ] = useState(GetUserData('id'));
    const [ dataPage, setDataPage ] = useState(GetDataPage('questionnaire'));
    const [ idPage, setIdPage ] = useState(GetListPag('currentPageId'));

    const [ title, setTitle ] = useState(InitialData('title'));
    const [ text, setText ] = useState(InitialData('text'));
    const [ list, setList ] = useState(InitialData('list'));

    function InitialData(type){
        const newData = GetDataPage('questionnaire').find(item => item.id == idPage);
        
        if(newData){
            return newData[type];
        }
        if(type == 'list'){
            return [];
        }
        return '';
    }

    function ReturnPage(){
        SetListPag('currentPage', 'questionnaire');
        SetListPag('currentPageId', 'remuve');
    } 

    function SaveData(event){
        event.preventDefault();
        setLoading(true);
        Reg_Questionnaire(idPage, title, text, list, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        setLoading(false);        
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    function HandleData(type, index, value){
        const newData = [...list];
        newData[index][type] = value
        setList(newData);
    }
    function DeleteData(id, index, value){
        if(id == 0){
            const newData = [...list];
            newData.splice(index , 1);
            setList(newData);
        }else {
            SetModalData('Confirmation', { "origin": "questionnaire", "type" : "delete_response", "id" : id, "name": value });
            SetModalState('Confirmation', true);
        }
    }

    useEffect(()=>{
        RegisterUserData('id', setUserId);
        RegisterDataPage('questionnaire', setDataPage);
        RegisterListPag('currentPageId', setIdPage);

        return ()=>{
            UnRegisterUserPage('id', setUserId);
            UnRegisterDataPage('questionnaire', setDataPage);
            UnRegisterListPag('currentPageId', setIdPage);
        };
    }, []);

    useEffect(()=>{
        setDataPage(GetDataPage('questionnaire'));
        setIdPage(GetListPag('currentPageId'));
        
        setTitle(InitialData('title'));
        setText(InitialData('text'));
        setList(InitialData('list'));
    }, [dataPage, idPage]);

    return(
        <div className="Site_Questionnaire_details">
            <div className={ loading == true ? "return_data_save div_loading" : "return_data_save" }>
                <img alt="loading" src="./assets/loading.gif" className="loading" />
            </div>
            <form onSubmit={ SaveData } className="div_login">
                <div className="new_block">
                    <div className="div_return" onClick={ ()=>{ ReturnPage() }}>
                        <SvgReturn className="icons" color="#061821" />
                        Voltar
                    </div>
                    <div className="title_page">Detalhes</div>
                    <div className="list_opt_alt_page">
                        <button className="div_add_new_data">
                            <div className="new_data_add">
                                <SvgSave color="#ffffff" className="icons" />
                            </div>
                            <div className="name_btn_add">Salvar</div>
                        </button>
                    </div>
                </div>

                <div className="show_page_data">
                    <div className="type_title">Dados da pergunta</div>
                    <div className="list_input_data">
                        <div className="div_input space_div">
                            <input type="text" className="input_name" onChange={ (e)=>{ setTitle(e.target.value) } } value={ title } maxLength="140" required />
                            <span className="name_input">Título</span>
                        </div>
                    </div>

                    <div className="list_input_data" style={ { marginTop: "16px" } }>
                        <div className="div_input space_div">
                            <JoditEditor className="show_textarea" config={ config } value={ text ? text.replaceAll('&#34;', '"') : "" } onBlur={ newContent => setText(newContent) } />
                            <span className="name_input">Descrição</span>
                        </div>
                    </div>
                </div>

                <div className="show_page_data">
                    <div className="type_title">
                        <div className="">Respostas</div>
                        <div className="cursor_pointer"  onClick={ ()=>{ setList([...list, { "id": 0, "title": "", "subtitle": "" }]) } }>
                            Adicionar nova resposta
                        </div>
                    </div>
                    
                    {
                        list.length > 0 ?
                        list.map((elem, index)=>{
                            return(
                                <div className="list_input_data div_show_data_specific" key={ index }>
                                    <div className="div_input space_div">
                                        <input type="text" className="input_name" onChange={ (e)=>{ HandleData('title', index, e.target.value) } } value={ elem.title } maxLength="140" />
                                        <span className="name_input">Título</span>
                                    </div>
                                    <div className="div_input space_div">
                                        <input type="text" className="input_name" onChange={ (e)=>{ HandleData('subtitle', index, e.target.value) } } value={ elem.subtitle } maxLength="140" />
                                        <span className="name_input">Subtitulo</span>
                                    </div>
                                    <div className="div_input" onClick={ ()=>{ DeleteData(elem.id, index, elem.title) } }>
                                        <SvgDelete className="trash" color="#ffffff" />
                                    </div>
                                </div>
                            )
                        })
                        : <div className="no_data">Nenhum dado encontrado...</div>
                    }
                </div>
            </form>
            <PopUP_ReturnResponse />
        </div>
    )
}