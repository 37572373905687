import { useState, useEffect, useRef } from "react";

import './Home.css';

import { SvgDelete, SvgImg } from "components/SvgFile";

import { GetListPag, RegisterListPag, SetListPag, UnRegisterListPag } from "interface/Page";
import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";

export default function ModelSite_Home(props){
    const [ idPage, setIdPage ]     = useState(GetListPag('currentPageId'));
    const [ dataPage, setDataPage ] = useState(GetDataPage(props.origin));

    const [ imgOrigin, setImgOrigin ] = useState(InitialData('img_origin'));
    const [ imgName, setImgName ]     = useState(InitialData('img_name'));

    const [ bg_img, setBgImg ]     = useState(InitialData('bg_img'));
    const [ bg_color, setBgColor ] = useState(InitialData('bg_color'));
    const [ title_1, setTitle_1 ]  = useState(InitialData('title_1'));
    const [ title_2, setTitle_2 ]  = useState(InitialData('title_2'));
    const [ title_3, setTitle_3 ]  = useState(InitialData('title_3'));
    const [ title_4, setTitle_4 ]  = useState(InitialData('title_4'));
    const [ title_5, setTitle_5 ]  = useState(InitialData('title_5'));
    const [ title_6, setTitle_6 ]  = useState(InitialData('title_6'));

    const [ btn_1_type, setBtn_1_type ]       = useState(InitialData('btn_1_type'));
    const [ btn_1_destiny, setBtn_1_destiny ] = useState(InitialData('btn_1_destiny'));
    const [ btn_1_name, setBtn_1_name ]       = useState(InitialData('btn_1_name'));

    const [ btn_2_type, setBtn_2_type ]       = useState(InitialData('btn_2_type'));
    const [ btn_2_destiny, setBtn_2_destiny ] = useState(InitialData('btn_2_destiny'));
    const [ btn_2_name, setBtn_2_name ]       = useState(InitialData('btn_2_name'));

    const [ status, setStatus ] = useState(false);

    function LoadingData(){
        props.ReturnData({ "img_origin": imgOrigin, "img_name": imgName, "bg_img": bg_img, "bg_color": bg_color, "title_1": title_1, "title_2": title_2, "title_3": title_3, "title_4": title_4, "title_5": title_5, "title_6": title_6, "btn_1_type": btn_1_type, "btn_1_destiny": btn_1_destiny, "btn_1_name": btn_1_name, "btn_2_type": btn_2_type, "btn_2_destiny": btn_2_destiny, "btn_2_name": btn_2_name });
    }

    function InitialData(type){
        if(idPage !=0){
            const newData = dataPage.find(item => item.id == idPage);
            return newData[type];
        }
        if(type == 'btn_1_type' || type == 'btn_2_type'){
            return 'Interno';
        }
        return '';
    }   

    function HandleImgOrigin(value){
        setImgOrigin(value);
        props.ReturnData({ "img_origin": value, "img_name": imgName, "bg_img": bg_img, "bg_color": bg_color, "title_1": title_1, "title_2": title_2, "title_3": title_3, "title_4": title_4, "title_5": title_5, "title_6": title_6, "btn_1_type": btn_1_type, "btn_1_destiny": btn_1_destiny, "btn_1_name": btn_1_name, "btn_2_type": btn_2_type, "btn_2_destiny": btn_2_destiny, "btn_2_name": btn_2_name });
    }

    function HandleImgName(value){
        setImgName(value);
        props.ReturnData({ "img_origin": imgOrigin, "img_name": value, "bg_img": bg_img, "bg_color": bg_color, "title_1": title_1, "title_2": title_2, "title_3": title_3, "title_4": title_4, "title_5": title_5, "title_6": title_6, "btn_1_type": btn_1_type, "btn_1_destiny": btn_1_destiny, "btn_1_name": btn_1_name, "btn_2_type": btn_2_type, "btn_2_destiny": btn_2_destiny, "btn_2_name": btn_2_name });
    }

    function HandleFile(value){
        setBgImg(value);
        value ? setStatus(true) : setStatus(false);
        props.ReturnData({ "img_origin": imgOrigin, "img_name": imgName, "bg_img": value, "bg_color": bg_color, "title_1": title_1, "title_2": title_2, "title_3": title_3, "title_4": title_4, "title_5": title_5, "title_6": title_6, "btn_1_type": btn_1_type, "btn_1_destiny": btn_1_destiny, "btn_1_name": btn_1_name, "btn_2_type": btn_2_type, "btn_2_destiny": btn_2_destiny, "btn_2_name": btn_2_name });
    }

    function HandleBgColor(value){
        setBgColor(value);
        props.ReturnData({ "img_origin": imgOrigin, "img_name": imgName, "bg_img": bg_img, "bg_color": value, "title_1": title_1, "title_2": title_2, "title_3": title_3, "title_4": title_4, "title_5": title_5, "title_6": title_6, "btn_1_type": btn_1_type, "btn_1_destiny": btn_1_destiny, "btn_1_name": btn_1_name, "btn_2_type": btn_2_type, "btn_2_destiny": btn_2_destiny, "btn_2_name": btn_2_name });
    }

    function HandleTitle_1(value){
        setTitle_1(value);
        props.ReturnData({ "img_origin": imgOrigin, "img_name": imgName, "bg_img": bg_img, "bg_color": bg_color, "title_1": value, "title_2": title_2, "title_3": title_3, "title_4": title_4, "title_5": title_5, "title_6": title_6, "btn_1_type": btn_1_type, "btn_1_destiny": btn_1_destiny, "btn_1_name": btn_1_name, "btn_2_type": btn_2_type, "btn_2_destiny": btn_2_destiny, "btn_2_name": btn_2_name });
    }

    function HandleTitle_2(value){
        setTitle_2(value);
        props.ReturnData({ "img_origin": imgOrigin, "img_name": imgName, "bg_img": bg_img, "bg_color": bg_color, "title_1": title_1, "title_2": value, "title_3": title_3, "title_4": title_4, "title_5": title_5, "title_6": title_6, "btn_1_type": btn_1_type, "btn_1_destiny": btn_1_destiny, "btn_1_name": btn_1_name, "btn_2_type": btn_2_type, "btn_2_destiny": btn_2_destiny, "btn_2_name": btn_2_name });
    }

    function HandleTitle_3(value){
        setTitle_3(value);
        props.ReturnData({ "img_origin": imgOrigin, "img_name": imgName, "bg_img": bg_img, "bg_color": bg_color, "title_1": title_1, "title_2": title_2, "title_3": value, "title_4": title_4, "title_5": title_5, "title_6": title_6, "btn_1_type": btn_1_type, "btn_1_destiny": btn_1_destiny, "btn_1_name": btn_1_name, "btn_2_type": btn_2_type, "btn_2_destiny": btn_2_destiny, "btn_2_name": btn_2_name });
    }

    function HandleTitle_4(value){
        setTitle_4(value);
        props.ReturnData({ "img_origin": imgOrigin, "img_name": imgName, "bg_img": bg_img, "bg_color": bg_color, "title_1": title_1, "title_2": title_2, "title_3": title_3, "title_4": value, "title_5": title_5, "title_6": title_6, "btn_1_type": btn_1_type, "btn_1_destiny": btn_1_destiny, "btn_1_name": btn_1_name, "btn_2_type": btn_2_type, "btn_2_destiny": btn_2_destiny, "btn_2_name": btn_2_name });
    }

    function HandleTitle_5(value){
        setTitle_5(value);
        props.ReturnData({ "img_origin": imgOrigin, "img_name": imgName, "bg_img": bg_img, "bg_color": bg_color, "title_1": title_1, "title_2": title_2, "title_3": title_3, "title_4": title_4, "title_5": value, "title_6": title_6, "btn_1_type": btn_1_type, "btn_1_destiny": btn_1_destiny, "btn_1_name": btn_1_name, "btn_2_type": btn_2_type, "btn_2_destiny": btn_2_destiny, "btn_2_name": btn_2_name });
    }

    function HandleTitle_6(value){
        setTitle_6(value);
        props.ReturnData({ "img_origin": imgOrigin, "img_name": imgName, "bg_img": bg_img, "bg_color": bg_color, "title_1": title_1, "title_2": title_2, "title_3": title_3, "title_4": title_4, "title_5": title_5, "title_6": value, "btn_1_type": btn_1_type, "btn_1_destiny": btn_1_destiny, "btn_1_name": btn_1_name, "btn_2_type": btn_2_type, "btn_2_destiny": btn_2_destiny, "btn_2_name": btn_2_name });
    }

    function HandleBtn_1_type(value){
        setBtn_1_type(value);
        props.ReturnData({ "img_origin": imgOrigin, "img_name": imgName, "bg_img": bg_img, "bg_color": bg_color, "title_1": title_1, "title_2": title_2, "title_3": title_3, "title_4": title_4, "title_5": title_5, "title_6": title_6, "btn_1_type": value, "btn_1_destiny": btn_1_destiny, "btn_1_name": btn_1_name, "btn_2_type": btn_2_type, "btn_2_destiny": btn_2_destiny, "btn_2_name": btn_2_name });
    }

    function HandleBtn_1_destiny(value){
        setBtn_1_destiny(value);
        props.ReturnData({ "img_origin": imgOrigin, "img_name": imgName, "bg_img": bg_img, "bg_color": bg_color, "title_1": title_1, "title_2": title_2, "title_3": title_3, "title_4": title_4, "title_5": title_5, "title_6": title_6, "btn_1_type": btn_1_type, "btn_1_destiny": value, "btn_1_name": btn_1_name, "btn_2_type": btn_2_type, "btn_2_destiny": btn_2_destiny, "btn_2_name": btn_2_name });
    }

    function HandleBtn_1_name(value){
        setBtn_1_name(value);
        props.ReturnData({ "img_origin": imgOrigin, "img_name": imgName, "bg_img": bg_img, "bg_color": bg_color, "title_1": title_1, "title_2": title_2, "title_3": title_3, "title_4": title_4, "title_5": title_5, "title_6": title_6, "btn_1_type": btn_1_type, "btn_1_destiny": btn_1_destiny, "btn_1_name": value, "btn_2_type": btn_2_type, "btn_2_destiny": btn_2_destiny, "btn_2_name": btn_2_name });
    }

    function HandleBtn_2_type(value){
        setBtn_2_type(value);
        props.ReturnData({ "img_origin": imgOrigin, "img_name": imgName, "bg_img": bg_img, "bg_color": bg_color, "title_1": title_1, "title_2": title_2, "title_3": title_3, "title_4": title_4, "title_5": title_5, "title_6": title_6, "btn_1_type": btn_1_type, "btn_1_destiny": btn_1_destiny, "btn_1_name": btn_1_name, "btn_2_type": value, "btn_2_destiny": btn_2_destiny, "btn_2_name": btn_2_name });
    }

    function HandleBtn_2_destiny(value){
        setBtn_2_destiny(value);
        props.ReturnData({ "img_origin": imgOrigin, "img_name": imgName, "bg_img": bg_img, "bg_color": bg_color, "title_1": title_1, "title_2": title_2, "title_3": title_3, "title_4": title_4, "title_5": title_5, "title_6": title_6, "btn_1_type": btn_1_type, "btn_1_destiny": btn_1_destiny, "btn_1_name": btn_1_name, "btn_2_type": btn_2_type, "btn_2_destiny": value, "btn_2_name": btn_2_name });
    }

    function HandleBtn_2_name(value){
        setBtn_2_name(value);
        props.ReturnData({ "img_origin": imgOrigin, "img_name": imgName, "bg_img": bg_img, "bg_color": bg_color, "title_1": title_1, "title_2": title_2, "title_3": title_3, "title_4": title_4, "title_5": title_5, "title_6": title_6, "btn_1_type": btn_1_type, "btn_1_destiny": btn_1_destiny, "btn_1_name": btn_1_name, "btn_2_type": btn_2_type, "btn_2_destiny": btn_2_destiny, "btn_2_name": value });
    } 

    function OpenFile(type, file){
        SetModalData('ShowFile', { "type": type, "file": file });
        SetModalState('ShowFile', true);
    }

    useEffect(()=>{
        RegisterDataPage(props.origin, setDataPage);
        RegisterListPag('currentPageId', setIdPage);

        LoadingData();

        return ()=>{
            UnRegisterDataPage(props.origin, setDataPage);
            UnRegisterListPag('currentPageId', setIdPage);
        };
    }, []);

    useEffect(()=>{
        LoadingData();

        setDataPage(GetDataPage(props.origin));
        setIdPage(GetListPag('currentPageId'));
        
        setImgOrigin(InitialData('img_origin'));
        setImgName(InitialData('img_name'));

        setBgImg(InitialData('bg_img'));
        setBgColor(InitialData('bg_color'));
        setTitle_1(InitialData('title_1'));
        setTitle_2(InitialData('title_2'));
        setTitle_3(InitialData('title_3'));
        setTitle_4(InitialData('title_4'));
        setTitle_5(InitialData('title_5'));
        setTitle_6(InitialData('title_6'));

        setBtn_1_type(InitialData('btn_1_type'));
        setBtn_1_destiny(InitialData('btn_1_destiny'));
        setBtn_1_name(InitialData('btn_1_name'));

        setBtn_2_type(InitialData('btn_2_type'));
        setBtn_2_destiny(InitialData('btn_2_destiny'));
        setBtn_2_name(InitialData('btn_2_name'));

        setStatus(false);
    }, [dataPage, idPage]);

    return(
        <div className="ModelSite_Home">
            <div className="list_data_page">  
                {
                    bg_img == "" ? null : 
                    <div className="div_input space_div title_side">
                        <div className="div_input">
                            <input type="text" className="data_input" onChange={ (e)=>{ HandleImgOrigin(e.target.value) } } value={ imgOrigin } maxLength="80" />
                            <span className="name_input">Assinatura - Origem</span>
                        </div>
                        <div className="div_input">
                            <input type="text" className="data_input" onChange={ (e)=>{ HandleImgName(e.target.value) } } value={ imgName } maxLength="80" />
                            <span className="name_input">Assinatura - Nome</span>
                        </div>
                    </div>
                }       
                    
                <div className="div_show_img">
                    <div className={ status == true ? "div_input new_file_add new_file_add_active" : "div_input new_file_add" }>
                        <label>
                            { status == true ? "Imagem de fundo adicionada" : "Adicionar imagem de fundo" }
                            <input type="file" onChange={ (e)=>{ HandleFile(e.target.files[0]); } } accept="image/*"/>
                        </label>
                    </div>
                    {
                        bg_img !='' ?
                        <>
                            <div className="div_input delete_img_add" onClick={ ()=>{ setBgImg(''); setStatus(false); } }>
                                <SvgDelete color="#FFFFFF" className="icons" />
                            </div>
                            {
                                status == true ?
                                <div className="div_input show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(bg_img)) } }>
                                    <SvgImg color="#FFFFFF" className="icons"/>
                                </div> :
                                <div className="div_input show_img_" onClick={ ()=>{ OpenFile('showImg', "home/" + bg_img) } }>
                                    <SvgImg color="#FFFFFF" className="icons"/>
                                </div>
                            }
                        </> : null
                    }  

                    <div className="div_input space_div">
                        <input type="text" className="data_input" onChange={ (e)=>{ HandleBgColor(e.target.value) } } value={ bg_color } maxLength="80" />
                        <span className="name_input">Cor de fundo</span>
                    </div>
                    <div className="div_input">
                        <div className="div_color" style={ { background: bg_color } } />
                    </div>
                </div>

                <div className="div_input space_div title_side">
                    <div className="div_input space_div">
                        <input type="text" className="data_input" onChange={ (e)=>{ HandleTitle_1(e.target.value) } } value={ title_1 } maxLength="80" />
                        <span className="name_input">Título 1</span>
                    </div>
                    <div className="div_input space_div">
                        <input type="text" className="data_input" onChange={ (e)=>{ HandleTitle_2(e.target.value) } } value={ title_2 } maxLength="80" />
                        <span className="name_input">Título 2</span>
                    </div>
                    <div className="div_input space_div">
                        <input type="text" className="data_input" onChange={ (e)=>{ HandleTitle_3(e.target.value) } } value={ title_3 } maxLength="80" />
                        <span className="name_input">Título 3</span>
                    </div>
                </div>

                <div className="div_input space_div title_side">
                    <div className="div_input space_div">
                        <input type="text" className="data_input" onChange={ (e)=>{ HandleTitle_4(e.target.value) } } value={ title_4 } maxLength="80" />
                        <span className="name_input">Título 4</span>
                    </div>
                    <div className="div_input space_div">
                        <input type="text" className="data_input" onChange={ (e)=>{ HandleTitle_5(e.target.value) } } value={ title_5 } maxLength="80" />
                        <span className="name_input">Título 5</span>
                    </div>
                    <div className="div_input space_div">
                        <input type="text" className="data_input" onChange={ (e)=>{ HandleTitle_6(e.target.value) } } value={ title_6 } maxLength="80" />
                        <span className="name_input">Título 6</span>
                    </div>
                </div>
                                
                <div className="div_input space_div title_side">
                    <div className="div_input">
                        <select className="select_button" onChange={ (e)=>{ HandleBtn_1_type(e.target.value) } } value={ btn_1_type }>
                            <option value="Interno">Interno</option>
                            <option value="Externo">Externo</option>
                        </select>
                        <span className="name_input">Direcionamento</span>
                    </div>
                    <div className="div_input">
                        {
                            btn_1_type == 'Interno' ?
                            <select onChange={ (e)=>{ HandleBtn_1_destiny(e.target.value) } } value={ btn_1_destiny } required>
                                <option value="">#</option>
                                <option value="home">Home</option>
                                <option value="howworks">Como funciona</option>
                                <option value="courses">Cursos</option>
                                <option value="mentoring">Mentoria</option>
                                <option value="rolo">Rolo</option>
                                <option value="typepayment">Tipo de pagamento</option>
                                <option value="enrollment">Inscrição</option>
                            </select> :
                            <input type="text" className="data_input" onChange={ (e)=>{ HandleBtn_1_destiny(e.target.value) } } value={ btn_1_destiny !='' ? btn_1_destiny == 'Externo' ? btn_1_destiny.replaceAll(' ', '') : "" : "" } placeholder="cole o link..." />
                        }
                        <span className="name_input">Para</span>
                    </div>
                    <div className="div_input space_div">
                        <input type="text" className="data_input" onChange={ (e)=>{ HandleBtn_1_name(e.target.value) } } value={ btn_1_name } maxLength="80" />
                        <span className="name_input">Botão 1</span>
                    </div>
                </div>
                                
                <div className="div_input space_div title_side">
                    <div className="div_input">
                        <select className="select_button" onChange={ (e)=>{ HandleBtn_2_type(e.target.value) } } value={ btn_2_type }>
                            <option value="Interno">Interno</option>
                            <option value="Externo">Externo</option>
                        </select>
                        <span className="name_input">Direcionamento</span>
                    </div>
                    <div className="div_input">
                        {
                            btn_2_type == 'Interno' ?
                            <select onChange={ (e)=>{ HandleBtn_2_destiny(e.target.value) } } value={ btn_2_destiny } required>
                                <option value="">#</option>
                                <option value="home">Home</option>
                                <option value="howworks">Como funciona</option>
                                <option value="courses">Cursos</option>
                                <option value="mentoring">Mentoria</option>
                                <option value="rolo">Rolo</option>
                                <option value="typepayment">Tipo de pagamento</option>
                                <option value="enrollment">Inscrição</option>
                            </select> :
                            <input type="text" className="data_input" onChange={ (e)=>{ HandleBtn_2_destiny(e.target.value) } } value={ btn_2_destiny !='' ? btn_2_destiny == 'Externo' ? btn_2_destiny.replaceAll(' ', '') : "" : "" } placeholder="cole o link..." />
                        }
                        <span className="name_input">Para</span>
                    </div>
                    <div className="div_input space_div">
                        <input type="text" className="data_input" onChange={ (e)=>{ HandleBtn_2_name(e.target.value) } } value={ btn_2_name } maxLength="80" />
                        <span className="name_input">Botão 1</span>
                    </div>
                </div>

                <div className="div_input div_space space_top">
                    <div className="div_show_data div_space img">
                        {
                            bg_img != '' ?
                                <>
                                    <div className="show_example_data">
                                        {
                                            title_1 == '' ? null : <div className="title_1">{ title_1 }</div>
                                        }
                                        <div className="div_highlight">                                
                                            {
                                                title_2 == '' ? null : <div className="text">{ title_2 }</div>
                                            }                             
                                            {
                                                title_3 == '' ? null : <div className="highlight">{ title_3 }</div>
                                            }
                                            {
                                                title_4 == '' & title_5 == '' & title_6 == '' ? null :
                                                <div className="list_text">
                                                    {
                                                        title_4 == '' ? null : <div className="text">{ title_4 }</div>
                                                    }
                                                    {
                                                        title_5 == '' ? null : <div className="text">{ title_5 }</div>
                                                    }
                                                    {
                                                        title_6 == '' ? null : <div className="text">{ title_6 }</div>
                                                    }
                                                </div>
                                            }
                                        </div>
                                        {
                                            btn_1_name == '' & btn_2_name == '' ? null :
                                            <div className="button">
                                                {
                                                    btn_1_name == '' ? null : <div className="button_text">{ btn_1_name }</div>
                                                }
                                                {
                                                    btn_2_name == '' ? null : <div className="button_text">{ btn_2_name }</div>
                                                }
                                            </div>
                                        }
                                    </div>
                                    {
                                        status == true ?
                                        <img alt="home_example" src={ URL.createObjectURL(bg_img) } className="img_example" /> :
                                        <img alt="home_example" src={ "./assets/home/" + bg_img } className="img_example" /> 
                                    }
                                </> :
                            <img alt="home_example" src="./assets/bg_home.jpg" className="img_example" />
                        }                        
                    </div>
                </div>
            </div>
        </div>
    );
}