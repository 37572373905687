import { useState, useEffect } from "react";

import './Historic.css';

import { SvgEye, SvgReturn } from "components/SvgFile";

import { GetDataPage } from "interface/Data";
import { GetUserData, RegisterUserData, UnRegisterUserPage } from "interface/Users";
import { GetListPag, RegisterListPag, SetListPag, UnRegisterListPag } from "interface/Page";
import { SetModalData, SetModalState } from "interface/PopUp";

export default function Page_Students_Historic(props){

    const [ userId, setUserId ]     = useState(GetUserData('id'));
    const [ dataPage, setDataPage ] = useState(GetDataPage('students'));
    const [ idPage, setIdPage ]     = useState(GetListPag('currentPageId'));

    const [ name, setName ] = useState(InitialData('name'));
    const [ regDate, setRegDate ] = useState(InitialData('reg_date'));
    const [ regHour, setRegHour ] = useState(InitialData('reg_hour'));
    
    const [ courseStated, setCourseStated ] = useState(CheckedCourse(0));
    const [ courseFinished, setCourseFinished ] = useState(CheckedCourse(1));
    const [ courseQuestions, setCourseQuestions ] = useState(CheckedQuestionsCourse());

    function InitialData(type){
        if(idPage !=0){
            const newData = dataPage.find(item => item.id == idPage);
            return newData[type];
        }else {            
            SetListPag('currentPage', 'students');
            SetListPag('currentPageId', 'remuve');
        }
    }
    
    function CheckedCourse(type){
        const newData = [];
        const newDataInf = GetDataPage('students').find(item => item.id == idPage);
        newDataInf.courses_started.map((elem, index)=>{
            if(elem.status == type){
                newData.push(GetDataPage('courses').find(item => item.id == elem.id_course))
            }
        });
        if(newData.length > 0){
            return newData;
        }else {
            return newData;
        }     
    }

    function StatusCourse(type, idCourse){
        const newData = GetDataPage('students').find(item => item.id == idPage);
        const listData = newData.classes_continue.filter(item => item.id_course == idCourse);        
        const showDate = [];
        listData.map((elem, index)=>{
            if(showDate.find(item => item == elem.reg_date)){}else {
                showDate.push(elem.reg_date);
            }
        })  
        if(showDate.length > 0){        
            if(type == 0){ 
                const maxDate = new Date(Math.min(...showDate.map(date => new Date(date))));
                if(maxDate){
                    let showData = maxDate.toISOString().split('T')[0]; 
                    let showDataBr = showData.split('-');
                    return showDataBr[2] + "/" + showDataBr[1] + "/" + showDataBr[0];
                }           
            }
            if(type == 1){
                const maxDate = new Date(Math.max(...showDate.map(date => new Date(date)))); 
                if(maxDate){
                    let showData = maxDate.toISOString().split('T')[0]; 
                    let showDataBr = showData.split('-');
                    return showDataBr[2] + "/" + showDataBr[1] + "/" + showDataBr[0];
                } 
            }
        }
    }

    function CheckedQuestionsCourse(){
        const newData = [];
        GetDataPage('courses').map((elem, index)=>{
            elem.questions.map((elem_1, index_1)=>{
                if(elem_1.id_user === parseInt(idPage)){                    
                    if(newData.find(item => item.id == elem_1.id)){ }else {
                        newData.push(elem_1);
                    }
                }
            });
        });
        return newData;
    }

    function OpenInf(elem, text){
        SetModalData('Questions', { "showData": elem, "text": text });
        SetModalState('Questions', true);
    }

    function OpenText(text){
        SetModalData('QuestionsText', text);
        SetModalState('QuestionsText', true);
    }    

    useEffect(()=>{
        RegisterUserData('id', setUserId);
        RegisterListPag('currentPageId', setIdPage);

        return ()=>{
            UnRegisterUserPage('id', setUserId);
            UnRegisterListPag('currentPageId', setIdPage);
        };
    }, []);

    useEffect(()=>{
        setIdPage(GetListPag('currentPageId'));
    }, [idPage]);

    return(
        <div className="Page_Historic">
            <div className="new_block">
                <div className="div_return" onClick={ ()=>{ SetListPag('currentPage', 'students_details') }}>
                    <SvgReturn className="icons" color="#061821" />
                    Voltar
                </div>
                <div className="title_page">{ name }</div>
                <div className="title_page">Histórico do aluno</div>
            </div>

            <div className="" style={ { textAlign: "center" } }>
                Cadastro registrado na data { regDate } às { regHour }
            </div>

            <div className="show_page_data">
                <div className="type_title">
                    <div className="">Cursos finalizados</div>
                </div>
                <div className="list_input_data line_top">
                    <table width="100%" cellPadding="0" cellSpacing="0">
                        <thead>
                            <tr>
                                <th width="20" align="center">#</th>
                                <th>Título</th>
                                <th width="100">Data de início</th>
                                <th width="120">Data de término</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                courseFinished.length > 0 ?
                                    courseFinished.map((elem, index)=>{
                                        return(
                                            <tr key={ index }>
                                                <td>{ index + 1 }</td>
                                                <td>
                                                    <div className="show_img">
                                                        <div className="">
                                                            <img alt="img fundo" src={ elem.img != "" ? "./assets/courses/img/" + elem.img : "./assets/not_file.jpg" } className="img_examplo" />
                                                        </div>
                                                        <div className="">{ elem.title }</div>
                                                    </div>
                                                </td>
                                                <td align="center">{ StatusCourse(0, elem.id) }</td>
                                                <td align="center">{ StatusCourse(1, elem.id) }</td>
                                            </tr>
                                        )
                                    })
                                :
                                <tr>
                                    <td colSpan={ 4 } className="no_data">Nenhum dado encontrado...</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
            </div>

            <div className="show_page_data">
                <div className="type_title">
                    <div className="">Cursos inicializados</div>
                </div>
                <div className="list_input_data line_top">
                    <table width="100%" cellPadding="0" cellSpacing="0">
                        <thead>
                            <tr>
                                <th width="20" align="center">#</th>
                                <th>Título</th>
                                <th width="100">Data de início</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                courseStated.length > 0 ?
                                    courseStated.map((elem, index)=>{
                                        return(
                                            <tr key={ index }>
                                                <td>{ index + 1 }</td>
                                                <td>
                                                    <div className="show_img">
                                                        <div className="">
                                                            <img alt="img fundo" src={ elem.img != "" ? "./assets/courses/img/" + elem.img : "./assets/not_file.jpg" } className="img_examplo" />
                                                        </div>
                                                        <div className="">{ elem.title }</div>
                                                    </div>
                                                </td>
                                                <td align="center">{ StatusCourse(0, elem.id) }</td>
                                            </tr>
                                        )
                                    })
                                :
                                <tr>
                                    <td colSpan={ 4 } className="no_data">Nenhum dado encontrado...</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
            </div>

            <div className="show_page_data">
                <div className="type_title">
                    <div className="">Questionários respondidos</div>
                </div>
                <div className="list_input_data line_top">
                    <table width="100%" cellPadding="0" cellSpacing="0">
                        <thead>
                            <tr>
                                <th width="20" align="center">#</th>
                                <th>Título</th>
                                <th width="340">Respostas</th>
                                <th width="80">Data</th>
                                <th width="40">Hora</th>
                                <th width="20">#</th>
                            </tr>
                        </thead>
                        <tbody>    
                            {
                                courseQuestions.length > 0 ?
                                    courseQuestions.map((elem, index)=>{    
                                        let infCourse = GetDataPage('courses').find(item => item.id == elem.id_course);
                                        
                                        let infText = GetDataPage('courses').find(item => item.id == elem.id_course);
                                        let ShowText = infText['questions'].find(item => item.id_user == parseInt(idPage));
                                                                                     
                                        return(                                    
                                            <tr key={ index }>
                                                <td align="center">{ index + 1 }</td>
                                                <td>
                                                    <div className="show_img">
                                                        <div className="">
                                                            <img alt="img fundo" src={ infCourse.file != "" ? "./assets/courses/img/" + infCourse.file : "./assets/not_file.jpg" } className="img_examplo" />
                                                        </div>
                                                        <div className="">{ elem.title }</div>
                                                    </div>
                                                </td>
                                                <td>                                                
                                                    <div className="div_opt_alt_respose">
                                                        {
                                                            elem.list_questions.map((elem_1, inde_1)=>{
                                                                let comma = ',';
                                                                if((elem.list_questions.length - 1) == inde_1){
                                                                    comma = '';
                                                                }

                                                                return(
                                                                    <div className="" key={ inde_1 }>
                                                                        { elem_1.title } { elem_1.opt }{ comma }
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                        {
                                                            ShowText.text == '' ? null :
                                                            <div className="" onClick={ ()=>{ OpenText(ShowText.text) } } style={ { cursor: "pointer" } }>
                                                                - Opnião
                                                            </div>
                                                        }
                                                    </div>
                                                </td>
                                                <td>{ elem.date }</td>
                                                <td>{ elem.hour }</td>
                                                <td onClick={ ()=>{ OpenInf(elem.list_questions, elem.text) } }>
                                                    <SvgEye className="icons" color="#061821" />
                                                </td>
                                            </tr>
                                        )
                                    })
                                :
                                <tr>
                                    <td colSpan={ 6 } className="no_data">Nenhum dado encontrado...</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}
