import { useState, useEffect } from "react";

import './HowWorks.css';

import JoditEditor from 'jodit-react';
import { config } from "dataFixed";

import { SvgDelete, SvgSave } from "components/SvgFile";

import { Reg_HowWorks } from "services/RegisterData";

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";
import PopUp_Doubts from "components/PopUp/Doubts";

export default function Site_HowWorks(){
    
    const [ loading, setLoading ]   = useState(false);
    const [ dataPage, setDataPage ] = useState(GetDataPage('how_works'));

    const [ title_1, setTitle_1 ] = useState(InitialData('title_1'));
    const [ title_2, setTitle_2 ] = useState(InitialData('title_2'));
    const [ text, setText ]       = useState(InitialData('text'));
    const [ doubts, setDoubts ]   = useState(InitialData('doubts'));

    function InitialData(type){
        const newData = dataPage;
        return newData[type];
    }

    function EditDoubts(type, index, value){
        const newData = [...doubts];
        newData[index][type] = value;
        setDoubts(newData)
    }

    function NewData(id){
        SetModalData('Doubts', { "origin": "how_works", "type" : "Doubts", "id" : id });
        SetModalState('Doubts', true);
    }

    function DeleteData(id, value){
        SetModalData('Confirmation', { "origin": "how_works", "type" : "delete_how_works", "id" : id, "name": value });
        SetModalState('Confirmation', true);
    }

    function SaveData(event){
        event.preventDefault();
        setLoading(true);
        Reg_HowWorks(title_1, title_2, text, doubts, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    useEffect(()=>{
        RegisterDataPage('how_works', setDataPage);

        return ()=>{
            UnRegisterDataPage('how_works', setDataPage);
        };
    }, []);

    useEffect(()=>{
        setDataPage(GetDataPage('how_works'));

        setTitle_1(InitialData('title_1'));
        setTitle_2(InitialData('title_2'));
        setText(InitialData('text'));
        setDoubts(InitialData('doubts'));
    }, [dataPage]);

    return(
        <div className="Site_HowWorks">
            <div className={ loading == true ? "return_data_save div_loading" : "return_data_save" }>
                <img alt="loading" src="./assets/loading.gif" className="loading" />
            </div>
            <form onSubmit={ SaveData } className="div_login">
                <div className="new_block">
                    <div className="title_page">Como funciona</div>
                    <div className="list_opt_alt_page">
                        <button className="div_add_new_data">
                            <div className="new_data_add">
                                <SvgSave color="#ffffff" className="icons" />
                            </div>
                            <div className="name_btn_add">Salvar</div>
                        </button>
                    </div>
                </div>

                <div className="show_page_data">
                    <div className="type_title">Dados fixo</div>
                    <div className="list_input_data">
                        <div className="div_input space_div">
                            <input type="text" className="input_name" onChange={ (e)=>{ setTitle_1(e.target.value) } } value={ title_1 } maxLength="140" required />
                            <span className="name_input">Título</span>
                        </div>
                        <div className="div_input space_div">
                            <JoditEditor className="show_textarea" config={ config } value={ text ? text.replaceAll('&#34;', '"') : "" } onBlur={ newContent => setText(newContent) } />
                            <span className="name_input">Descrição</span>
                        </div>
                        <div className="div_input space_div">
                            <input type="text" className="input_name" onChange={ (e)=>{ setTitle_2(e.target.value) } } value={ title_2 } maxLength="140" required />
                            <span className="name_input">Subtitulo</span>
                        </div>
                    </div>
                </div>

                <div className="show_page_data">
                    <div className="type_title">
                        <div className="">Dúvidas</div>
                        <div className="cursor_pointer" onClick={ ()=>{ NewData(); } }>
                            + nova dúvidas
                        </div>                        
                    </div>
                    {
                        doubts.map((key, index)=>{
                            return(
                                <div className="list_input_data border_questions" key={ index }>
                                    <div className="div_input space_div title_side width_">
                                        <div className="div_input space_div">
                                            <input type="text" className="data_input" onChange={ (e)=>{ EditDoubts('question', index, e.target.value) } } value={ key.question } maxLength="80" />
                                            <span className="name_input">Dúvida</span>
                                        </div>
                                        <div className="div_input" onClick={ ()=>{ DeleteData(key.id, key.question) } }>
                                            <SvgDelete color="#ffffff" className="trash" />
                                        </div>
                                    </div>

                                    <div className="div_input space_div width_">
                                        <JoditEditor className="show_textarea" config={ config } value={ key.response ? key.response.replaceAll('&#34;', '"') : "" } onBlur={ newContent => EditDoubts('response', index, newContent) } />
                                        <span className="name_input">Resposta</span>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </form>
            <PopUp_Doubts />
        </div>
    );
}